import { Directive, OnInit, Self, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[hfcRemoveLeadingZero]'
})
export class RemoveLeadingZeroDirective implements OnInit {
  @Input()
  public hfcRemoveLeadingZero: string;

  constructor(
    @Self() private ngControl: NgControl
  ) { }

  public ngOnInit() {
    const phoneControl = this.ngControl.control;
    const phonePrefixControl = phoneControl.parent.get(this.hfcRemoveLeadingZero || "phonePrefix");

    phoneControl.valueChanges.subscribe((phone: string) => {
      if (phonePrefixControl.value !== "+44" || !phone || phone[0] !== "0") {
        return;
      }

      const withoutZero = phoneControl.value.replace(/^0+/, '');
      phoneControl.setValue(withoutZero);
    });
  }
}
