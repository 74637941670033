import { trigger, style, transition, animate, group, query, animateChild } from "@angular/animations";

export const slideUpAnimation =
  trigger("popup", [
    transition(":enter", [
      group([
        // query("@overlay", animateChild()),
        style({ transform: "translateY(100%)" }),
        animate(200, style({ transform: "translateY(0)" }))
      ])
    ]),
    transition(":leave", [
      group([
        // query("@overlay", animateChild()),
        animate(200, style({ transform: "translateY(100%)" }))
      ])
    ])
  ]);
