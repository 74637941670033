<form [formGroup]="form" class="field-container">
  <hfc-field label="Address Location" class="full">
    <hfc-checkbox-list [items]="[true, false]" formControlName="_isUk">
      <ng-template #itemTemplate let-item>
        <div>
            {{item ? 'UK' : 'Overseas'}}
        </div>
      </ng-template>
    </hfc-checkbox-list>
  </hfc-field>

  <div class="error">
    <span class="material-icons error-icon">error</span>
    Please specify full address
  </div>

  <hfc-field *ngIf="form.get('_isUk').value" label="Postcode">
    <hfc-postcode (addressSelect)="onAddressSelected($event)" formControlName="postcode"></hfc-postcode>
  </hfc-field>
  <div style="width: 100%"></div>

  <ng-container *ngIf="isDetailVisible">
    <hfc-field label="House Number or Name">
      <hfc-text-input formControlName="house"></hfc-text-input>
    </hfc-field>
    <hfc-field label="Street">
    <hfc-text-input formControlName="street"></hfc-text-input>
    </hfc-field>

    <hfc-field label="Address Line 2">
      <hfc-text-input formControlName="line2"></hfc-text-input>
    </hfc-field>
    <hfc-field label="City">
      <hfc-text-input formControlName="city"></hfc-text-input>
    </hfc-field>
    <hfc-field *ngIf="withRegion && form.get('_isUk').value" label="Region">
      <hfc-region-dropdown formControlName="region"></hfc-region-dropdown>
    </hfc-field>

    <ng-container *ngIf="!form.get('_isUk').value">
      <hfc-field label="Country">
        <hfc-text-input formControlName="country"></hfc-text-input>
      </hfc-field>
      <hfc-field label="Postcode/ZIP">
        <hfc-text-input formControlName="postcode"></hfc-text-input>
      </hfc-field>
    </ng-container>
  </ng-container>

  <!-- <hfc-debug-form [form]="form"></hfc-debug-form> -->
<form>
