<div *ngIf="confirmation" class="dialog-overlay" (click)="onRejectClicked()" [@fade]>
  <div class="dialog" (click)="$event.stopPropagation()" [@popup]="false">
    <div class="title">
      {{confirmation.title}}

      <hfc-button icon="close" (click)="onRejectClicked()"></hfc-button>
    </div>

    <div class="message" [innerHTML]="confirmation.message">
    </div>

    <div class="buttons">
      <hfc-button [type]="confirmation.acceptButtonType" [isLoading]="isAcceptButtonLoading" (click)="onAcceptClicked()">{{confirmation.acceptLabel}}</hfc-button>
      <hfc-button *ngIf="confirmation.rejectButtonType" [type]="confirmation.rejectButtonType" (click)="onRejectClicked()">{{confirmation.rejectLabel}}</hfc-button>
    </div>
  </div>
</div>
