<div class="container" [class.is-open]="isOpen" [class.is-ios-opening]="isIosOpening">
  <div class="input-container">
    <input type="text" [(ngModel)]="searchTerm" (ngModelChange)="onSearchTermChanged()" (click)="onInputClicked()" (focus)="onInputFocus()" (blur)="onInputBlur()">

    <i class="material-icons">search</i>

    <div *ngIf="isOpen" (click)="onCancelClicked()" class="cancel">Cancel</div>
  </div>

  <div *ngIf="isOpen" class="drawer">
    <div class="list">
      <div *ngIf="isHintVisible" class="text-grey72 hint">
        Start by typing the occupation
      </div>

      <ng-container *ngIf="!isLoading">
        <div *ngFor="let item of items" (click)="onItemClicked(item)" class="item">
          {{item.OrigoOccupation}}
        </div>
      </ng-container>

      <hfc-spinner *ngIf="isLoading"></hfc-spinner>
    </div>
  </div>
</div>


