import { Component, Input } from '@angular/core';

export interface StepStatus {
  lastUpdated: Date;
  prefilled: boolean;
}

@Component({
  selector: 'hfc-completed-banner',
  templateUrl: './completed-banner.component.html',
  styleUrls: ['./completed-banner.component.scss']
})
export class CompletedBannerComponent {
  @Input()
  public readyToSign: boolean;

  @Input()
  public waitingForCompletion: string[];

  @Input()
  public stepStatus?: StepStatus;

  public get isPrefilledVisible() {
    return this.stepStatus?.prefilled && !this.readyToSign && !this.waitingForCompletion?.length;
  }

  public get applicants() {
    return this.waitingForCompletion?.join(" and ");
  }
}
