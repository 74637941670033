import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'hfc-object-checkbox-list',
  templateUrl: './object-checkbox-list.component.html',
  styleUrls: ['./object-checkbox-list.component.scss'],
})
export class ObjectCheckboxListComponent implements ControlValueAccessor {
  @Input()
  public map: Map<string, string>;

  public value: string[];

  public get hfInvalid() { return this.ngControl?.invalid && this.ngControl?.dirty }

  constructor(
    @Optional() private ngControl: NgControl
  ) {
    this.ngControl && (this.ngControl.valueAccessor = this);
  }

  public onValueChange() {
    if (!(this.map instanceof Map)) {
      return;
    }

    const obj = Array.from(this.map.keys()).reduce((acc, p) => {
      acc[p] = this.value.includes(p);

      return acc;
    }, {});

    this.onChangeFn && this.onChangeFn(obj);
  }

  private onChangeFn: (value: any) => void;

  writeValue(obj: any): void {
    if (obj instanceof Object && this.map instanceof Map) {
      this.value = Array.from(this.map.keys()).reduce((acc, p) => {
        if (obj[p]) {
          acc.push(p);
        }

        return acc;
      }, []);
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
