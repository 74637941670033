<input type="number"
       pattern="\d*"
       [value]="value"
       [disabled]="disabled || lock"
       (input)="onInput($event.target.value)"
       (blur)="onTouchedFn && onTouchedFn()">

<div class="prefix">£</div>

<ng-container *ngIf="lock && !disabled">
  <i *ngIf="lock" class="material-icons lock">lock</i>
  <i *ngIf="lock" class="material-icons lock-open">lock_open</i>
</ng-container>

<img *ngIf="initials === 'MOGO'" class="mogo" src="assets/img/mogoplus-logo-mini.svg">
<img *ngIf="initials === 'EXPERIAN'" class="mogo" src="assets/external/experian-logo-mini.svg">
<div *ngIf="initials !== 'MOGO' && initials !== 'EXPERIAN' && initials" class="initials">{{initials}}</div>
