<div *ifMedia="'phone'" class="relative">
  <div class="dropdown" [class.is-open]="isOpen">
    <ng-container *ngTemplateOutlet="valueTemplate || defaultValueTemplate, context: { $implicit: selected }">
    </ng-container>
    &nbsp;
  </div>

  <i *ngIf="icon" class="material-icons chevron">{{icon}}</i>

  <select [(ngModel)]="selected" (ngModelChange)="onModelChange()">
    <option *ngFor="let item of filteredItems" [ngValue]="item">
      <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item }">
      </ng-container>
    </option>
  </select>

  <span class="material-icons error-icon">error</span>
</div>

<div *ifMedia="'desktop'" class="container">
  <div class="dropdown" [class.is-open]="isOpen" (click)="onDropdownClicked()">
    <ng-container *ngTemplateOutlet="valueTemplate || defaultValueTemplate, context: { $implicit: selected }">
    </ng-container>
    &nbsp;
    <i *ngIf="icon" class="material-icons chevron">{{icon}}</i>
  </div>

  <span class="material-icons error-icon">error</span>
</div>

<ng-template #defaultItemTemplate let-item>{{item}}</ng-template>
<ng-template #defaultValueTemplate let-item>{{item}}</ng-template>

<ng-template #desktopDrawer>
  <div class="drop">
    <hfc-text-input *ngIf="filterWith" [(ngModel)]="searchTerm" (ngModelChange)="filterItems()" placeholder="Search country…" icon="search" #searchInput></hfc-text-input>
    <div class="list">
      <div *ngFor="let item of filteredItems" (click)="onItemClicked(item)" class="item">
        <i class="material-icons">radio_button_unchecked</i>
        <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item }">
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
