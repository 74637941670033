<div *ngIf="helpPopupService.visible" class="popup" [@popup]>
  <div class="header">
    <span class="text-h4-medium-grey24">Need Help</span>
    <i class="material-icons close-icon" (click)="helpPopupService.visible = false">close</i>
  </div>
  <div class="content">
    <hfc-user-bar [user]="advisor" [showRole]="false"></hfc-user-bar>

    <div class="text-big-grey24 details">
      Call your advisor {{advisor.firstName}} on: {{advisor.phonePrefix}} {{advisor.phone}}<br>
      or email: {{advisor.email}}
    </div>
  </div>
  <div class="footer">
    <hfc-button type="primary" (click)="onCallClicked()">Call</hfc-button>
    <hfc-button type="primary" (click)="onEmailClicked()">Email</hfc-button>
  </div>

  <div class="overlay" (click)="helpPopupService.visible = false" [@overlay]></div>
</div>
