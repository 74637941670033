import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, Input, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { slideUpBackdropAnimation } from '../../animations/slide-up-backdrop.animation';
import { HelpPopupService } from '../help-popup/help-popup.service';

@Component({
  selector: 'hfc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: slideUpBackdropAnimation
})
export class TooltipComponent {
  @Input()
  public heading: string;

  public show$ = new Subject<boolean>();
  public popupVisible = false;

  constructor(
    private overlay: Overlay,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private helpPopupService: HelpPopupService
  ) {
    this.show$.pipe(
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(show => show ? this.show() : this.hide())
  }

  @ViewChild("content")
  public content: TemplateRef<any>;

  @ViewChild("tooltip")
  public tooltip: TemplateRef<any>;

  public onHelpClicked() {
    this.helpPopupService.visible = true;
  }

  private show() {
    // console.log("show");

    const t = new TemplatePortal(this.tooltip, this.viewContainerRef);
    this.overlayRef.attach(t);
  }

  private hide() {
    // console.log("hide");

    this.overlayRef.detach();
  }

  private _overlayRef: OverlayRef;
  private get overlayRef() {
    if (this._overlayRef) {
      return this._overlayRef;
    }

    const position = {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetY: -10
    }

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.el)
      .withPositions([position as any]);

    this._overlayRef = this.overlay.create({ positionStrategy });

    this._overlayRef.overlayElement.addEventListener("mouseenter", () => this.show$.next(true));
    this._overlayRef.overlayElement.addEventListener("mouseleave", () => this.show$.next(false));

    return this._overlayRef;
  }
}
