import { trigger, style, transition, animate, group, query, animateChild } from "@angular/animations";

export const slideUpBackdropAnimation = [
  trigger("slideUp", [
    transition(":enter", [
      group([
        query("@backdrop", animateChild()),
        style({ transform: "translateY(100%)" }),
        animate(200, style({ transform: "translateY(0)" }))
      ])
    ]),
    transition(":leave", [
      group([
        query("@backdrop", animateChild()),
        animate(200, style({ transform: "translateY(100%)" }))
      ])
    ])
  ]),
  trigger("backdrop", [
    transition(":enter", [
      style({ opacity: 0 }),
      animate(200, style({ opacity: 1 })),
    ]),
    transition(":leave", [
      animate(200, style({ opacity: 0 })),
    ])
  ])
]
