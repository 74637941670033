import { Directive, Input, ElementRef, Inject, Optional } from '@angular/core';
import { Environment } from '../services/environment.service';

export abstract class AuthTokenProvider {
  abstract getToken(): string;
}

@Directive({
  selector: '[hfSrcset]'
})
export class SrcsetDirective {
  /**
   * Expects image filename ending with `@xN.jpeg` where `N` will be replaced with 1,2,3 and used
   * in [src] and [srcset] attributes.
   */
  @Input() set hfSrcset(value: string) {
    this.srcset = value;
    this.compose();
  }

  private srcset: string;

  constructor(
    private el: ElementRef,
    private environment: Environment,
    @Inject(AuthTokenProvider) @Optional() private authTokenProvider: AuthTokenProvider,
  ) { }

  private compose() {
    if (this.srcset) {
      const token = this.authTokenProvider ? `?token=${this.authTokenProvider.getToken()}` : "";
      const sizes: string[] = [1, 2, 3].map(n => `${this.environment.apiUrl}servlet/gcs/${this.srcset.replace("@xN.", `@x${n}.`)}${token}`);

      this.el.nativeElement.src = sizes[0];
      this.el.nativeElement.srcset = `${sizes[1]} 2x, ${sizes[2]} 3x`;
    } else {
      this.el.nativeElement.src = null;
      this.el.nativeElement.srcset = null;
    }
  }
}
