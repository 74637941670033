<div class="upload"
     [class.upload--highlight]="dragover"
     (dragover)="onDragOver($event)"
     (dragleave)="onDragLeave($event)"
     (drop)="onDrop($event)">

  <hfc-spinner *ngIf="isLoading"></hfc-spinner>

  <div class="file-list" [class.isLoading]="isLoading">
    <div *ngFor="let document of documents" class="file">
      <!-- {{uploadedFile | json}} -->
      <hfc-button icon="cancel" (click)="onRemove(document)" title="Remove this document"></hfc-button>

      <div style="display: flex; align-items: center; cursor: pointer;" (click)="onDocumentClicked(document)">
        <img *ngIf="document.file.thumbnail" [hfSrcset]="document.file.folder + '/' + document.file.thumbnail">

        <div class="filename">{{document.file.originalFilename || document.file.filename}}</div>
      </div>
    </div>
  </div>

  <div class="hint desktop" [class.isLoading]="isLoading">Drag & Drop or tap to upload</div>
  <div class="hint mobile"  [class.isLoading]="isLoading">Tap to upload</div>

  <input *ngIf="!isLoading" type="file" (change)="onFileChange($event)" multiple class="input">
</div>

<hfc-field-error>{{errorMessage}}</hfc-field-error>
