import { Component, Inject, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Environment } from '../../../../../../../src/app/website/services/environment.service';
import { DOCUMENT_TYPE } from '../../enums.module';
import { Document } from '../../services/fact-find-api.service';

export abstract class FactFindUploadService {
  abstract addDocument(document: Document, file: File): Promise<Document>;
  abstract deleteDocument(document: Document): Promise<void>;
}

@Component({
  selector: 'hfc-fact-find-upload',
  templateUrl: './fact-find-upload.component.html',
  styleUrls: ['./fact-find-upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FactFindUploadComponent,
    multi: true
  }]
})
export class FactFindUploadComponent implements ControlValueAccessor {
  @Input()
  public type: DOCUMENT_TYPE;

  public isLoading: boolean = false;
  public dragover: boolean = false;
  public documents: Document[];
  public errorMessage: string;

  constructor(
    private environment: Environment,
    @Inject(FactFindUploadService) private factFindUploadService: FactFindUploadService,
  ) { }

  public onFileChange(event) {
    if (this.isLoading) {
      return;
    }

    const fileList: FileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    const files: File[] = Array.from(fileList);

    this.upload(files);
  }

  public async onRemove(document: Document) {
    if (document.file.id) {
      this.isLoading = true;

      try {
        await this.factFindUploadService.deleteDocument(document);
      } catch (e) {
        console.log("Exception in FactFindUploadComponent.onRemove(): ", e);
      }

      this.isLoading = false;
    }

    this.documents = this.documents.filter(doc => doc !== document);
    this.onChange && this.onChange(this.documents);
    this.errorMessage = null;
  }

  public onDragOver(event: Event) {
    this.dragover = true;

    event.stopPropagation();
    event.preventDefault();
  }

  public onDragLeave(event) {
    this.dragover = false;

    event.stopPropagation();
    event.preventDefault();
  }

  public onDrop(event) {
    this.dragover = false;

    event.stopPropagation();
    event.preventDefault();

    this.onFileChange(event);
  }

  private async upload(files: File[]) {
    this.isLoading = true;

    try {
      this.errorMessage = null;
      const promises = files.map(file => this.factFindUploadService.addDocument({ type: this.type }, file));
      const uploadedFiles = await Promise.all(promises);

      this.documents = [...this.documents, ...uploadedFiles];
      this.onChange && this.onChange(this.documents);
    } catch (e) {
      if (e.name === "FileValidationError") {
        this.errorMessage = e.message;
      }
      console.log("Exception in FactFindUploadComponent.upload(): ", e);
    }

    this.isLoading = false;
  }

  public onDocumentClicked(document: Document) {
    const url = `${this.environment.apiUrl}servlet/gcs/${document.file.folder}/${document.file.filename}?download=true`;

    window.open(url);
  }

  private onChange: (value: Document[]) => void;

  public writeValue(obj: any): void {
    this.documents = obj;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
