<ng-container *ngIf="advisor">
  <div class="title text-big-medium-secondary">
    <i class="title__icon material-icons">phone</i>
    Need Help?
  </div>
  <div class="advisor text-grey24">
    Call your Advisor, {{advisor.firstName}}<br>
    on <a href="tel:{{advisor.phonePrefix}} {{advisor.phone}}">{{advisor.phonePrefix}} {{advisor.phone}}</a><br>
    or <a href="mailto:{{advisor.email}}">email them now</a>
  </div>
</ng-container>
