<hfc-user-bar [user]="user"></hfc-user-bar>
<hfc-applicant-switch *ngIf="isApplicantSwitchVisible"></hfc-applicant-switch>

<div class="list">
  <div *ngFor="let step of steps" class="step" [class.step--returned]="step.status === 'RETURNED'">
    <div [ngSwitch]="step.status" class="status-icon">
      <div *ngSwitchCase="'NOT_STARTED'" class="not-started"></div>
      <div *ngSwitchCase="'IN_PROGRESS'" class="in-progress"></div>
      <i *ngSwitchCase="'DONE'" class="material-icons done">check_circle</i>
      <i *ngSwitchCase="'RETURNED'" class="material-icons error">error</i>
    </div>

    <span [routerLink]="[step.url]" routerLinkActive="link--active" class="link">
      {{stepNameMap.get(step.step)}}
    </span>
  </div>
</div>
