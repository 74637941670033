<button [type]="submit ? 'submit' : 'button'"
        class="button"
        [class.button--icon]="icon"
        [class.button--disabled]="disabled || isLoading"
        [class.button--is-loading]="isLoading"
        [class.postfixIcon]="!!postfixIcon"
        [class.size-small]="size === 'small'"
        [ngClass]="{'button--primary': type === 'primary', 'button--secondary': type === 'secondary', 'button--tertiary': type === 'tertiary', 'button--red': type === 'red', 'button--outline': type === 'outline'}">
  <i *ngIf="icon" class="material-icons">{{icon}}</i>

  <div class="stuffing"></div>

  <div class="content">
      <ng-content></ng-content>
  </div>

  <i *ngIf="postfixIcon && !isLoading" class="material-icons">{{postfixIcon}}</i>

  <hfc-spinner *ngIf="isLoading" size="small"></hfc-spinner>
</button>
