<div class="top">
  <a href="/dashboard">
    <img class="logo" src="assets/img/Everglades-light-bg.svg">
  </a>

  <hfc-logout-button *ngIf="logoutButton"></hfc-logout-button>
</div>

<hfc-progress-bar *ngIf="showProgressBar" [value]="percentageCompleted" [showValue]="false" [radius]="false"></hfc-progress-bar>

<div *ngIf="!showProgressBar" class="bottom">
</div>

