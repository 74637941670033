<ng-container *ngIf="!stepLayoutService.closeButton">
  <i class="material-icons menu-button" (click)="stepLayoutService.isMenuVisible = true">menu</i>

  <a href="/dashboard">
    <img class="logo" src="assets/img/Everglades-light-bg.svg">
  </a>
</ng-container>

<div class="title">
  {{pageTitle}}
</div>

<hfc-button *ngIf="stepLayoutService.closeButton" icon="close" (click)="onCloseButtonClicked()" class="close-button"></hfc-button>

<div *ngIf="stepLayoutService.isMenuVisible" class="sidemenu" [@menu]>
  <!-- we need separate div for content to make it scrollable, otherwise we cannot position overlay -->
  <div class="sidemenu-content">
    <a href="/dashboard">
      <img class="sidemenu-logo" src="assets/img/Everglades-light-bg.svg">
    </a>

    <hfc-progress-bar *ngIf="percentageCompleted" [value]="percentageCompleted" [showValue]="false" [radius]="false"></hfc-progress-bar>

    <hfc-step-navigation *ngIf="showStepNavigation" [user]="user" [isApplicantSwitchVisible]="isApplicantSwitchVisible" [steps]="steps" [stepNameMap]="stepNameMap"></hfc-step-navigation>

    <hfc-footer></hfc-footer>
  </div>
  <div class="overlay" (click)="stepLayoutService.isMenuVisible = false" [@overlay]></div>
</div>
