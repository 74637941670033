import { Component, Input, HostBinding, HostListener, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hfc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckboxComponent,
    multi: true
  }]
})
export class CheckboxComponent implements ControlValueAccessor {
  @HostBinding('class.checked')
  public checked: boolean = false;

  @Output()
  public onCheckedChange: EventEmitter<boolean> = new EventEmitter();

  /** Used in checkbox-group */
  @Input()
  public value: any;

  @Input()
  public label: string;

  @HostListener('click')
  public onClicked() {
    this.checked = !this.checked;

    this.onChange && this.onChange(this.checked);
    this.onCheckedChange.emit(this.checked);
  }

  private onChange: (value: boolean) => void;

  writeValue(checked: boolean): void {
    this.checked = checked || false;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
