<i *ifMedia="'desktop'" (mouseenter)="show$.next(true)" (mouseleave)="show$.next(false)" class="material-icons">help_outline</i>
<i *ifMedia="'phone'" (click)="popupVisible = true" class="material-icons">help_outline</i>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #tooltip>
  <div class="tooltip">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-container *ifMedia="'phone'">
<div *ngIf="popupVisible" class="popup" @slideUp>
  <div class="header">
    <span class="text-h4-medium-grey24">{{heading}}</span>
    <i class="material-icons close-icon" (click)="popupVisible = false">close</i>
  </div>
  <div class="content">
    <div class="text-big-grey24 details">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
  <div class="footer">
    <hfc-button type="primary" (click)="onHelpClicked()">Still need help?</hfc-button>
  </div>

  <div class="backdrop" (click)="popupVisible = false" @backdrop></div>
</div>
</ng-container>
