import { Component, ElementRef, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicantUser } from '../../../fact-find/services/fact-find-api.service';

export abstract class ApplicantSwitchService {
  public abstract applicants$: Observable<ApplicantUser[]>;
  public abstract signInLead$: Observable<boolean>;

  public abstract switchApplicant(applicant: ApplicantUser);
  public makeLead?(applicant: ApplicantUser): Observable<void>;
}

@Component({
  selector: 'hfc-applicant-switch',
  templateUrl: './applicant-switch.component.html',
  styleUrls: ['./applicant-switch.component.scss']
})
export class ApplicantSwitchComponent {
  public get applicants$() { return this.applicantSwitchService.applicants$ }
  public get signInLead$() { return this.applicantSwitchService.signInLead$ }

  public isOpen = false;
  public isLoading = false;

  @HostListener("document:click", ["$event.target"])
  public onDocumentClicked(target) {
    if (this.isOpen && !this.el.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }

  constructor(
    private el: ElementRef,
    private applicantSwitchService: ApplicantSwitchService,
  ) { }

  public async onSwitchClicked() {
    this.isOpen = !this.isOpen;
  }

  public async onApplicantSwitchClicked(applicant: ApplicantUser) {
    this.applicantSwitchService.switchApplicant(applicant);
  }

  public async onApplicantMakeLeadClicked(applicant: ApplicantUser) {
    this.isLoading = true;

    this.applicantSwitchService.makeLead(applicant).toPromise();

    this.isLoading = false;
  }
}
