import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { PhonePrefix, PHONE_PREFIXES } from '../../../../../../../src/app/website/utils/variables.data';
import { DropdownComponentFilterWithFn } from '../dropdown/dropdown.component';

@Component({
  selector: 'hfc-phone-prefix-dropdown',
  templateUrl: './phone-prefix-dropdown.component.html',
  styleUrls: ['./phone-prefix-dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: PhonePrefixDropdownComponent,
    multi: true
  }]
})
export class PhonePrefixDropdownComponent implements ControlValueAccessor {
  public selected: PhonePrefix;

  public PHONE_PREFIXES = PHONE_PREFIXES;

  public filterWith: DropdownComponentFilterWithFn = (item, term) => {
    return item && item.country.toLowerCase().includes(term.toLowerCase());
  }

  public onPrefixChange() {
    this.onChangeFn && this.onChangeFn(this.selected && this.selected.prefix);
  }

  private onChangeFn: (value: string) => void;

  writeValue(prefix: string): void {
    const phonePrefix = PHONE_PREFIXES.find(p => p.prefix === prefix);

    if (phonePrefix) {
      this.selected = phonePrefix;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
