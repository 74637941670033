<hfc-header-with-sidebar *ifMedia="'phone'"
  [pageTitle]="pageTitle"
  [percentageCompleted]="percentageCompleted"
  [user]="applicantUser"
  [isApplicantSwitchVisible]="isApplicantSwitchVisible"
  [steps]="steps"
  [stepNameMap]="stepNameMap"></hfc-header-with-sidebar>

<hfc-desktop-header *ifMedia="'desktop'"
  [showProgressBar]="true"
  [percentageCompleted]="percentageCompleted">
</hfc-desktop-header>

<div class="middle">
  <div class="left-navigation" *ifMedia="'desktop'">
    <hfc-step-navigation
      [user]="applicantUser"
      [isApplicantSwitchVisible]="isApplicantSwitchVisible"
      [steps]="steps"
      [stepNameMap]="stepNameMap">
    </hfc-step-navigation>
  </div>

  <div class="step-container">
    <div *ngIf="returnComment" class="returnComment">
      <i class="material-icons">error</i>
      {{ returnComment }}
    </div>

    <hfc-completed-banner *ngIf="stepStatus?.prefilled || readyToSign || waitingForCompletion?.length"
      [stepStatus]="stepStatus"
      [readyToSign]="readyToSign"
      [waitingForCompletion]="waitingForCompletion">
    </hfc-completed-banner>

    <div class="step-content" [@route]="url">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<hfc-desktop-footer *ifMedia="'desktop'"></hfc-desktop-footer>

<!-- Mobile Help Popup -->
<hfc-help-popup></hfc-help-popup>
