<div class="container">
  <i class="material-icons">check_circle</i>

  <div *ngIf="isPrefilledVisible" class="message">
    This information has been pre-populated from your previous Fact Find dated {{ stepStatus.lastUpdated | date:'E d MMM y' }}.
    Please check before saving.
  </div>

  <div *ngIf="!isPrefilledVisible" class="message">
    It looks like you’ve finished.<br>
    <ng-container *ngIf="waitingForCompletion?.length">
      As soon as
      {{ applicants }}
      {{ waitingForCompletion?.length > 1 ? 'have' : 'has' }}
      finished too, we’ll invite you to check and sign the Fact Find by email.
    </ng-container>
    <ng-container *ngIf="!waitingForCompletion?.length">
      When you’re ready, please check and sign your Fact Find.
    </ng-container>
  </div>

  <hfc-button *ngIf="readyToSign" type="primary" [routerLink]="['./sign-off']">Check and Sign</hfc-button>
</div>
