import { Component, Input } from '@angular/core';
import { FactFindStatus } from '../../../fact-find/services/fact-find-api.service';

@Component({
  selector: 'hfc-desktop-header',
  templateUrl: './desktop-header.component.html',
  styleUrls: ['./desktop-header.component.scss']
})
export class DesktopHeaderComponent {
  @Input()
  logoutButton: boolean = true;

  @Input()
  showProgressBar: boolean;

  @Input()
  percentageCompleted: number;
}
