import { Component, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators, ValidatorFn } from '@angular/forms';
import { setControlEnabled } from '../../../../../../../src/app/website/utils/functions/set-control-enabled';
import { Address } from '../../../fact-find/services/fact-find-api.service';

export const addressValidator: ValidatorFn = (control: AbstractControl) => {
  const address: Address = control.value;

  const isValid = !!
    (
      address
      && address.postcode && address.postcode.length
      && address.house && address.house.length
      && address.street && address.street.length
      && address.city && address.city.length
      && address.country && address.country.length
    );

  return isValid
    ? null
    : { address: true };
}

export const addressWithRegionValidator: ValidatorFn = (control: AbstractControl) => {
  const address: Address = control.value;

  const isValid = !!
    (
      address
      && address.postcode && address.postcode.length
      && address.house && address.house.length
      && address.street && address.street.length
      && address.city && address.city.length
      && address.country && address.country.length
      && (!address._isUk || (address.region && address.region.length))
    );

  return isValid
    ? null
    : { address: true };
}

@Component({
  selector: 'hfc-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AddressComponent,
    multi: true
  }]
})
export class AddressComponent implements ControlValueAccessor {
  @Input()
  public withRegion = false;

  public form = this.fb.group({
    id: [],
    postcode: ["", Validators.required],
    house: ["", Validators.required],
    street: ["", Validators.required],
    line2: [""],
    city: ["", Validators.required],
    country: ["", Validators.required],
    region: [null, Validators.required],
    _isUk: [true]
  });

  public isDetailVisible = false;

  constructor(private fb: FormBuilder) {
    this.form.valueChanges.subscribe(value => {
      this.onChangeFn && this.onChangeFn(value);
    })
    this.form.get("_isUk").valueChanges.subscribe(isUK => {
      if (isUK) {
        this.form.get("country").setValue("UK");
      }

      if (!this.isDetailVisible && !isUK) {
        this.isDetailVisible = true;
      }

      setControlEnabled(this.form.get("region"), isUK);
    })
  }

  public onAddressSelected(address: Address) {
    this.form.patchValue(address);
    this.isDetailVisible = true;
  }

  private onChangeFn: (value: Address) => void;

  public writeValue(obj: any): void {
    if (obj) {
      obj = { ...obj };
      obj._isUk = obj.country === "UK";

      this.form.patchValue(obj, { emitEvent: false });

      this.isDetailVisible = true;
    }
  }
  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  public registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
