import { Component, Input, OnInit } from '@angular/core';
import { Attachment } from '../../models/attachment';

@Component({
  selector: 'hf-attachment-thumb-image',
  templateUrl: './attachment-thumb-image.component.html',
  styleUrls: ['./attachment-thumb-image.component.scss']
})
export class AttachmentThumbImageComponent implements OnInit {
  @Input()
  public attachment: Attachment;

  public isError = false;

  public ngOnInit() {
    this.isError = !this.attachment?.thumbnail;
  }
}
