import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { FileOrientation, FileType } from '../shared/fileuploader/utils/fileuploader.enum';
import { FileValidationError } from './file-validation.error';

export function fieldsMatcher(field1: string, field2: string) {
  return (control: AbstractControl) => {
    const field1Value = control.get(field1);
    const field2Value = control.get(field2);
    if (!field1Value || !field2Value) {
      return null;
    } else if (field1Value.value === field2Value.value) {
      return null;
    } else {
      field2Value.setErrors({ invalid: true });
      return { nomatch: true };
    }
  };
}

export const atLeastOne = (validator: ValidatorFn) => (group: FormGroup): ValidationErrors | null => {
  const hasAtLeastOne = group && group.controls && Object.keys(group.controls)
    .some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : {
    atLeastOne: true,
  };
};

export const trimmedMinLength = (minLength: number): ValidatorFn => {
    return (control: FormControl) => {
      return control.value && (control.value as string).trim().length >= minLength
        ? null
        : { trimmedMinLength: true }
    };
}

export class HfValidators {
  private static readonly MAX_FILE_SIZE_DISK_B = 31457280;
  private static readonly MAX_FILE_SIZE_DISK_MB = 30;
  private static readonly MAX_FILE_SIZE_EMAIL_B = 10485760;
  private static readonly MAX_FILE_SIZE_EMAIL_MB = 10;

  public static maxDate(maxDate: Date): ValidatorFn {
    return (control: FormControl) => {
      return control.value && control.value > maxDate
        ? { maxDate: true }
        : null;
    };
  }

  public static minDate(minDate: Date): ValidatorFn {
    return (control: FormControl) => {
      return control.value && control.value < minDate
        ? { minDate: true }
        : null;
    };
  }

  public static maxFileSize(maxFileSize: number, errorMessage: string): ValidatorFn {
    return (control: FormControl) => {
      return control.value && control.value > maxFileSize
        ? { maxFileSize: errorMessage }
        : null;
    };
  }

  public static maxFileSizeEmail(): ValidatorFn {
    return HfValidators.maxFileSize(HfValidators.MAX_FILE_SIZE_EMAIL_B, HfValidators.maxFileSizeMessage(HfValidators.MAX_FILE_SIZE_EMAIL_MB));
  }

  public static maxFileSizeDisk(): ValidatorFn {
    return HfValidators.maxFileSize(HfValidators.MAX_FILE_SIZE_DISK_B, HfValidators.maxFileSizeMessage(HfValidators.MAX_FILE_SIZE_DISK_MB));
  }

  public static allowedFileTypes(allowedFileTypes: FileType[], errorMessage: string): ValidatorFn {
    return (control: FormControl) => {
      let isAllowed = false;

      for (const allowedFileType of allowedFileTypes) {
        const exactMatch = allowedFileType === control.value;
        const anyMatch = allowedFileType.endsWith('*') && control.value && control.value.startsWith(allowedFileType.substr(0, allowedFileType.length - 1));

        if (exactMatch || anyMatch) {
          isAllowed = true;
        }
      }

      return control.value && !isAllowed
        ? { allowedFileTypes: errorMessage }
        : null;
    };
  }

  public static fileOrientation(fileOrientation: FileOrientation, errorMessage: string): ValidatorFn {
    return (control: FormControl) => {
      return control.value && control.value === fileOrientation
        ? { fileOrientation: errorMessage }
        : null;
    };
  }

  public static minFileWidth(minFileWidth: number, errorMessage: string): ValidatorFn {
    return (control: FormControl) => {
      return control.value && control.value < minFileWidth
        ? { minFileWidth: errorMessage }
        : null;
    };
  }

  public static minFileHeight(minFileHeight: number, errorMessage: string): ValidatorFn {
    return (control: FormControl) => {
      return control.value && control.value < minFileHeight
        ? { minFileHeight: errorMessage }
        : null;
    };
  }

  public static required(errorMessage: string): ValidatorFn {
    return (control: FormControl) => {
      return !control.value
        ? { required: errorMessage }
        : null;
    };
  }

  public static validateMaxFileSizeDisk(file: File) {
    if (file.size > HfValidators.MAX_FILE_SIZE_DISK_B) {
      throw new FileValidationError('File size of ' + file.name + ' exceeds ' + HfValidators.MAX_FILE_SIZE_DISK_MB + 'MB');
    }
  }

  private static maxFileSizeMessage(maxFileSize: number): string {
    return 'File size must not exceed ' + maxFileSize + 'MB';
  }
}
