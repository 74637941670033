/**
 * Converts Date object into ISO string in local time zone.
 * @param date Date to convert
 * @param stripTime Strip time of the date
 */
export function toLocalDateISO(date: Date, stripTime = false): string {
  if (!date) {
    return null;
  }

  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  stripTime && localDate.setUTCHours(0, 0, 0, 0);

  return localDate.toISOString();
}
