<div class="button" (click)="onSwitchClicked()">
  Switch Applicant
  <i class="material-icons">arrow_drop_down</i>
</div>

<div *ngIf="isOpen" class="drawer">
  <hfc-spinner *ngIf="isLoading"></hfc-spinner>

  <ng-container *ngIf="!isLoading">
    <ng-container *ngIf="applicants$ | async as applicants">
      <div *ngFor="let applicant of applicants" class="applicant">
        <hfc-user-bar [user]="applicant"></hfc-user-bar>
        <div class="buttons">
          <hfc-button type="outline" (click)="onApplicantSwitchClicked(applicant)">Switch</hfc-button>
          <hfc-button *ngIf="!applicant.isLead && (signInLead$ | async)" type="outline" (click)="onApplicantMakeLeadClicked(applicant)">Make Lead</hfc-button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
