import { Component, Input } from '@angular/core';
import { Attachment } from '../../models/attachment';
import { Environment } from '../../services/environment.service';

@Component({
  selector: 'hf-attachment-image',
  templateUrl: './attachment-image.component.html',
  styleUrls: ['./attachment-image.component.scss']
})
export class AttachmentImageComponent {
  @Input()
  public set attachment(value: Attachment) {
    this.url = value && `${this.environment.apiUrl}servlet/gcs/${value.folder}/${value.filename}`;
    this.autoAlt = value && value.originalFilename;
  }

  @Input()
  public alt: string;

  public url: string;
  public autoAlt: string;

  constructor(
    private environment: Environment,
  ) { }
}
