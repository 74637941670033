import { Pipe, PipeTransform } from "@angular/core";
import { WordingService } from "../../services/wording.service";
import { INTRODUCERS_TYPES } from "../../utils/variables.data";

@Pipe({ name: "wording" })
export class WordingPipe implements PipeTransform {
  constructor(private wordingService: WordingService) { }

  public transform(value: string, introducerType: INTRODUCERS_TYPES|string, ...args: any[]) {
    return this.wordingService.transform(value, introducerType);
  }
}
