import { Component, EventEmitter, Input, Output, ContentChild, TemplateRef } from '@angular/core';
import { UserSimpleDTO } from '../../../../../../../src/app/website/models/user-simple.dto';
import { APPLICATION_STEP, APPLICATION_STEP_STATUS } from '../../../fact-find/enums.module';

export interface ReviewSectionState {
  step: APPLICATION_STEP;
  stepStatus?: APPLICATION_STEP_STATUS;
  markToReturn?: boolean;
  applicantId?: string;
  buttonType?: string;
  buttonLabel?: string;
  buttonIsLoading?: boolean;
  returnComment?: string;
  lastUpdated?: string;
  updatedBy?: UserSimpleDTO;
}

@Component({
  selector: 'hfc-review-section',
  templateUrl: './review-section.component.html',
  styleUrls: ['./review-section.component.scss']
})
export class ReviewSectionComponent {
  @Input()
  public heading: string;

  @Input()
  public subTitle: string;

  @ContentChild("subTitleTemplate")
  public subTitleTemplate: TemplateRef<any>;

  @Input()
  public state: ReviewSectionState;

  @Output()
  public buttonClicked = new EventEmitter<ReviewSectionState>();

  public onButtonClicked() {
    this.buttonClicked.emit(this.state);
  }
}
