import { Component, Input } from '@angular/core';
import { User } from '../../../root/services/user.service';

export interface StepItem {
  step: string;
  status: string;
  url: string;
}

@Component({
  selector: 'hfc-step-navigation',
  templateUrl: './step-navigation.component.html',
  styleUrls: ['./step-navigation.component.scss']
})
export class StepNavigationComponent {
  @Input()
  public user: User;

  @Input()
  public isApplicantSwitchVisible: boolean;

  @Input()
  public steps: StepItem[];

  @Input()
  public stepNameMap: Map<string, string>;
}
