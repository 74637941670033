<div class="upload" [class.upload--highlight]="dragover" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
  <hfc-spinner *ngIf="loading"></hfc-spinner>

  <div *ngIf="!loading && uploadedFile?.filename" class="preview">
    <img *ngIf="uploadedFile?.thumbnail" [hfSrcset]="uploadedFile?.folder + '/' + uploadedFile?.thumbnail" class="preview__item">

    <div class="preview__item">{{uploadedFile?.originalFilename || uploadedFile?.filename}}</div>

    <hfc-button icon="cancel" (click)="onRemove()" class="remove-button preview__item"></hfc-button>
  </div>

  <div class="preview__item">
    <ng-container *ifMedia="'phone'">Upload file</ng-container>
    <ng-container *ifMedia="'desktop'">Drag & Drop or click to upload</ng-container>
  </div>

  <input type="file" (change)="onFileChange($event)" class="input">
</div>

<hfc-field-error>{{errorMessage}}</hfc-field-error>
