import { Component, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { PERSONAL_TITLE, PERSONAL_TITLE_NAME } from '../../../fact-find/enums.module';
import { isIOS } from '../../functions/is-ios';

@Component({
  selector: 'hfc-title-dropdown',
  templateUrl: './title-dropdown.component.html',
  styleUrls: ['./title-dropdown.component.scss'],
})
export class TitleDropdownComponent implements ControlValueAccessor {
  public titles = [...(isIOS ? [""] : []), ...PERSONAL_TITLE_NAME.keys()];
  public PERSONAL_TITLE_NAME = PERSONAL_TITLE_NAME;

  public value: PERSONAL_TITLE;

  public get hfInvalid() { return this.ngControl?.invalid && this.ngControl?.dirty }

  constructor(
    @Optional() private ngControl: NgControl
  ) {
    ngControl && (ngControl.valueAccessor = this);
  }

  public onValueChange() {
    this.onChangeFn && this.onChangeFn(this.value);
  }

  private onChangeFn: (value: PERSONAL_TITLE) => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
