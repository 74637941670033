import { Component } from '@angular/core';
import { AdvisorService } from '../../../root/services/advisor.service';

@Component({
  selector: 'hfc-desktop-footer',
  templateUrl: './desktop-footer.component.html',
  styleUrls: ['./desktop-footer.component.scss']
})
export class DesktopFooterComponent {
  public get advisor() { return this.advisorService.advisor }

  constructor(private advisorService: AdvisorService,) { }
}
