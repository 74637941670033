import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const getOrdinal = (day: number) => {
  if (day > 3 && day < 21) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

@Pipe({
  name: 'dateOrdinal',
  pure: true
})
export class DateOrdinalPipe implements PipeTransform {
  constructor(
    private datePipe: DatePipe
  ) { }

  public transform(value: Date, format?: string): any {
    const transformed = this.datePipe.transform(value, format);

    if (!transformed) {
      return transformed;
    }

    const day = this.datePipe.transform(value, "d");
    const ordinal = `${day}${getOrdinal(parseInt(day))}`;

    return transformed.replace(/\bR\b/, ordinal);
  }
}
