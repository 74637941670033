import {Component, ElementRef, HostBinding, Input, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'hfc-code-input',
  templateUrl: './code-input.component.html',
  styleUrls: ['./code-input.component.scss']
})
export class CodeInputComponent {
  @Input()
  @HostBinding("class.invalid")
  public invalid = false;

  @Input()
  public disabled = false;

  @Output()
  public codeEnter = new EventEmitter<string>();
  
  public log = "";
  public l(line) { this.log = `${this.log}\n${line}`};

  private controls: HTMLInputElement[];

  constructor(private el: ElementRef) { }

  private setup() {
    this.controls = [...this.el.nativeElement.querySelectorAll("input[type=tel]")];

    const onKeyUp = (event, i: number) => {
      if (event.keyCode === 8 && event.target.value === "") {
        this.controls[i-1]?.focus();
      }
    }

    const onInput = (event, i: number) => {
      if (!event.data) {
        // this is for apple devices, autofill input event doesn't have data property
        const rawValue = event.target.value;
        this.parseCodeAndFill(rawValue);

        return;
      }

      if (event.data?.length) {
        this.controls[i+1]?.focus();
      } else {
        this.controls[i-1]?.focus();
      }

      this.onEnter();
    }

    const onPaste = (event: ClipboardEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const rawCode: string = event.clipboardData?.getData("text/plain");
      this.parseCodeAndFill(rawCode);
    }

    this.controls.map((c, i) => {
      c.addEventListener("input", e =>  onInput(e, i));
      c.addEventListener("keyup", e =>  onKeyUp(e, i));
      c.addEventListener("paste", e =>  onPaste(e));
    });
  }

  private parseCodeAndFill(rawCode: string) {
    const code = rawCode?.replace(/[^[0-9]/g, "");

    if (code && code?.length === 6) {
      [...code].map((c, i) => this.controls[i].value = c);

      this.onEnter();
    }
  }

  private onEnter() {
    let code = "";

    this.controls.map(c => code = `${code}${c.value}`);

    if (code.length !== 6) {
      return;
    }

    code = `${code.substring(0, 3)}-${code.substring(3)}`;
    this.codeEnter.next(code);
  }

  public ngAfterViewInit() {
    this.setup();
  }
}
