import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { subscribeLastActivatedRouteSnapshotData } from "../../../fact-find/functions/subscribe-last-activated-route-snapshot-data";

@Injectable()
export class StepLayoutService {
  private _closeButton: boolean = false;
  public get closeButton() { return this._closeButton }
  public setCloseButton(closeButton: boolean) {
    this._closeButton = closeButton;
  }

  public closeButtonPath: string;
  public closeButtonFragment: string;
  public isMenuVisible = false;
  public pageTitle: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {
    subscribeLastActivatedRouteSnapshotData(router, route).subscribe(data => {
      this.setCloseButton(!!data.closeButton);
      this.closeButtonPath = data.closeButtonPath;
      this.closeButtonFragment = data.closeButtonFragment;
      this.isMenuVisible = false;
    });
  }
}
