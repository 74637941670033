import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hfc-agreebox',
  templateUrl: './agreebox.component.html',
  styleUrls: ['./agreebox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AgreeboxComponent,
    multi: true
  }]
})
export class AgreeboxComponent implements ControlValueAccessor {
  public checked: boolean = false;

  constructor() { }

  public onClicked() {
    this.checked = !this.checked;
    this.onChange && this.onChange(this.checked);
  }

  private onChange: (value: boolean) => void;

  writeValue(checked: boolean): void {
    this.checked = checked;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
