<div class="terms">
  <div class="terms-content">
    <div class="header">
      <img src="assets/img/Everglades-light-bg.svg" class="logo">
      <img src="assets/external/experian-logo.svg" class="experian">
    </div>

      <ng-container *ngIf="declined">
        You’ve declined our Terms & Conditions which means we can’t help you!<br>
        If you have a change of heart and accept our T&C’s we can pick up where we left off.
      </ng-container>

      <ng-container *ngIf="!declined">
        <div class="title">Before we begin, please review and accept the following:</div>

        <div [innerHTML]="termsAndConditions?.text"></div>
      </ng-container>

      <hfc-spinner *ngIf="isLoading"></hfc-spinner>
  </div>

  <div *ifMedia="'desktop'" class="buttons-desktop">
    <ng-container *ngIf="declined">
      <hfc-button type="secondary" (click)="declined = false">Go Back</hfc-button>
    </ng-container>
    <ng-container *ngIf="!declined">
      <hfc-button type="primary" [isLoading]="isSubmitting" (click)="onSubmit()">Accept</hfc-button>
      <hfc-button *ngIf="!isSubmitting" type="secondary" (click)="declined = true">Decline</hfc-button>
    </ng-container>
  </div>
</div>

<hfc-sticky-footer *ifMedia="'phone'" class="buttons-mobile">
  <ng-container *ngIf="declined">
    <hfc-button type="secondary" (click)="declined = false">Go Back</hfc-button>
  </ng-container>
  <ng-container *ngIf="!declined">
    <hfc-button type="primary" [isLoading]="isSubmitting" (click)="onSubmit()">Accept</hfc-button>
    <hfc-button *ngIf="!isSubmitting" type="secondary" (click)="declined = true">Decline</hfc-button>
  </ng-container>
</hfc-sticky-footer>
