import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { FormFieldService, RegisterControlOptions } from '../../services/form-field.service';

@Directive({
  selector: '[hfFormField]'
})
export class FormFieldDirective implements OnChanges {
  @Input()
  public hfFormField: AbstractControl;

  @Input()
  public options: RegisterControlOptions;

  constructor(
    private el: ElementRef,
    private formFieldService: FormFieldService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if ("hfFormField" in changes) {
      const change = changes["hfFormField"];

      if (change.currentValue) {
        this.formFieldService.registerControl(this.el.nativeElement, change.currentValue, this.options);
      }
    }
  }
}
