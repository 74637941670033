<div *ngIf="label" class="label">
  <span [innerHtml]="label"></span>
  <hfc-tooltip *ngIf="tooltipText" [heading]="label">
    {{tooltipText}}
  </hfc-tooltip>
</div>

<div class="content">
  <ng-content></ng-content>
</div>
