import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "hf-named-icon",
  templateUrl: "./named-icon.component.html",
  styleUrls: ["./named-icon.component.scss"],
})
export class NamedIconComponent implements OnChanges {
  @Input()
  public icon: string;

  public matIcon: string;
  public svgIconUrl: string;

  public ngOnChanges(changes: SimpleChanges) {
    if ("icon" in changes && changes.icon.currentValue) {
      const name: string = changes.icon.currentValue;
      const isSvg = name.toLowerCase().includes(".svg");

      if (isSvg) {
        this.svgIconUrl = `/assets/icons/${name}`;
        this.matIcon = null;
      } else {
        this.svgIconUrl = null;
        this.matIcon = name;
      }
    }
  }
}
