import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'yesNo'
})
export class YesNoPipe implements PipeTransform {
  public transform(value: boolean): string {
    return value === true ? "Yes" : (value === false ? "No" : null);
  }
}
