import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { MORTGAGE_DOCUMENT_TYPE } from "../../../../../../src/app/website/utils/variables.data";
import { Attachment } from "../../../../../../src/app/website/models/attachment";
import { AppointmentType } from "../../self-referral/services/self-referral-api.service";
import { AppointmentTokenInfoDTO, AppointmentTokenRequestDTO } from "./client.enum";
import * as R from "ramda";

export type TASK_TYPE =
  | "REQUEST_DOCUMENT"
  | "FACT_FIND"
  | "PROTECTION_FACT_FIND"
  | "SIGNATURE_REQUEST"

export const TASK_TYPE_NAMES = new Map<TASK_TYPE, string>([
  ["REQUEST_DOCUMENT", "Request Document"],
  ["FACT_FIND", "Fact Find"],
  ["PROTECTION_FACT_FIND", "Protection Fact Find"],
  ["SIGNATURE_REQUEST", "Signature Request"],
]);

export type TASK_STATUS = string;

export const TASK_STATUS_NAMES = new Map<TASK_STATUS, string>([
  ["REQUESTED", "Waiting for you…"],
  ["PROVIDED", "Archived"]
])

export type SignatureRequestType =
  | "PT_DECLARATION"

  | "MORTGAGE_OFFER_ACKNOWLEDGEMENT"
  | "MORTGAGE_SUITABILITY_ACCEPTANCE"
  | "PROTECTION_SUITABILITY_ACCEPTANCE"

export const SignatureRequestTypeName = new Map<SignatureRequestType, string>([
  ["PT_DECLARATION", "Product Transfer Declaration"],

  ["MORTGAGE_OFFER_ACKNOWLEDGEMENT", "Document Acceptance Signature"],
  ["MORTGAGE_SUITABILITY_ACCEPTANCE", "Document Acceptance Signature"],
  ["PROTECTION_SUITABILITY_ACCEPTANCE", "Document Acceptance Signature"],
])

export const SignatureRequestTypeRoute = new Map<SignatureRequestType, string>([
  ["PT_DECLARATION", "product-transfer-declaration"],

  ["MORTGAGE_OFFER_ACKNOWLEDGEMENT", "document-acceptance"],
  ["MORTGAGE_SUITABILITY_ACCEPTANCE", "document-acceptance"],
  ["PROTECTION_SUITABILITY_ACCEPTANCE", "document-acceptance"],
])

export type SignatureRequestState =
  | "WAITING"
  | "SIGNED"
  | "ALL_SIGNED"

export const SignatureRequestStateName = new Map<SignatureRequestState, string>([
  ["WAITING", "Waiting for you…"],
  ["SIGNED", "Signed"],
  ["ALL_SIGNED", "All Signed"],
])

export const SignatureRequestStateNameHeron = new Map<SignatureRequestState, string>([
  ["WAITING", "Waiting"],
  ["SIGNED", "Signed"],
  ["ALL_SIGNED", "All Signed"],
])

export interface Task {
  timestamp: string;
  type: TASK_TYPE;
  status: TASK_STATUS;
  archive: boolean;

  // request document
  requestedTypes?: string;
  token?: string;
  file: Attachment;

  // fact find
  reference?: string;
  mortgageId?: string;
  percentageCompleted: number;

  // protection
  protectionCaseId?: string;

  // signature request
  signatureRequestType?: SignatureRequestType;
  requestId?: string;
  state?: SignatureRequestState;
  declarationFile?: Attachment;
}

export interface HomeDashboardData {
  activeTasks: Task[];
  archivedTasks: Task[];
}

@Injectable({ providedIn: "root" })
export class ClientService {
  private readonly API_NAME = "client";

  constructor(private apiService: ApiService) { }

  public getHomeDashboardData(): Promise<HomeDashboardData> {
    return this.apiService.get(this.API_NAME, "getHomeDashboardData").toPromise();
  }

  public unsubscribeReminder(token: string) {
    return this.apiService.post(this.API_NAME, "unsubscribeReminder", undefined, { params: { token } }).toPromise();
  }

  public unsubscribeMortgage(token: string) {
    return this.apiService.post(this.API_NAME, "unsubscribeMortgage", undefined, { params: { token } }).toPromise();
  }

  public unsubscribeRemortgage(token: string) {
    return this.apiService.post(this.API_NAME, "unsubscribeRemortgage", undefined, { params: { token } }).toPromise();
  }

  public unsubscribe(token: string) {
    return this.apiService.post("interestedParty", "unsubscribe", undefined, { params: { token } }).toPromise();
  }

  public getAppointmentTokenInfo(token: string, mortgageId?: string, protectionId?: string): Promise<AppointmentTokenInfoDTO> {
    const params = R.reject(R.isNil, { token, mortgageId, protectionId });
    return this.apiService.get(this.API_NAME, "getAppointmentTokenInfo", { params }).toPromise();
  }

  public createClientAppointment(token: string, request: AppointmentTokenRequestDTO, mortgageId?: string, protectionId?: string): Promise<void> {
    const params = R.reject(R.isNil, { token, mortgageId, protectionId });
    return this.apiService.post(this.API_NAME, "createClientAppointment", request, { params }).toPromise();
  }

  public requestFactFind(token: string, mortgageId?: string, protectionId?: string): Promise<void> {
    const params = R.reject(R.isNil, { token, mortgageId, protectionId });
    return this.apiService.post(this.API_NAME, "requestFactFind", undefined, { params }).toPromise();
  }
}
