import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'hfc-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input()
  public value: number;

  @Input()
  showValue: boolean = true;

  @Input()
  unit: string = "%";

  @Input()
  radius: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
