import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CheckboxComponent } from '../checkbox/checkbox.component';

@Component({
  selector: 'hfc-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckboxGroupComponent,
    multi: true
  }]
})
export class CheckboxGroupComponent implements AfterViewInit, ControlValueAccessor, OnDestroy {
  @Input()
  public multi: boolean = false;

  public value: any|any[];

  @Output()
  public onValueChange = new EventEmitter<any|any[]>();

  @ContentChildren(CheckboxComponent)
  public checkboxes: QueryList<CheckboxComponent>;

  private destroy$ = new Subject();
  private checkboxesSubscription: Subscription;
  private onChange: (value: any|any[]) => void;

  public ngAfterViewInit() {
    this.subscribeToCheckboxes();

    this.checkboxes.changes.pipe(takeUntil(this.destroy$))
      .subscribe(() => this.subscribeToCheckboxes());

    /** If we have value here, call writeValue again as we already have checkboxes */
    if (typeof this.value !== "undefined") {
      setTimeout(() => this.writeValue(this.value));
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  private subscribeToCheckboxes() {
    if (this.checkboxesSubscription) {
      this.checkboxesSubscription.unsubscribe();
      this.checkboxesSubscription = undefined;
    }

    this.checkboxes.forEach(cb => {
      const sub = cb.onCheckedChange.subscribe(() => this.onCheckboxCheckedChange(cb));

      if (this.checkboxesSubscription) {
        this.checkboxesSubscription.add(sub)
      } else {
        this.checkboxesSubscription = sub;
      }
    })
  }

  private onCheckboxCheckedChange(checkbox: CheckboxComponent) {
    if (!this.multi && checkbox.checked) {
      this.checkboxes
        .filter(cb => cb !== checkbox)
        .forEach(cb => cb.checked = false);
    }

    if (this.multi) {
      this.value = this.checkboxes
        .filter(cb => cb.checked)
        .map(cb => cb.value);
    } else {
      const checked = this.checkboxes.find(cb => cb.checked);

      if (checked) {
        this.value = checked.value;
      } else {
        this.value = undefined;
      }
    }

    this.onChange && this.onChange(this.value);
    this.onValueChange.emit(this.value);
  }

  writeValue(value: any|any[]): void {
    this.value = value;

    if (!this.checkboxes) {
      return;
    }

    const packed: any[] = this.multi
      ? value
      : [value];

    this.checkboxes.forEach(cb => {
      if (packed.includes(cb.value)) {
        !cb.checked && (cb.checked = true);
      } else {
        cb.checked && (cb.checked = false);
      }
    });
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
