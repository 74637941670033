import { Component, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { REGION_TYPE, REGION_TYPE_NAME } from '../../../fact-find/enums.module';
import { isIOS } from '../../functions/is-ios';

@Component({
  selector: 'hfc-region-dropdown',
  templateUrl: './region-dropdown.component.html',
  styleUrls: ['./region-dropdown.component.scss'],
})
export class RegionDropdownComponent implements ControlValueAccessor {
  public items = [...(isIOS ? [""] : []), ...REGION_TYPE_NAME.keys()];
  public REGION_TYPE_NAME = REGION_TYPE_NAME;

  public value: REGION_TYPE;

  public get hfInvalid() { return this.ngControl?.invalid && this.ngControl?.dirty }

  constructor(
    @Optional() private ngControl: NgControl
  ) {
    ngControl && (ngControl.valueAccessor = this);
  }

  public onValueChange() {
    this.onChangeFn && this.onChangeFn(this.value);
  }

  private onChangeFn: (value: REGION_TYPE) => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }
}
