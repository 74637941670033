import { HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

// TODO: merge changes from this into evergaldes ApiService and use just that

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({ providedIn: "root" })
export class ApiService {

  public apiURL: string = environment.apiUrl;
  public rawApiURL: string = environment.rawApiUrl;
  public apiVersion: string = environment.apiVersion;

  constructor(private http: HttpClient) {
  }

  /**
   * Description.
   * @param {string}  apiName a name of called API including version of API
   * @param {string}  methodName a name of called method
   * @param {HttpHeaders| object}  options Decription of options
   * param : {
   *   headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *   };
   *   observe?: 'body';
   *   params?: HttpParams | {
   *       [param: string]: string | string[];
   *   };
   *   reportProgress?: boolean;
   *   responseType: 'arraybuffer';
   *   withCredentials?: boolean;
   * }
   */
  public get<T>(apiName: string, methodName: string, options?: any) {
    const url = `${this.rawApiURL}${apiName}${this.apiVersion}${methodName}`;
    return this.handleResponse(this.http.get<T>(url, this.prepareRequestOptions(options)));
  }

  /**
   * Description.
   * @param {string}  apiName a name of called API including version of API
   * @param {string}  methodName a name of called method
   * @param {HttpHeaders| object}  options Decription of
   * param : {
   *   headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *   };
   *   observe?: 'body';
   *   params?: HttpParams | {
   *       [param: string]: string | string[];
   *   };
   *   reportProgress?: boolean;
   *   responseType: 'arraybuffer';
   *   withCredentials?: boolean;
   * }
   */
  public delete<T>(apiName: string, methodName: string, options?: any) {
    const url = `${this.rawApiURL}${apiName}${this.apiVersion}${methodName}`;
    return this.handleResponse(this.http.delete<T>(url, this.prepareRequestOptions(options)));
  }

  /**
   * Description.
   * @param {string}  apiName a name of called API including version of API
   * @param {string}  methodName a name of called method
   * @param {HttpHeaders| object}  options Decription of options
   * param : {
   *   headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *   };
   *   observe?: 'body';
   *   params?: HttpParams | {
   *       [param: string]: string | string[];
   *   };
   *   reportProgress?: boolean;
   *   responseType: 'arraybuffer';
   *   withCredentials?: boolean;
   * }
   */
  public head<T>(apiName: string, methodName: string, options?: any) {
    const url = `${this.rawApiURL}${apiName}${this.apiVersion}${methodName}`;
    return this.handleResponse(this.http.head<T>(url, this.prepareRequestOptions(options)));
  }

  /**
   * Description.
   * @param {string}  apiName a name of called API including version of API
   * @param {string}  methodName a name of called method
   * @param {string}  callback a name of callback parameter
   */
  public jsonp<T>(apiName: string, methodName: string, callback: string) {
    const url = `${this.rawApiURL}${apiName}${this.apiVersion}${methodName}`;
    return this.handleResponse(this.http.jsonp<T>(url, callback));
  }

  /**
   * Description.
   * @param {string}  apiName a name of called API including version of API
   * @param {string}  methodName a name of called method
   * @param body
   * @param {HttpHeaders| object}  options Decription of options
   * param : {
   *   headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *   };
   *   observe?: 'body';
   *   params?: HttpParams | {
   *       [param: string]: string | string[];
   *   };
   *   reportProgress?: boolean;
   *   responseType: 'arraybuffer';
   *   withCredentials?: boolean;
   * }
   */
  public post<T>(apiName: string, methodName: string, body?: any, options?: any) {
    const url = `${this.rawApiURL}${apiName}${this.apiVersion}${methodName}`;
    return this.handleResponse(this.http.post<T>(url, body, this.prepareRequestOptions(options)));
  }

  public rawPost<T>(url:string, body:File, options?:any) {
    return this.handleResponse(this.http.post<T>(url, body, this.prepareRequestOptions(options)));
  }

  /**
   * Description.
   * @param {string}  apiName a name of called API including version of API
   * @param {string}  methodName a name of called method
   * @param body
   * @param {HttpHeaders| object}  options Decription of options
   * param : {
   *   headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *   };
   *   observe?: 'body';
   *   params?: HttpParams | {
   *       [param: string]: string | string[];
   *   };
   *   reportProgress?: boolean;
   *   responseType: 'arraybuffer';
   *   withCredentials?: boolean;
   * }
   */
  public put<T>(apiName: string, methodName: string, body?: any, options?: any) {
    const url = `${this.rawApiURL}${apiName}${this.apiVersion}${methodName}`;
    return this.handleResponse(this.http.put<T>(url, body, this.prepareRequestOptions(options)));
  }

  /**
   * Description.
   * @param {string}  apiName a name of called API including version of API
   * @param {string}  methodName a name of called method
   * @param body
   * @param {HttpHeaders| object}  options Decription of options
   * param : {
   *   headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *   };
   *   observe?: 'body';
   *   params?: HttpParams | {
   *       [param: string]: string | string[];
   *   };
   *   reportProgress?: boolean;
   *   responseType: 'arraybuffer';
   *   withCredentials?: boolean;
   * }
   */
  public patch<T>(apiName: string, methodName: string, body?: string, options?: any) {
    const url = `${this.rawApiURL}${apiName}${this.apiVersion}${methodName}`;
    return this.handleResponse(this.http.patch<T>(url, body, this.prepareRequestOptions(options)));
  }

  /**
   * Description.
   * @param {HttpRequest}  request request contains {METHOD}, {url}, {init?}
   */
  public request(request: HttpRequest<any>) {
    return this.handleResponse(this.http.request(request));
  }

  public handleErrorResponse(errorObj) {
    return errorObj.error ? errorObj.error.error.message : errorObj.message;
  }

  private handleResponse(observable: Observable<any>) {
    return observable.pipe(
      tap(response => {
        if (response.headers.has('jsessionid')) {
          // document.cookie = 'JSESSIONID=' + response.headers.get('jsessionid') + ';Path=/;Domain=' + environment.config.authDomain;
        }
      }),
      map(response => response.body),
      catchError(error => {
        if (error.status === 0) {
          const errorMessage = {
            message: 'Server is temporarily down'
          };
          return throwError(errorMessage);
        }
        return throwError(error);
      })
    );
  }

  private prepareRequestOptions(options) {
    const isIE11 = !!(window.document as any).documentMode; // https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie
    const headers = isIE11 // disable caching of API calls for IE11
      ? new HttpHeaders({
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT'
      })
      : undefined

    const params = options && options.params
      ? new HttpParams({ fromObject: options.params, encoder: new CustomEncoder() })
      : undefined

    return {
      headers,
      observe: 'response' as 'response', // tslint type checking fails without the string infer
      withCredentials: true,
      ...options,
      params
    };
  }
}
