import { Platform } from '@angular/cdk/platform';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { Occupation, occupations } from './occupations';

@Component({
  selector: 'hfc-origo-code',
  templateUrl: './origo-code.component.html',
  styleUrls: ['./origo-code.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: OrigoCodeComponent,
    multi: true
  }]
})
export class OrigoCodeComponent implements ControlValueAccessor {
  public origoCode: string;
  public searchTerm: string = "";
  public searchTerm$ = new Subject<string>();
  public items: Occupation[];
  public isOpen: boolean = false;
  public isLoading = false;
  public isIosOpening = false;
  public get isHintVisible() { return this.searchTerm.length < 3 && !this.isLoading }

  private touched = false;
  private static occupations: Occupation[] = occupations;

  constructor(
    private el: ElementRef,
    private httpClient: HttpClient,
    private platform: Platform
  ) {
    this.searchTerm$.pipe(
      tap(t => t.length >= 3 || (this.items = [])),
      filter(t => t.length >= 3),
      debounceTime(250),
      map(t => t.toLowerCase()),
      map(t => OrigoCodeComponent.occupations.filter(o => o.OrigoOccupation.toLowerCase().includes(t)))
    ).subscribe(result => {
      this.items = result;
    });
  }

  @HostListener("document:click", ["$event.target"])
  public onDocumentClicked(target) {
    if (this.isOpen && !this.el.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }

  public onSearchTermChanged() {
    this.touched = true;
    this.searchTerm$.next(this.searchTerm);
    this.onChangeFn && this.onChangeFn(this.searchTerm.toUpperCase());
  }

  public onInputClicked() {
    this.isOpen = true;

    if (!this.touched && this.searchTerm.length >= 5) {
      this.touched = true;
      this.searchTerm$.next(this.searchTerm);
    }
  }

  public onItemClicked(item: Occupation) {
    this.isOpen = false;
    this.origoCode = item.OrigoCode;
    this.searchTerm = item.OrigoOccupation;

    this.onChangeFn && this.onChangeFn(this.origoCode);
  }

  public onCancelClicked() {
    this.isOpen = false;
  }

  public onInputBlur() {
    this.onTouchedFn && this.onTouchedFn();
  }

  public onInputFocus() {
    if (this.platform.IOS) {
      this.isIosOpening = true;

      setTimeout(() => {
        window.scrollTo(0, 0);
        this.isIosOpening = false;
      }, 300);
    }
  }

  private onChangeFn: (value: string) => void;
  private onTouchedFn: () => void;

  writeValue(obj: any): void {
    const occupation = occupations.find(o => o.OrigoCode === obj);

    this.searchTerm = occupation?.OrigoOccupation || "";
    this.origoCode = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
