export type PROTECTION_APPLICATION_STEP =
  | "TERMS_CONDITIONS"
  | "APPLICATION_DETAILS"
  | "PREVIOUS_COVER"
  | "HEALTH_OVERVIEW"
  | "MEDICAL_HISTORY"
  | "RECENT_HEALTH"
  | "HEALTH_CONSULTATIONS"
  | "FAMILY_HEALTH"
  | "PASTIMES"
  | "OCCUPATION_DETAILS"
  | "ASSETS"
  | "DEPENDANTS"
  | "POLICY_IN_TRUST"
  | "DIRECT_DEBIT"

export const PROTECTION_APPLICATION_STEPS_NOT_IN_MENU = [
  "TERMS_CONDITIONS"
];

export const PROTECTION_APPLICATION_STEP_NAME = new Map<PROTECTION_APPLICATION_STEP, string>([
  ["TERMS_CONDITIONS", "Terms and Conditions"],
  ["APPLICATION_DETAILS", "Applicant Details"],
  ["PREVIOUS_COVER", "Existing Cover"],
  ["HEALTH_OVERVIEW", "Health Overview"],
  ["MEDICAL_HISTORY", "Medical History"],
  ["RECENT_HEALTH", "Recent Health"],
  ["HEALTH_CONSULTATIONS", "Health Consultations"],
  ["FAMILY_HEALTH", "Family Health"],
  ["PASTIMES", "Pastimes"],
  ["OCCUPATION_DETAILS", "Occupation Details"],
  ["ASSETS", "Assets"],
  ["DEPENDANTS", "Dependants"],
  ["POLICY_IN_TRUST", "Policy In Trust"],
  ["DIRECT_DEBIT", "Direct Debit"],
])

export const PROTECTION_APPLICATION_STEP_URL = new Map<PROTECTION_APPLICATION_STEP, string>([
  ["TERMS_CONDITIONS", "terms-and-conditions"],
  ["APPLICATION_DETAILS", "applicant-details"],
  ["DEPENDANTS", "dependants"],
  ["PREVIOUS_COVER", "existing-cover"],
  ["HEALTH_OVERVIEW", "health-overview"],
  ["MEDICAL_HISTORY", "medical-history"],
  ["RECENT_HEALTH", "recent-health"],
  ["HEALTH_CONSULTATIONS", "health-consultations"],
  ["FAMILY_HEALTH", "family-health"],
  ["PASTIMES", "pastimes"],
  ["OCCUPATION_DETAILS", "occupation-details"],
  ["ASSETS", "assets"],
  ["POLICY_IN_TRUST", "policy-in-trust"],
  ["DIRECT_DEBIT", "direct-debit"],
])

export type PolicyCover =
  | "LIFE"
  | "CRITICAL_ILLNESS"
  | "INCOME"

export const PolicyCoverName = new Map<PolicyCover, string>([
  ["LIFE", "Life Cover"],
  ["CRITICAL_ILLNESS", "Critical Illness Cover"],
  ["INCOME" , "Income Protection"],
])

export type PolicyType =
  |  "LEVEL"
  |  "INCREASING"
  |  "DECREASING"

export const PolicyTypeName = new Map<PolicyType, string>([
  ["LEVEL", "Level"],
  ["INCREASING", "Increasing"],
  ["DECREASING", "Decreasing"],
])

export type IncomeBenefit =
  | "SHORT_TERM"
  | "FULL_TERM"

export const IncomeBenefitName = new Map<IncomeBenefit, string>([
  ["SHORT_TERM", "Short term"],
  ["FULL_TERM", "Full term"],
])


export type WeightChange =
  | "NO"
  | "GAINED"
  | "LOST"

export const WeightChangeName = new Map<WeightChange, string>([
  ["NO", "No"],
  ["GAINED", "Gained"],
  ["LOST", "Lost"],
])

export type VapeOrEcig =
  | "NONE"
  | "VAPE"
  | "ECIG"
  | "BOTH"

export const VapeOrEcigName = new Map<VapeOrEcig, string>([
  ["NONE", "None"],
  ["VAPE", "Vape"],
  ["ECIG", "E-Cigarettes"],
  ["BOTH", "Both"],
])

export type PolicyTrusteeRelationship =
  | "PARENT"
  | "CHILD"
  | "SPOUSE"
  | "SIBLING"
  | "GRANDPARENT"
  | "GRANDCHILD"
  | "FRIEND"
  | "OTHER"

export const PolicyTrusteeRelationshipName = new Map<PolicyTrusteeRelationship, string>([
  ["PARENT", "Parent"],
  ["CHILD", "Child"],
  ["SPOUSE", "Spouse"],
  ["SIBLING", "Sibling"],
  ["GRANDPARENT", "Grandparent"],
  ["GRANDCHILD", "Grandchild"],
  ["FRIEND", "Friend"],
  ["OTHER", "Other"],
])

export type RecentHealthCategory =
  | "SKIN"
  | "HEART"
  | "RESPIRATORY"
  | "NERVES"
  | "SEIZURES"
  | "EYES_EARS"
  | "BONES_JOINTS"
  | "DIGESTIVE"
  | "BLOOD"
  | "THYROID"
  | "URINARY"
  | "SEX"
  | "DEPRESSION"

export const RecentHealthCategoryItems: RecentHealthCategory[] = [
  "SKIN",
  "HEART",
  "RESPIRATORY",
  "NERVES",
  "SEIZURES",
  "EYES_EARS",
  "BONES_JOINTS",
  "DIGESTIVE",
  "BLOOD",
  "THYROID",
  "URINARY",
  "SEX",
  "DEPRESSION",
]

export type FamilyMember =
  | "FATHER"
  | "MOTHER"
  | "GRANDFATHER"
  | "GRANDMOTHER"
  | "SIBLING"
  | "OTHER"

export const FamilyMemberName = new Map<FamilyMember, string>([
  ["FATHER", "Father"],
  ["MOTHER", "Mother"],
  ["GRANDFATHER", "Grandfather"],
  ["GRANDMOTHER", "Grandmother"],
  ["SIBLING", "Sibling"],
  ["OTHER", "Other"],
])

export type FamilyConditionCategory =
  | "HEART"
  | "STROKE"
  | "CHOLESTEROL"
  | "CANCER"
  | "CANCER_BREAST"
  | "CANCER_OVARIAN"
  | "CANCER_COLON"
  | "CANCER_OTHER"
  | "DIABETES"
  | "SCLEROSIS"
  | "HUNTINGTONS"
  | "KIDNEY"
  | "BOWEL"
  | "ALZHEIMERS"
  | "PARKINSONS"
  | "MUSCULAR"
  | "MOTOR_NEURONE"
  | "OTHER"

export const FamilyConditionCategoryItems = [
  "HEART",
  "STROKE",
  "CHOLESTEROL",
  "CANCER",
  "CANCER_BREAST",
  "CANCER_OVARIAN",
  "CANCER_COLON",
  "CANCER_OTHER",
  "DIABETES",
  "SCLEROSIS",
  "HUNTINGTONS",
  "KIDNEY",
  "BOWEL",
  "ALZHEIMERS",
  "PARKINSONS",
  "MUSCULAR",
  "MOTOR_NEURONE",
  "OTHER",
]

export type PolicyInTrust =
  | "YES"
  | "NO"
  | "MAYBE_LATER"

export const PolicyInTrustName = new Map<PolicyInTrust, string>([
  ["YES", "Yes"],
  ["NO", "No"],
])

export const PolicyInTrustNameAll = new Map<PolicyInTrust, string>([
  ["YES", "Yes"],
  ["NO", "No"],
  ["MAYBE_LATER", "Maybe Later"],
])

export type ApplicantStatus =
  | "WAITING"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "SIGNED"
