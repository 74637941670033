import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

const PREDEFINED_MEDIA = new Map<string, string>([
  ["desktop", "(min-width: 768px)"],
  ["phone", "not all and (min-width: 768px)"]
]);

@Injectable()
export class MediaQueryService {
  private subscriptions: { [media: string]: Observable<BreakpointState> } = {};

  constructor(
    private breakpointObserver: BreakpointObserver
  ) { }

  public subscribeToMedia(media: string): Observable<BreakpointState> {
    media = PREDEFINED_MEDIA.get(media) || media;

    if (this.subscriptions[media]) {
      return this.subscriptions[media];
    }


    const observe = this.breakpointObserver.observe(media).pipe(shareReplay(1));
    this.subscriptions[media] = observe;

    return observe;
  }
}
