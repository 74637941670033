<a [href]="getDownloadLink(document.file)" target="_blank" class="container">
  <img *ngIf="!isError && document?.file?.folder" [hfSrcset]="document.file.folder + '/' + document.file.thumbnail" (error)="isError = true">
  <i *ngIf="isError || !document?.file?.folder" class="material-icons">insert_photo</i>
</a>

<div class="filename">
  <ng-container *ngIf="showFilename">
    {{ document.file.originalFilename || document.file.filename }}
  </ng-container>
  <ng-container *ngIf="!showFilename">
    {{ document.customType || DOCUMENT_TYPE_NAME.get(document.type) }}
  </ng-container>
</div>
