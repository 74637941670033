import { Component } from '@angular/core';
import { DialogService, Confirmation } from '../../services/dialog.service';
import { fadeAnimation } from '../../animations/fade.animation';
import { trigger, transition, animateChild, style, animate, group, query } from '@angular/animations';
import { slideUpAnimation } from '../../animations/slide-up.animation';

@Component({
  selector: 'hfc-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  animations: [fadeAnimation, slideUpAnimation]
})
export class DialogComponent {
  public get confirmation(): Confirmation { return this.dialogService.confirmation; };
  public get isAcceptButtonLoading() { return this.dialogService.isAcceptButtonLoading };

  constructor(private dialogService: DialogService) { }

  public onAcceptClicked() {
    this.dialogService.accept();
  }

  public onRejectClicked() {
    this.dialogService.reject();
  }
}
