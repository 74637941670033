import { Component, Input, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'hfc-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
  @Input()
  public title: string;

  @Input()
  public details: string;

  @Input()
  public clickable = true;

  @ContentChild("detailsTemplate")
  public detailsTemplate: TemplateRef<any>;
}
