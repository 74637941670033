import { Component, Input, OnInit } from '@angular/core';
import { DOCUMENT_TYPE_NAME, DOCUMENT_TYPE } from '../../../fact-find/enums.module';
import { Attachment } from '../../../../../../../src/app/website/models/attachment';
import { Environment } from '../../../../../../../src/app/website/services/environment.service';

export interface DocumentThumbnail {
  type?: DOCUMENT_TYPE;
  customType?: string;

  file: Attachment;
}

@Component({
  selector: 'hfc-document-thumbnail',
  templateUrl: './document-thumbnail.component.html',
  styleUrls: ['./document-thumbnail.component.scss']
})
export class DocumentThumbnailComponent implements OnInit {
  public DOCUMENT_TYPE_NAME = DOCUMENT_TYPE_NAME;

  @Input()
  public document: DocumentThumbnail;

  @Input()
  public showFilename = false;

  public isError = false;

  constructor(
    private environment: Environment
  ) { }

  public getDownloadLink(document: Attachment) {
    if (document.externalURL)
      return document.externalURL;
    else
      return `${this.environment.apiUrl}servlet/gcs/${document.folder}/${document.filename}`;
  }

  public ngOnInit() {
    this.isError = !this.document?.file?.thumbnail;
  }
}
