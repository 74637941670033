import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../root/services/user.service';

@Component({
  selector: 'hfc-header-with-logo',
  templateUrl: './header-with-logo.component.html',
  styleUrls: ['./header-with-logo.component.scss']
})
export class HeaderWithLogoComponent implements OnInit {
  public get name() { return this.userService.user.fullName }
  public get role() { return this.userService.user.email }

  @Input()
  public showUser = true;

  constructor(private userService: UserService) { }

  ngOnInit() {
  }

}
