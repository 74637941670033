import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'hfc-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {
  @Input()
  public type: "primary" | "secondary" | "tertiary" | "red" | "outline";

  @Input()
  public submit = false;

  @Input()
  public size: "normal" | "small" = "normal";

  @Input()
  public icon: string;

  @Input()
  public postfixIcon: string;

  @Input()
  public disabled: boolean;

  @Input()
  public isLoading: boolean = false;

  @HostBinding('style.pointer-events')
  public get pointerEvents() { return this.disabled || this.isLoading ? "none" : "auto" }

  constructor() { }

  ngOnInit() {
  }

}
