import { AfterViewInit } from '@angular/core';
import { Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hfc-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextareaInputComponent,
    multi: true
  }]
})
export class TextareaInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input()
  @HostBinding("class.readonly")
  public readonly: boolean

  @HostBinding("class.focus")
  public focus = false;

  @Input()
  public rows = 3;

  @Input()
  public autoexpand = false;

  @Input()
  public editor = false;

  @ViewChild('textarea')
  public textarea: ElementRef;

  public value: string;

  private initialHeight: number = 0;
  private height: number = 0;

  constructor() { }

  public onModelChange() {
    this.onChange && this.onChange(this.value);
    this.resize();
  }

  public onBlur() {
    this.onTouchedFn && this.onTouchedFn();
  }

  public ngAfterViewInit() {
    this.initialHeight = this.textarea?.nativeElement.offsetHeight
  }

  public command(cmd: string) {
    const selection = document.getSelection()?.toString();

    document.execCommand(cmd, false, selection);
  }

  private resize() {
    if (!this.textarea || !this.autoexpand) {
      return;
    }

    const target = this.textarea.nativeElement;
    const height = Math.max(this.initialHeight, this.height, target.scrollHeight);

    if (height !== this.height) {
      this.height = height;
      target.style.minHeight = `${this.height}px`;
    }
  }

  private onChange: (value: string) => void;
  private onTouchedFn: () => void;

  public writeValue(obj: any): void {
    this.value = obj;
    setTimeout(() => this.resize());
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
