<input *ifMedia="'phone'"
  type="date"
  [value]="value|date:'yyyy-MM-dd'"
  [attr.min]="nativeInputMin"
  [attr.max]="nativeInputMax"
  (change)="onInputDateChanged($event.target.value)">

<ng-container *ifMedia="'desktop'">
  <div class="value" (click)="onValueClicked()" [class.is-open]="isOpen">
    {{value | date:'d MMM y'}} &nbsp;
    <i class="material-icons">event</i>
  </div>

  <div *ngIf="isOpen" class="pane" [class.up]="openDirection == 'UP'" [class.down]="openDirection == 'DOWN'">
    <div class="controls">
      <hfc-button icon="arrow_back" (click)="onPrevMonthClicked()"></hfc-button>
      <div>
        <div class="dropdown">
          <div class="dropdown-value">
            {{MONTHS.get(month)}}
          </div>
          <i class="material-icons">arrow_drop_down</i>
          <select [ngModel]="month" (ngModelChange)="onMonthChanged($event)" class="select">
            <option *ngFor="let m of MONTHS | keyvalue" [ngValue]="m.key">{{m.value}}</option>
          </select>
        </div>

        <div class="dropdown">
          <div class="dropdown-value">
            {{year}}
          </div>
          <i class="material-icons">arrow_drop_down</i>
          <select [ngModel]="year" (ngModelChange)="onYearChanged($event)">
            <option *ngFor="let y of years" [value]="y">{{y}}</option>
          </select>
        </div>
      </div>
      <hfc-button icon="arrow_forward" (click)="onNextMonthClicked()"></hfc-button>
    </div>

    <table>
      <tr>
        <td *ngFor="let day of rows[0]" [title]="day | date:'EEEE'" class="day-name">
          {{ day | date:'EEEEE' }}
        </td>
      </tr>
      <tr *ngFor="let row of rows">
        <td *ngFor="let day of row" >
          <div class="day" (click)="onDayClicked(day)"
                [class.selected]="value && day.getTime() === value.getTime()"
                [class.today]="day.getTime() === today.getTime()"
                [class.out]="(day.getMonth() + 1) !== this.month || !isDayAllowed(day)">
            {{ day | date:'d' }}
          </div>
        </td>
      </tr>
    </table>
  </div>
</ng-container>

<span class="material-icons error-icon">error</span>
