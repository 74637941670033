import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// TODO: make strategy onpush
@Component({
  selector: 'hfc-checkbox-list',
  templateUrl: './checkbox-list.component.html',
  styleUrls: ['./checkbox-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CheckboxListComponent,
    multi: true
  }]
})
export class CheckboxListComponent implements ControlValueAccessor {
  @ContentChild("itemTemplate")
  itemTemplate: TemplateRef<any>;

  @ContentChild("hintTemplate")
  hintTemplate: TemplateRef<any>;

  @Input()
  public items: any[];

  private _map: Map<any, string>;
  @Input()
  public set map(value: Map<any, string>) {
    if (!value) {
      return;
    }

    this._map = value;
    this.items = Array.from(value.keys());
  }
  public get map() { return this._map }

  @Input()
  public isMulti: boolean = false;

  @Input()
  public readonly: boolean;

  public checked: any[];

  public isChecked(item: any) {
    // console.log("isChecked: ", item, this.checked);
    return this.checked && this.checked.includes(item);
  }

  public onClicked(item: any) {
    if (!this.isMulti) {
      const includes = this.checked?.includes(item);

      this.checked = includes
        ? []
        : [item];

      this.onChange();
    } else {
      if (this.checked && this.checked.includes(item)) {
        this.checked = this.checked.filter(c => c !== item);
      } else {
        this.checked = [...(this.checked || []), item];
      }

      this.onChange();
    }
  }

  private onChange() {
    this.onChangeFn && this.onChangeFn(this.isMulti ? this.checked : (this.checked && this.checked[0]));
  }

  private onChangeFn: (value: any[]) => void;

  writeValue(obj: any|any[]): void {
    // console.log("writeValue: ", obj, this.items);
    if (Array.isArray(obj)) {
      this.checked = obj;
    } else {
      this.checked = typeof obj !== "undefined" ? [obj] : obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
