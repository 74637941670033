import { ActivatedRoute, Data, NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { getLastActivatedRouteSnapshotData } from "./get-last-activated-route-snapshot-data";

export function subscribeLastActivatedRouteSnapshotData(router: Router, route: ActivatedRoute): Observable<Data> {
  return router.events.pipe(
    filter(e => e instanceof NavigationEnd),
    map(() => getLastActivatedRouteSnapshotData(route.snapshot))
  );
}
