import { Component, Input, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

@Component({
  selector: 'hfc-yes-no-checkbox',
  templateUrl: './yes-no-checkbox.component.html',
  styleUrls: ['./yes-no-checkbox.component.scss'],
})
export class YesNoCheckboxComponent implements ControlValueAccessor {
  @Input()
  public readonly: boolean;

  public value: boolean;

  public onValueChange() {
    this.onChangeFn && this.onChangeFn(this.value);
  }

  public get hfInvalid() { return this.ngControl?.invalid && this.ngControl?.dirty }

  constructor(
    @Optional() private ngControl: NgControl
  ) {
    this.ngControl && (this.ngControl.valueAccessor = this);
  }

  private onChangeFn: (value: boolean) => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
