import { Injectable } from "@angular/core";
import { AbstractControl, FormGroup } from "@angular/forms";

export interface RegisterControlOptions {
  preventAutofocus?: boolean;
}

@Injectable()
export class FormFieldService {
  private controls: [HTMLElement, AbstractControl, RegisterControlOptions][] = [];

  public registerControl(element: HTMLElement, control: AbstractControl, options?: RegisterControlOptions) {
    this.controls.push([element, control, options]);
  }

  public checkFormValidity(form: FormGroup): boolean {
    this.updateValueAndValidityFormGroup(form);
    form.updateValueAndValidity();

    if (form.invalid) {
      this.scrollAndFocusInvalidControl();
    }

    return form.valid;
  }

  public scrollAndFocusInvalidControl() {
    for (const [element, control, options] of this.getControlsSortedByPosition()) {
      if (control.invalid) {
        const inputElement = element.querySelector("input") || element.querySelector("textarea");

        inputElement && !options?.preventAutofocus && inputElement.focus();
        element.scrollIntoView({ behavior: "smooth" });

        return;
      }
    }
  }

  private getControlsSortedByPosition() {
    return this.controls.sort(([element1], [element2]) => {
      const element1top = element1.getBoundingClientRect().top;
      const element2top = element2.getBoundingClientRect().top;

      return element1top - element2top;
    });
  }

  private updateValueAndValidityFormGroup(formGroup: FormGroup) {
    Object.entries(formGroup.controls).forEach(([_, control]) => {
      if (control instanceof FormGroup) {
        this.updateValueAndValidityFormGroup(control);
      }

      control.enabled && control.markAsDirty({ onlySelf: true });
      control.updateValueAndValidity({ onlySelf: true, emitEvent: false });
    });
  }
}
