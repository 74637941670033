import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HfValidators } from '../../../../../../src/app/website/utils/form.validators';

const uploadUrl = `${environment.apiUrl}/servlet/uploadFile`;

export interface UploadedFile {
  createdDate: string;
  filename: string;
  folder: string;
  id: string
  originalFilename?: string;
  thumbnail?: string;
}

@Injectable({ providedIn: "root" })
export class FileUploadService {
  constructor(private httpClient: HttpClient) { }

  public upload(file: File): Promise<UploadedFile> {
    HfValidators.validateMaxFileSizeDisk(file);

    return this.httpClient.post<UploadedFile>(uploadUrl, file, {
      params: { filename: file.name },
      headers: { "Content-Type": file.type },
      withCredentials: true
    }).toPromise();
  }
}
