import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MONTHS } from '../../../fact-find/enums.module';
import { toLocalDateISO } from '../../functions/to-local-date-iso';
import { parseNumber } from '../../../fact-find/functions/parse-number';

function getYears(min?: Date, max?: Date): number[] {
  const thisYear = new Date().getFullYear();
  const yearsBefore = min
    ? thisYear - min.getFullYear()
    : 100;
  const yearsAfter = max
    ? max.getFullYear() - thisYear
    : 50;

  const before = new Array(yearsBefore).fill(0).map((e, i) => thisYear - i - 1).reverse();
  const after = new Array(yearsAfter).fill(0).map((e, i) => thisYear + 1 + i);

  return [...before, thisYear, ...after];
}

function stripTime(date: Date): Date {
  date.setHours(0, 0, 0, 0);

  return date;
}

const firstDayOfWeekOffset = 1;

@Component({
  selector: 'hfc-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CalendarComponent,
    multi: true
  }]
})
export class CalendarComponent implements OnInit, ControlValueAccessor {
  @Input()
  public year: number = new Date().getFullYear();

  @Input()
  public min: Date;

  @Input()
  public max: Date;

  public years: number[];
  public MONTHS = MONTHS;

  public today = stripTime(new Date());
  public month: number = new Date().getMonth() + 1; // 1-based month
  public isOpen = false;
  public openDirection: "UP" | "DOWN" = "DOWN";

  public value: Date = this.today;

  public nativeInputMin: string;
  public nativeInputMax: string;

  rows: Date[][];

  constructor(private el: ElementRef) { }

  @HostListener("document:click", ["$event.target"])
  public onDocumentClicked(target) {
    if (this.isOpen && !this.el.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }

  public ngOnInit() {
    this.generateMonthDaysTable();

    if (this.min) {
      this.nativeInputMin = toLocalDateISO(this.min).substring(0, 10);
    }
    if (this.max) {
      this.nativeInputMax = toLocalDateISO(this.max).substring(0, 10);
    }

    this.years = getYears(this.min, this.max);
  }

  public onValueClicked() {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      const rect: ClientRect = this.el.nativeElement.getBoundingClientRect();
      this.openDirection = window.innerHeight - rect.bottom > 400
        ? "DOWN"
        : "UP";
    }
  }

  public onYearChanged(event: string) {
    const year = parseNumber(event);
    this.year = year;

    this.generateMonthDaysTable();

    if (this.value) {
      let value = new Date(this.year, this.month - 1, this.value.getDate());
      if (value.getMonth() !== this.value.getMonth()) {
        value = new Date(this.year, this.month - 1, 1);
      }

      this.value = value;
      this.onChangeFn && this.onChangeFn(this.value);
    }
  }

  public onMonthChanged(event: string) {
    const month = parseNumber(event);
    this.month = month;

    this.generateMonthDaysTable();

    if (this.value) {
      let value = new Date(this.year, this.month - 1, this.value.getDate());
      if (value.getMonth() !== (month - 1)) {
        value = new Date(this.year, this.month - 1, 1);
      }

      this.value = value;
      this.onChangeFn && this.onChangeFn(this.value);
    }
  }

  public generateMonthDaysTable() {
    const init = new Date(this.year, this.month - 1, 1, 0, 0, 0, 0);
    this.createDates(init);
  }

  public onDayClicked(day: Date) {
    if (day.getMonth() + 1 != this.month || !this.isDayAllowed(day)) {
      return;
    }
    // console.log("onDayClicked: ", day);

    this.value = day;
    this.onChangeFn && this.onChangeFn(this.value);
    this.isOpen = false;
  }

  public isDayAllowed(day: Date) {
    return (!this.min || this.min <= day) && (!this.max || this.max >= day);
  }

  public onInputDateChanged(value: string) {
    const parts = value.split("-").map(Number);

    this.value = new Date(parts[0], parts[1] - 1, parts[2]);
    this.onChangeFn && this.onChangeFn(this.value);
  }

  public onNextMonthClicked() {
    this.month++;

    if (this.month > 12) {
      this.month = 1;
      this.year++;
    }

    this.generateMonthDaysTable();
  }

  public onPrevMonthClicked() {
    this.month--;

    if (this.month < 1) {
      this.month = 12;
      this.year--;
    }

    this.generateMonthDaysTable();
  }

  private createDates(init: Date) {
    // console.log("createDates: ", init);

    const dates: Date[] = [];
    const day = new Date(new Date(init).setDate(1));
    const month = day.getMonth();
    // console.log("day: ", day);
    do {
      dates.push(new Date(day));
      day.setDate(day.getDate() + 1);
    } while (day.getMonth() === month)
    // console.log("dates: ", [...dates]);

    // add days from next month
    const last = new Date(dates[dates.length - 1]);
    // console.log("last day: ", last);
    while ((last.getDay() + firstDayOfWeekOffset + 6) % 7 != 0) {
      last.setDate(last.getDate() + 1);
      dates.push(new Date(last));
    }
    // console.log("next: ", [...dates]);

    // add days from previous month
    const first = new Date(dates[0]);
    while ((first.getDay() + firstDayOfWeekOffset + 6) % 7 != 1) {
      first.setDate(first.getDate() - 1);
      dates.unshift(new Date(first));
    }
    // console.log("previuos: ", [...dates]);

    const rows: Date[][] = [];
    for (let i = 0; i < dates.length; i += 7) {
      rows.push(dates.slice(i, i + 7));
    }

    this.rows = rows.map(r => r.map(stripTime));
  }

  onChangeFn: (value: Date) => void;

  writeValue(obj: any): void {
    // console.log("writeValue: ", obj);

    this.value = obj;

    if (obj instanceof Date) {
      this.year = obj.getFullYear();
      this.month = obj.getMonth() + 1;
      this.generateMonthDaysTable();
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
