import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import SignaturePad from "signature_pad";

@Component({
  selector: 'hfc-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SignaturePadComponent,
    multi: true
  }]
})
export class SignaturePadComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild("canvas")
  public canvas: ElementRef;

  private signaturePad: SignaturePad;
  private value: string;

  public ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvas.nativeElement, {
      onEnd: () => this.onSignatureChange()
    });

    // offset fix
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.nativeElement.width = this.canvas.nativeElement.offsetWidth * ratio;
    this.canvas.nativeElement.height = this.canvas.nativeElement.offsetHeight * ratio;
    this.canvas.nativeElement.getContext("2d").scale(ratio, ratio);

    this.value && this.signaturePad.fromDataURL(this.value);
  }

  public onRemoveClicked() {
    this.signaturePad.clear();
  }

  private onSignatureChange() {
    const signature = this.signaturePad.toDataURL();

    this.onChangeFn && this.onChangeFn(signature);
  }

  private onChangeFn: (value) => void;

  public writeValue(obj: any): void {
    if (obj) {
      this.value = obj;
      this.signaturePad && this.signaturePad.fromDataURL(obj);
    }
  }
  public registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  public registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
