import { Component, Input } from '@angular/core';

@Component({
  selector: 'hfc-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent {
  @Input()
  public label: string;

  @Input()
  public tooltipText: string;
}
