import { Injectable } from "@angular/core";
import { AdvisorDTO } from "../models/advisor.dto";

@Injectable({ providedIn: "root" })
export class AdvisorService {
  public userAdvisor: AdvisorDTO;
  public productAdvisor: AdvisorDTO;

  public get advisor() { return this.productAdvisor || this.userAdvisor }
}
