import { AbstractControl, FormGroup } from "@angular/forms";

export function setControlEnabled(control: AbstractControl, enabled: boolean) {
  if (control.enabled === enabled) {
    return;
  }

  enabled
    ? control.enable({ onlySelf: true })
    : control.disable({ onlySelf: true });
}

export const setEnabledBy = (form: FormGroup, controlName: string, logic: (value: any) => boolean) => {
  form.valueChanges.subscribe(value => {
    const enabled = logic(value);
    setControlEnabled(form.get(controlName), enabled);
  });
}
