import { Component, Input, HostBinding, HostListener } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'hfc-expense-input',
  templateUrl: './expense-input.component.html',
  styleUrls: ['./expense-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: ExpenseInputComponent,
    multi: true
  }]
})
export class ExpenseInputComponent implements ControlValueAccessor {
  public value: number;

  @HostBinding("class.locked")
  @Input()
  public lock: boolean;

  @Input()
  public initials: "MOGO" | string;

  @HostBinding("class.disabled")
  @Input()
  public disabled: boolean = false;

  @HostBinding("class.has-green-border")
  public get hasGreenBorder() { return this.value !== null && !this.disabled }

  public onInput(value: number) {
    this.value = value;

    this.onChangeFn && this.onChangeFn(this.value);
  }

  @HostListener("click")
  public onClick() {
    if (this.disabled) {
      return;
    }

    this.lock = false;
  }

  public onTouchedFn: () => void;
  private onChangeFn: (value: number) => void;

  writeValue(obj: number): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
