<div class="header">
  <div class="titles">
    <div *ngIf="heading" class="title">{{heading}}</div>
    <div *ngIf="subTitle || subTitleTemplate" class="subtitle">
      <ng-container *ngTemplateOutlet="subTitle ? defaultSubTitleTemplate : subTitleTemplate">
      </ng-container>
    </div>
    <div *ngIf="state?.updatedBy" class="savedBy">
      Saved by {{ state.updatedBy.fullName }} at {{state?.lastUpdated | date:'HH:mm on E d MMM y'}}
    </div>
  </div>

  <hfc-button *ngIf="state?.buttonLabel" [type]="state?.buttonType" size="small" [isLoading]="state?.buttonIsLoading" (click)="onButtonClicked()">{{state?.buttonLabel}}</hfc-button>
</div>

<div *ngIf="state?.returnComment" class="returnComment">
  {{state?.returnComment}}
  <div class="lastUpdated">{{state?.lastUpdated | date:'HH:mm on E d MMM y'}}</div>
</div>

<div class="content">
  <ng-content *ngIf="state?.stepStatus != 'NOT_STARTED'"></ng-content>

  <div *ngIf="state?.stepStatus == 'NOT_STARTED'" class="notReady">
    Waiting for applicant to complete
  </div>
</div>
<div class="subsections">
  <ng-content select="hfc-review-section"></ng-content>
</div>
<div class="review-section-separator"></div>

<ng-template #defaultSubTitleTemplate>
  {{subTitle}}
</ng-template>
