import { Directive, EmbeddedViewRef, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaQueryService } from '../services/media-query.service';

@Directive({
  selector: '[ifMedia]'
})
export class IfMediaDirective implements OnDestroy {
  private viewRef: EmbeddedViewRef<any>;
  private elseViewRef: EmbeddedViewRef<any>;
  private elseTemplateRef: TemplateRef<any>;

  private observeSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private mediaQueryService: MediaQueryService,
  ) { }

  @Input()
  public set ifMedia(media: string) {
    this.observeSubscription && this.observeSubscription.unsubscribe();
    this.observeSubscription = this.mediaQueryService.subscribeToMedia(media)
      .subscribe(value => this.updateView(value.matches));
  }

  @Input()
  public set ifMediaElse(templateRef: TemplateRef<any>) {
    this.elseTemplateRef = templateRef;
    this.elseViewRef = undefined;
  }

  public ngOnDestroy() {
    this.observeSubscription && this.observeSubscription.unsubscribe();
  }

  private updateView(matches: boolean) {
    if (matches) {
      if (!this.viewRef) {
        this.viewContainer.clear();
        this.elseViewRef = undefined;
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.viewRef = undefined;
        this.elseTemplateRef && this.viewContainer.createEmbeddedView(this.elseTemplateRef);
      }
    }
  }
}
