import { Component } from '@angular/core';
import { AdvisorService } from '../../../root/services/advisor.service';
import { HelpPopupService } from '../help-popup/help-popup.service';

@Component({
  selector: 'hfc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],

})
export class FooterComponent {
  constructor(
    private advisorService: AdvisorService,
    private helpPopupService: HelpPopupService
  ) { }

  public get advisor() { return this.advisorService.advisor }

  public onHelpClicked() {
    this.helpPopupService.visible = true;
  }
}
