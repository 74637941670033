import { Component, Input } from '@angular/core';

@Component({
  selector: 'hfc-info-box-container',
  templateUrl: './info-box-container.component.html',
  styleUrls: ['./info-box-container.component.scss']
})
export class InfoBoxContainerComponent {
  @Input()
  public label: string;

  @Input()
  public hint: string;
}
