import { Component, Input } from '@angular/core';

@Component({
  selector: 'hfc-review-section-item',
  templateUrl: './review-section-item.component.html',
  styleUrls: ['./review-section-item.component.scss']
})
export class ReviewSectionItemComponent {
  @Input()
  public label: string;
}
