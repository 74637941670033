<div class="container" [class.clickable]="clickable">
  <div class="title">{{title}}</div>

  <ng-container *ngTemplateOutlet="detailsTemplate || defaultDetailsTemplate">
  </ng-container>

  <i *ngIf="clickable" class="material-icons">chevron_right</i>
</div>

<ng-template #defaultDetailsTemplate>
  <div class="details" [innerHtml]="details"></div>
</ng-template>
