import { Component, Input } from '@angular/core';
import { UploadedFile } from '../../../root/services/file-upload.service';

@Component({
  selector: 'hfc-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent {
  @Input()
  file: UploadedFile;
}
