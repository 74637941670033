import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TermsAndConditions } from '../../../fact-find/services/fact-find-api.service';

@Component({
  selector: 'hfc-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  @Input()
  public isLoading = true;

  @Input()
  public isSubmitting = false;

  @Input()
  public termsAndConditions: TermsAndConditions;

  @Output()
  public agree = new EventEmitter();

  public declined = false;

  public async onSubmit() {
    this.agree.emit();
  }
}
