import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "hf-inject-svg",
  templateUrl: "./inject-svg.component.html",
  styleUrls: ["./inject-svg.component.scss"],
})
export class InjectSvgComponent implements OnChanges {
  @Input()
  public src: string;

  constructor(
    private el: ElementRef,
  ) {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ("src" in changes) {
      changes.src.currentValue && this.loadSvg();
    }
  }

  private async loadSvg() {
    try {
      const res = await fetch(this.src);
      const svg = await res.text();

      this.el.nativeElement.innerHTML = svg;
    } catch (e) {
      console.error("Failed to load SVG: ", e);
    }
  }
}
