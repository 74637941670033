import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { UserService } from '../../../root/services/user.service';
import { HelpPopupService } from './help-popup.service';
import { AdvisorService } from '../../../root/services/advisor.service';

@Component({
  selector: 'hfc-help-popup',
  templateUrl: './help-popup.component.html',
  styleUrls: ['./help-popup.component.scss'],
  animations: [
    trigger("popup", [
      transition(":enter", [
        group([
          query("@overlay", animateChild()),
          style({ transform: "translateY(100%)" }),
          animate(200, style({ transform: "translateY(0)" }))
        ])
      ]),
      transition(":leave", [
        group([
          query("@overlay", animateChild()),
          animate(200, style({ transform: "translateY(100%)" }))
        ])
      ])
    ]),
    trigger("overlay", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        animate(200, style({ opacity: 0 })),
      ])
    ])
  ]
})
export class HelpPopupComponent {
  constructor(
    private advisorService: AdvisorService,
    public helpPopupService: HelpPopupService
  ) { }

  public get advisor() { return this.advisorService.advisor }

  public onCallClicked() {
    window.location = `tel:${this.advisor.phonePrefix}${this.advisor.phone}` as any;
  }

  public onEmailClicked() {
    window.location = `mailto:${this.advisor.email}` as any;
  }
}
