import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StepLayoutService } from '../step-layout/step-layout.service';
import { User } from '../../../root/services/user.service';
import { StepItem } from '../step-navigation/step-navigation.component';

@Component({
  selector: 'hfc-header-with-sidebar',
  templateUrl: './header-with-sidebar.component.html',
  styleUrls: ['./header-with-sidebar.component.scss'],
  animations: [
    trigger("menu", [
      transition(":enter", [
        group([
          query("@overlay", animateChild()),
          style({ transform: "translateX(-100%)" }),
          animate(200, style({ transform: "translateX(0)" }))
        ])
      ]),
      transition(":leave", [
        group([
          query("@overlay", animateChild()),
          animate(200, style({ transform: "translateX(-100%)" }))
        ])
      ])
    ]),
    trigger("overlay", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        animate(200, style({ opacity: 0 })),
      ])
    ])
  ]
})
export class HeaderWithSidebarComponent {
  public closeButton: boolean = false;

  @Input()
  public pageTitle: string;

  @Input()
  public percentageCompleted: number;

  @Input()
  public showStepNavigation: boolean = true;

  @Input()
  public user: User;

  @Input()
  public isApplicantSwitchVisible: boolean;

  @Input()
  public steps: StepItem[];

  @Input()
  public stepNameMap: Map<string, string>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public stepLayoutService: StepLayoutService
  ) { }

  public onCloseButtonClicked() {
    let last = this.route.firstChild;

    if (last.firstChild) {
      last = last.firstChild;
    }

    this.router.navigate([this.stepLayoutService.closeButtonPath || ".."], { relativeTo: last, fragment: this.stepLayoutService.closeButtonFragment });
  }
}
