import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueOrDash'
})
export class ValueOrDashPipe<T> implements PipeTransform {
  public transform(value: T): T | string {
    return value ? value : "-";
  }
}
