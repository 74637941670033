import { Component, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { countries } from './countries';
import { DropdownComponentFilterWithFn } from '../dropdown/dropdown.component';
import { isIOS } from '../../functions/is-ios';

@Component({
  selector: 'hfc-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.scss'],
})
export class CountryDropdownComponent implements ControlValueAccessor {
  public countries = isIOS ? ["", ...countries] : countries;
  public value: string;

  public get hfInvalid() { return this.ngControl?.invalid && this.ngControl?.dirty }

  public filterWithFn: DropdownComponentFilterWithFn = (item: string, term: string) => {
    return item && item.toLowerCase().includes(term.toLowerCase());
  }

  constructor(
    @Optional() private ngControl: NgControl
  ) {
    ngControl && (ngControl.valueAccessor = this);
  }

  public onValueChange() {
    this.onChangeFn && this.onChangeFn(this.value);
  }

  private onChangeFn: (value: string) => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
