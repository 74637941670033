import { Component, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploadService, UploadedFile } from '../../../root/services/file-upload.service';

@Component({
  selector: 'hfc-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: UploadComponent,
    multi: true
  }]
})
export class UploadComponent implements ControlValueAccessor {
  public loading: boolean = false;
  public dragover: boolean = false;
  public uploadedFile: UploadedFile;
  public errorMessage: string;

  private onChange: (value: UploadedFile) => {};

  constructor(private fileuploadService: FileUploadService) { }

  public onFileChange(event) {
    const files: FileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    this.upload(files[0]);
  }

  private async upload(file: File) {
    this.loading = true;

    try {
      this.errorMessage = null;
      const uploadedFile = await this.fileuploadService.upload(file);
      this.uploadedFile = uploadedFile;
      this.onChange && this.onChange(this.uploadedFile);
    } catch (e) {
      if (e.name === "FileValidationError") {
        this.errorMessage = e.message;
      }
    }
    this.loading = false;
  }

  public onRemove() {
    this.errorMessage = null;
    this.uploadedFile = undefined;
    this.onChange && this.onChange(this.uploadedFile);
  }

  public onDragEnter(event: Event) {
    if (event.target === event.srcElement) {
      this.dragover = true;

      event.stopPropagation();
      event.preventDefault();

      // console.log("onDragEnter", event);
    }
  }

  public onDragLeave(event) {
    if (event.target === event.srcElement) {
      this.dragover = false;

      event.stopPropagation();
      event.preventDefault();

      // console.log("onDragLeave", event);
    }
  }

  public onDrop(event) {
    this.dragover = false;

    this.onFileChange(event);

    // console.log("onDrop");
  }

  public writeValue(obj: any): void {
    this.uploadedFile = obj;
  }
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  public setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
