import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { environment } from '../../../environments/environment';

export interface PostcodeLookupResultAddress {
  building_name: string;
  building_number: string;
  thoroughfare: string;
  town_or_city: string;
  line_1: string;
  line_2: string;
  sub_building_name: string;
  sub_building_number: string;
  locality: string;
  country: string;
}

export interface PostcodeLookupResult {
  postcode: string;
  addresses: PostcodeLookupResultAddress[]
}

@Injectable({ providedIn: "root" })
export class PostcodeLookupService {
  constructor(private httpClient: HttpClient) { }

  public lookup(postcode: string): Observable<PostcodeLookupResult> {
    if (!postcode) {
      throw "Empty parameter `postcode`";
    }

    const url = `${environment.postCodeApi.url}/getAddressesOnPostcode/${postcode}`;
    const params = { key: environment.postCodeApi.key };

    return this.httpClient
      .get<PostcodeLookupResult>(url, { params });
  }
}

// https://sprint-tools.appspot.com/_ah/api/postcodeLookup/v1/getAddressesOnPostcode/N1AT4?key=AIzaSyC0cZkmELAMM5qTE-FoSl6pIfcCVWkQKeE
// https://sprint-tools.appspot.com/_ah/api/postcodeLookup/v1/getAddressOnPostcode/N1AT4?key=AIzaSyC0cZkmELAMM5qTE-FoSl6pIfcCVWkQKeE
