<textarea *ngIf="!editor"
  class="textarea"
  [attr.rows]="rows"
  [(ngModel)]="value"
  (ngModelChange)="onModelChange()"
  (focus)="focus = true"
  (focusout)="focus = false"
  (blur)="onBlur()" #textarea></textarea>

<div *ngIf="editor" class="editor">
  <div class="toolbox">
    <hf-named-icon icon="format_bold" (mousedown)="command('bold'); $event.preventDefault()"></hf-named-icon>
    <hf-named-icon icon="format_italic" (mousedown)="command('italic'); $event.preventDefault()"></hf-named-icon>
    <hf-named-icon icon="format_underline" (mousedown)="command('underline'); $event.preventDefault()"></hf-named-icon>
    <hf-named-icon icon="format_list_bulleted" (mousedown)="command('insertUnorderedList'); $event.preventDefault()"></hf-named-icon>
    <!-- <hf-named-icon icon="link" (mousedown)="command('createLink'); $event.preventDefault()"></hf-named-icon> -->
  </div>

  <div class="textarea editor-input" contenteditable="true" (input)="value = $event.target.innerHTML; onModelChange();"></div>
</div>

<span class="material-icons error-icon">error</span>
