import { Injectable } from '@angular/core';
import { USER_TYPE } from '../../../../../../src/app/website/utils/variables.data';
import { AdvisorDTO } from '../models/advisor.dto';
import { AdvisorService } from './advisor.service';

export interface User {
  id: string;
  username: string;
  email: string;
  photo?: any;

  createdDate: string;
  lastLogin: string;

  firstName: string;
  lastName: string;
  fullName: string;

  phone: string;
  phonePrefix: string;

  advisor: AdvisorDTO;

  userType: USER_TYPE;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  private _user: User;

  public get user() { return this._user }

  public setUser(user?: User) {
    this._user = user;

    if (user?.advisor) {
      this.advisorService.userAdvisor = user.advisor;
    }
  }

  constructor(private advisorService: AdvisorService,) { }
}
