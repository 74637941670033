import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicantUser } from '../../../fact-find/services/fact-find-api.service';
import { routeAnimation } from '../../../shared/animations/route.animation';
import { StepStatus } from '../completed-banner/completed-banner.component';
import { StepItem } from '../step-navigation/step-navigation.component';
import { StepLayoutService } from './step-layout.service';

@Component({
  selector: 'hfc-step-layout',
  templateUrl: './step-layout.component.html',
  styleUrls: ['./step-layout.component.scss'],
  animations: [routeAnimation]
})
export class StepLayoutComponent {
  @Input()
  public pageTitle: string;

  @Input()
  public applicantUser: ApplicantUser;

  @Input()
  public steps: StepItem[];

  @Input()
  public stepNameMap: Map<string, string>;

  @Input()
  public returnComment: string;

  @Input()
  public percentageCompleted: number;

  @Input()
  public readyToSign: boolean;

  @Input()
  public waitingForCompletion: string[];

  @Input()
  public isApplicantSwitchVisible: boolean;

  @Input()
  public stepStatus?: StepStatus;

  public url: string;

  constructor(
    private router: Router,
    private stepLayoutService: StepLayoutService // Keep instance here (to be instantied for mobile & desktop)
  ) {
    this.router.events.pipe().subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.url= event.url;
      }
    });
  }
}
