import { NgModule } from "@angular/core";
import { ButtonComponent } from "./components/button/button.component";
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { DialogComponent } from "./components/dialog/dialog.component";
import { DocumentThumbnailComponent } from "./components/document-thumbnail/document-thumbnail.component";
import { EvergladesSharedMorule } from "../../../../../src/app/website/shared/everglades-shared.module";
import { CheckboxListComponent } from "./components/checkbox-list/checkbox-list.component";
import { YesNoCheckboxComponent } from "./components/yes-no-checkbox/yes-no-checkbox.component";
import { FormsModule } from "@angular/forms";
import { DebugFormComponent } from "./components/debug-form/debug-form.component";
import { TextInputComponent } from "./components/text-input/text-input.component";
import { NgxMaskModule } from "ngx-mask";
import { ReviewSectionComponent } from "./components/review-section/review-section.component";
import { ReviewSectionItemComponent } from "./components/review-section-item/review-section-item.component";
import { InfoBoxComponent } from "./components/info-box/info-box.component";
import { InfoBoxContainerComponent } from "./components/info-box-container/info-box-container.component";
import { AddButtonComponent } from "./components/add-button/add-button.component";
import { ToastComponent } from "./components/toast/toast.component";
import { TextareaInputComponent } from "./components/textarea-input/textarea-input.component";
import { IfMediaDirective } from "./directives/if-media.directive";
import { MediaQueryService } from "./services/media-query.service";
import { DateOrdinalPipe } from "./pipes/date-ordinal.pipe";
import { ImageComponent } from "./components/image/image.component";
import { YesNoPipe } from './pipes/yes-no.pipe';
import { ValueOrDashPipe } from './pipes/value-or-dash.pipe';
import { PercentagePipe } from './pipes/percentage.pipe';
import { RemoveLeadingZeroDirective } from "./directives/remove-leading-zero.directive";

const exported = [
  ButtonComponent,
  TextInputComponent,
  TextareaInputComponent,
  SpinnerComponent,
  DialogComponent,
  DocumentThumbnailComponent,
  CheckboxListComponent,
  YesNoCheckboxComponent,
  ReviewSectionComponent,
  ReviewSectionItemComponent,
  InfoBoxComponent,
  InfoBoxContainerComponent,
  AddButtonComponent,
  ToastComponent,
  DateOrdinalPipe,
  ImageComponent,
  YesNoPipe,
  ValueOrDashPipe,
  PercentagePipe,

  RemoveLeadingZeroDirective,
  IfMediaDirective,
  DebugFormComponent,
]

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    EvergladesSharedMorule,

    NgxMaskModule.forRoot()
  ],
  declarations: exported,
  exports: exported,
  providers: [
    MediaQueryService,
  ]
})
export class ClientSharedModule { }
