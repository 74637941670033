<hfc-dropdown [items]="items" [filterWith]="undefined" icon="arrow_drop_down" [(ngModel)]="value" (ngModelChange)="onValueChange()" [class.hf-invalid]="hfInvalid">
  <ng-template #itemTemplate let-item>
    <div style="display: flex; flex: 1 1 auto; align-items: center;">
      {{REGION_TYPE_NAME.get(item)}}
    </div>
  </ng-template>
  <ng-template #valueTemplate let-item>
    {{REGION_TYPE_NAME.get(item)}}
  </ng-template>
</hfc-dropdown>
