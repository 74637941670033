<hfc-dropdown [items]="PHONE_PREFIXES"
              [filterWith]="filterWith"
              icon="chevron_right"
              [(ngModel)]="selected"
              (ngModelChange)="onPrefixChange()">
  <ng-template #itemTemplate let-item>
    <div style="display: flex; flex: 1 1 auto; align-items: center;">
      <div style="flex: 3 1 0;">{{item.country}}</div>
      <div style="flex: 1 1 0;"> {{item.prefix}}</div>
    </div>
  </ng-template>
  <ng-template #valueTemplate let-item>
    {{item?.prefix}}
  </ng-template>
</hfc-dropdown>
