import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'hfc-debug-form',
  templateUrl: './debug-form.component.html',
  styleUrls: ['./debug-form.component.scss']
})
export class DebugFormComponent {
  @Input()
  public form: FormGroup;
}
