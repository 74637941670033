import { ConnectedPosition, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ContentChild, ElementRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TextInputComponent } from '../text-input/text-input.component';

export type DropdownComponentFilterWithFn = (item: any, term: string) => boolean

@Component({
  selector: 'hfc-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DropdownComponent,
    multi: true
  }]
})
export class DropdownComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input()
  public items: any[];

  @Input()
  public filterWith: DropdownComponentFilterWithFn = (item, term) =>
    item.toString().toLowerCase().includes(term.toLowerCase());

  @Input()
  public icon = "";

  @ContentChild("itemTemplate")
  itemTemplate: TemplateRef<any>;

  @ContentChild("valueTemplate")
  valueTemplate: TemplateRef<any>;

  @ViewChild("desktopDrawer")
  desktopDrawer: TemplateRef<any>;

  @ViewChild("searchInput")
  searchInput: TextInputComponent;

  public selected: any;

  public isOpen: boolean = false;
  public searchTerm: string = "";
  public filteredItems: any[];

  private destroy$ = new Subject();

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) { }

  @HostListener("document:click", ["$event.target"])
  public onDocumentClicked(target) {
    if (this.isOpen && !this.el.nativeElement.contains(target)) {
      this.isOpen = false;
    }
  }

  public onDropdownClicked() {
    this.openDesktopDrawer();

    setTimeout(() => this.searchInput?.setFocus());

    if (this.searchTerm) {
      this.searchTerm = "";
      this.filterItems();
    }
  }

  public onItemClicked(item: any) {
    this.selected = item;

    this.isOpen = false;
    this.closeDesktopDrawer();
    this.onChangeFn && this.onChangeFn(this.selected);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ("items" in changes || "filterWith" in changes) {
      this.filterItems();
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
  }

  public onModelChange() {
    this.onChangeFn && this.onChangeFn(this.selected);
  }

  private openDesktopDrawer() {
    const t = new TemplatePortal(this.desktopDrawer, this.viewContainerRef);

    this.overlayRef.attach(t);
    this.isOpen = true;
  }

  private closeDesktopDrawer() {
    this.overlayRef.detach();
    this.isOpen = false;
  }

  private _overlayRef: OverlayRef;
  private get overlayRef() {
    if (this._overlayRef) {
      return this._overlayRef;
    }

    const positions: ConnectedPosition[] = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
        offsetY: 10
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom',
        offsetY: -10
      }
    ];

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(this.el)
      .withViewportMargin(20)
      .withPositions(positions);
    const scrollStrategy = this.overlay.scrollStrategies.reposition();
    const hasBackdrop = true;
    const backdropClass = 'cdk-overlay-transparent-backdrop';

    this._overlayRef = this.overlay.create({ positionStrategy, scrollStrategy, hasBackdrop, backdropClass });

    this._overlayRef.backdropClick().pipe(takeUntil(this.destroy$)).subscribe(() => this.closeDesktopDrawer());

    return this._overlayRef;
  }

  private filterItems() {
    if (this.items) {
      const filter = this.searchTerm;

      this.filteredItems = this.filterWith && filter
        ? this.items.filter(e => this.filterWith(e, filter))
        : this.items;
    } else {
      this.filteredItems = this.items;
    }
  }

  private onChangeFn: (item: any) => void;

  writeValue(obj: any): void {
    this.selected = obj;
  }
  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }
  registerOnTouched(fn: any): void {
    // throw new Error("Method not implemented.");
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error("Method not implemented.");
  }
}
