export interface Occupation {
  OrigoCode: string;
  OrigoOccupation: string;
}

export const occupations: Occupation[] =
  [
    {
      "OrigoCode": "AAB00001",
      "OrigoOccupation": "Abattoir Inspector"
    },
    {
      "OrigoCode": "AAC02588",
      "OrigoOccupation": "Abattoir Worker"
    },
    {
      "OrigoCode": "AAD03000",
      "OrigoOccupation": "Abrasive Wheels Worker"
    },
    {
      "OrigoCode": "AAB00010",
      "OrigoOccupation": "Account Executive"
    },
    {
      "OrigoCode": "AAB00011",
      "OrigoOccupation": "Accountant"
    },
    {
      "OrigoCode": "AAD03001",
      "OrigoOccupation": "Accounts Administrator/Assistant"
    },
    {
      "OrigoCode": "AAB00013",
      "OrigoOccupation": "Acidifier Operator"
    },
    {
      "OrigoCode": "AAD03002",
      "OrigoOccupation": "Acrobat"
    },
    {
      "OrigoCode": "AAB00020",
      "OrigoOccupation": "Actor/Actress (no stunt work)"
    },
    {
      "OrigoCode": "AAB00021",
      "OrigoOccupation": "Actuary"
    },
    {
      "OrigoCode": "AAB00022",
      "OrigoOccupation": "Acupuncturist"
    },
    {
      "OrigoCode": "AAC02590",
      "OrigoOccupation": "Administration Manager"
    },
    {
      "OrigoCode": "AAD03003",
      "OrigoOccupation": "Administrator - office"
    },
    {
      "OrigoCode": "AAB00023",
      "OrigoOccupation": "Advertising Manager"
    },
    {
      "OrigoCode": "AAC02591",
      "OrigoOccupation": "Advertising executive"
    },
    {
      "OrigoCode": "AAB00024",
      "OrigoOccupation": "Aerial Erector (40' up)"
    },
    {
      "OrigoCode": "AAB00025",
      "OrigoOccupation": "Aerial Erector (up to 40')"
    },
    {
      "OrigoCode": "AAB00026",
      "OrigoOccupation": "Aerial Photography"
    },
    {
      "OrigoCode": "AAC02592",
      "OrigoOccupation": "Aerobics Instructor"
    },
    {
      "OrigoCode": "AAB00027",
      "OrigoOccupation": "Agent"
    },
    {
      "OrigoCode": "AAB00028",
      "OrigoOccupation": "Agricultural Engineer"
    },
    {
      "OrigoCode": "AAB00030",
      "OrigoOccupation": "Agricultural Worker"
    },
    {
      "OrigoCode": "AAB00031",
      "OrigoOccupation": "Agronomist"
    },
    {
      "OrigoCode": "AAD03004",
      "OrigoOccupation": "Air Compressor Operator"
    },
    {
      "OrigoCode": "AAB00036",
      "OrigoOccupation": "Air Frame Service Fitter"
    },
    {
      "OrigoCode": "AAB00037",
      "OrigoOccupation": "Air Pump Attendant -Coastal etc"
    },
    {
      "OrigoCode": "AAB00038",
      "OrigoOccupation": "Air Pump Attendant -Deep Sea"
    },
    {
      "OrigoCode": "AAB00039",
      "OrigoOccupation": "Air Traffic Control Assistant"
    },
    {
      "OrigoCode": "AAB00041",
      "OrigoOccupation": "Air Traffic Controller"
    },
    {
      "OrigoCode": "AAC02593",
      "OrigoOccupation": "Air Traffic Planner"
    },
    {
      "OrigoCode": "AAB00042",
      "OrigoOccupation": "Aircraft Electronics Service Fitter"
    },
    {
      "OrigoCode": "AAB00043",
      "OrigoOccupation": "Aircraft Engine Service Fitter"
    },
    {
      "OrigoCode": "AAB00044",
      "OrigoOccupation": "Aircraft Finisher"
    },
    {
      "OrigoCode": "AAB00045",
      "OrigoOccupation": "Aircraft Inspector"
    },
    {
      "OrigoCode": "AAB00046",
      "OrigoOccupation": "Aircraft Instrument Mechanic"
    },
    {
      "OrigoCode": "AAB00047",
      "OrigoOccupation": "Aircraft Joiner"
    },
    {
      "OrigoCode": "AAB00048",
      "OrigoOccupation": "Aircraft Maintenance Technician"
    },
    {
      "OrigoCode": "AAB00049",
      "OrigoOccupation": "Aircraft Marshaller"
    },
    {
      "OrigoCode": "AAB00050",
      "OrigoOccupation": "Aircraft Refueller"
    },
    {
      "OrigoCode": "AAB00051",
      "OrigoOccupation": "Aircrew (including Flight Engineer)"
    },
    {
      "OrigoCode": "AAB00052",
      "OrigoOccupation": "Airline Cabin Staff"
    },
    {
      "OrigoCode": "AAB00053",
      "OrigoOccupation": "Airline Pilots"
    },
    {
      "OrigoCode": "AAB00054",
      "OrigoOccupation": "Airport Manager"
    },
    {
      "OrigoCode": "AAB00055",
      "OrigoOccupation": "Airport Superintendent"
    },
    {
      "OrigoCode": "AAD03392",
      "OrigoOccupation": "Alternative Therapist/Complimentary Therapist"
    },
    {
      "OrigoCode": "AAC02594",
      "OrigoOccupation": "Ambassador"
    },
    {
      "OrigoCode": "AAB00057",
      "OrigoOccupation": "Ambulance Driver"
    },
    {
      "OrigoCode": "AAB00058",
      "OrigoOccupation": "Ambulanceman (No driving)"
    },
    {
      "OrigoCode": "AAB00119",
      "OrigoOccupation": "Amusement Arcade Worker"
    },
    {
      "OrigoCode": "AAD03005",
      "OrigoOccupation": "Amusement Park Worker"
    },
    {
      "OrigoCode": "AAB00060",
      "OrigoOccupation": "Anaesthetist"
    },
    {
      "OrigoCode": "AAD03006",
      "OrigoOccupation": "Analyst - Business"
    },
    {
      "OrigoCode": "AAD03007",
      "OrigoOccupation": "Analyst - City"
    },
    {
      "OrigoCode": "AAD03008",
      "OrigoOccupation": "Analyst - Investment"
    },
    {
      "OrigoCode": "AAD03009",
      "OrigoOccupation": "Analyst - Systems"
    },
    {
      "OrigoCode": "AAD03010",
      "OrigoOccupation": "Analyst - Other"
    },
    {
      "OrigoCode": "AAC02596",
      "OrigoOccupation": "Analytical Chemist"
    },
    {
      "OrigoCode": "AAB00062",
      "OrigoOccupation": "Ancient Monuments Inspector"
    },
    {
      "OrigoCode": "AAB00063",
      "OrigoOccupation": "Animal Nursing Auxiliary"
    },
    {
      "OrigoCode": "AAB00064",
      "OrigoOccupation": "Animal Trainer/Keeper"
    },
    {
      "OrigoCode": "AAB00065",
      "OrigoOccupation": "Animator"
    },
    {
      "OrigoCode": "AAB00066",
      "OrigoOccupation": "Annealer"
    },
    {
      "OrigoCode": "AAB00067",
      "OrigoOccupation": "Announcer - Radio & TV - Entertainment"
    },
    {
      "OrigoCode": "AAB00068",
      "OrigoOccupation": "Announcer - Station Personnel - Railways"
    },
    {
      "OrigoCode": "AAB00069",
      "OrigoOccupation": "Anodiser"
    },
    {
      "OrigoCode": "AAB00070",
      "OrigoOccupation": "Antique Dealer"
    },
    {
      "OrigoCode": "AAB00071",
      "OrigoOccupation": "Antique Restorer"
    },
    {
      "OrigoCode": "AAB00073",
      "OrigoOccupation": "Arc Welder"
    },
    {
      "OrigoCode": "AAB00074",
      "OrigoOccupation": "Archaeologist"
    },
    {
      "OrigoCode": "AAC02597",
      "OrigoOccupation": "Archaeologist (other countries)"
    },
    {
      "OrigoCode": "AAB00075",
      "OrigoOccupation": "Architect"
    },
    {
      "OrigoCode": "AAC02598",
      "OrigoOccupation": "Architect (office)"
    },
    {
      "OrigoCode": "AAB00076",
      "OrigoOccupation": "Archivist"
    },
    {
      "OrigoCode": "AAD03014",
      "OrigoOccupation": "Armed Forces - Army - SAS"
    },
    {
      "OrigoCode": "AAD03011",
      "OrigoOccupation": "Armed Forces - Army - aircrew"
    },
    {
      "OrigoCode": "AAD03012",
      "OrigoOccupation": "Armed Forces - Army - bomb disposal"
    },
    {
      "OrigoCode": "AAD03013",
      "OrigoOccupation": "Armed Forces - Army - no bomb disposal"
    },
    {
      "OrigoCode": "AAD03015",
      "OrigoOccupation": "Armed Forces - Full time reservist - no special duties"
    },
    {
      "OrigoCode": "AAD03016",
      "OrigoOccupation": "Armed Forces - Full time reservist - special duties"
    },
    {
      "OrigoCode": "AAD03019",
      "OrigoOccupation": "Armed Forces - Navy - SBS"
    },
    {
      "OrigoCode": "AAD03017",
      "OrigoOccupation": "Armed Forces - Navy - aircrew"
    },
    {
      "OrigoCode": "AAD3390",
      "OrigoOccupation": "Armed Forces - Navy - diving"
    },
    {
      "OrigoCode": "AAD03018",
      "OrigoOccupation": "Armed Forces - Navy - no diving"
    },
    {
      "OrigoCode": "AAD03389",
      "OrigoOccupation": "Armed Forces - RAF - aircrew"
    },
    {
      "OrigoCode": "AAD03020",
      "OrigoOccupation": "Armed Forces - RAF - no flying"
    },
    {
      "OrigoCode": "AAB00079",
      "OrigoOccupation": "Aromatherapist"
    },
    {
      "OrigoCode": "AAB00080",
      "OrigoOccupation": "Arranger"
    },
    {
      "OrigoCode": "AAB00081",
      "OrigoOccupation": "Art Director"
    },
    {
      "OrigoCode": "AAB00082",
      "OrigoOccupation": "Art Gallery Attendant"
    },
    {
      "OrigoCode": "AAB00083",
      "OrigoOccupation": "Art Gallery Curator"
    },
    {
      "OrigoCode": "AAB00084",
      "OrigoOccupation": "Art Gallery Guide"
    },
    {
      "OrigoCode": "AAC02599",
      "OrigoOccupation": "Art Gallery Manager - Commercial"
    },
    {
      "OrigoCode": "AAB00085",
      "OrigoOccupation": "Artexer"
    },
    {
      "OrigoCode": "AAB00086",
      "OrigoOccupation": "Artist - Freelance Painter"
    },
    {
      "OrigoCode": "AAB00087",
      "OrigoOccupation": "Artist Commercial"
    },
    {
      "OrigoCode": "AAB00088",
      "OrigoOccupation": "Artist's Model"
    },
    {
      "OrigoCode": "AAD03021",
      "OrigoOccupation": "Asbestos Inspector"
    },
    {
      "OrigoCode": "AAB00089",
      "OrigoOccupation": "Asbestos Worker"
    },
    {
      "OrigoCode": "AAB00095",
      "OrigoOccupation": "Asphalter"
    },
    {
      "OrigoCode": "AAB00108",
      "OrigoOccupation": "Assembler - Woodworking Industry"
    },
    {
      "OrigoCode": "AAB00109",
      "OrigoOccupation": "Assembly Inspector"
    },
    {
      "OrigoCode": "AAC02600",
      "OrigoOccupation": "Assessor (claims/insurance)"
    },
    {
      "OrigoCode": "AAB00110",
      "OrigoOccupation": "Assistant Cameraman"
    },
    {
      "OrigoCode": "AAB00111",
      "OrigoOccupation": "Assistant Director"
    },
    {
      "OrigoCode": "AAB00112",
      "OrigoOccupation": "Assistant Editor"
    },
    {
      "OrigoCode": "AAB00113",
      "OrigoOccupation": "Assistant Superintendent"
    },
    {
      "OrigoCode": "AAB00114",
      "OrigoOccupation": "Assistant Tool Pusher"
    },
    {
      "OrigoCode": "AAB00115",
      "OrigoOccupation": "Associate Producer"
    },
    {
      "OrigoCode": "AAC02601",
      "OrigoOccupation": "Assumed Non-Hazardous (for quotation only)"
    },
    {
      "OrigoCode": "AAB00116",
      "OrigoOccupation": "Astrologer"
    },
    {
      "OrigoCode": "AAB00117",
      "OrigoOccupation": "Astronomer"
    },
    {
      "OrigoCode": "AAC02602",
      "OrigoOccupation": "Atomic Energy Worker"
    },
    {
      "OrigoCode": "AAB00120",
      "OrigoOccupation": "Attendant - Bingo - Entertainment"
    },
    {
      "OrigoCode": "AAB00121",
      "OrigoOccupation": "Attendant - Fairground etc - Entertainment"
    },
    {
      "OrigoCode": "AAD03022",
      "OrigoOccupation": "Au Pair"
    },
    {
      "OrigoCode": "AAB00122",
      "OrigoOccupation": "Auctioneer"
    },
    {
      "OrigoCode": "AAB00123",
      "OrigoOccupation": "Audiometrician"
    },
    {
      "OrigoCode": "AAB00124",
      "OrigoOccupation": "Auditor"
    },
    {
      "OrigoCode": "AAB00125",
      "OrigoOccupation": "Author"
    },
    {
      "OrigoCode": "AAB00126",
      "OrigoOccupation": "Autoclave Operator"
    },
    {
      "OrigoCode": "AAB00127",
      "OrigoOccupation": "Autolysis Man"
    },
    {
      "OrigoCode": "AAB00128",
      "OrigoOccupation": "Automatic Train Attendant"
    },
    {
      "OrigoCode": "AAD03023",
      "OrigoOccupation": "Auxiliary Nurse"
    },
    {
      "OrigoCode": "BAB00130",
      "OrigoOccupation": "Baggage Handler"
    },
    {
      "OrigoCode": "BAD03025",
      "OrigoOccupation": "Baggage Manager"
    },
    {
      "OrigoCode": "BAB00133",
      "OrigoOccupation": "Baggage Master"
    },
    {
      "OrigoCode": "BAB00134",
      "OrigoOccupation": "Baggage Porter"
    },
    {
      "OrigoCode": "BAD03026",
      "OrigoOccupation": "Bailiff"
    },
    {
      "OrigoCode": "BAC02606",
      "OrigoOccupation": "Baker"
    },
    {
      "OrigoCode": "BAB00135",
      "OrigoOccupation": "Bakery Equipment Operator"
    },
    {
      "OrigoCode": "BAC02607",
      "OrigoOccupation": "Bakery Shop Manager"
    },
    {
      "OrigoCode": "BAB00137",
      "OrigoOccupation": "Baler"
    },
    {
      "OrigoCode": "BAB00138",
      "OrigoOccupation": "Band Leader"
    },
    {
      "OrigoCode": "BAB00139",
      "OrigoOccupation": "Band Mill Sawyer"
    },
    {
      "OrigoCode": "BAB00141",
      "OrigoOccupation": "Bank Staff"
    },
    {
      "OrigoCode": "BAD03027",
      "OrigoOccupation": "Banksman"
    },
    {
      "OrigoCode": "BAB00152",
      "OrigoOccupation": "Banksman's Assistant"
    },
    {
      "OrigoCode": "BAC02609",
      "OrigoOccupation": "Bar Manager/Proprietor"
    },
    {
      "OrigoCode": "BAB00153",
      "OrigoOccupation": "Bar Steward"
    },
    {
      "OrigoCode": "BAC02610",
      "OrigoOccupation": "Barber"
    },
    {
      "OrigoCode": "BAC02611",
      "OrigoOccupation": "Barber - Shop Manager/Proprietor"
    },
    {
      "OrigoCode": "BAB00154",
      "OrigoOccupation": "Bargeman - Merchant Marine"
    },
    {
      "OrigoCode": "BAB00155",
      "OrigoOccupation": "Bargeman - Quarrying"
    },
    {
      "OrigoCode": "BAB00156",
      "OrigoOccupation": "Bargemaster"
    },
    {
      "OrigoCode": "BAB00157",
      "OrigoOccupation": "Barley Roaster"
    },
    {
      "OrigoCode": "BAB00158",
      "OrigoOccupation": "Barmaid"
    },
    {
      "OrigoCode": "BAB00159",
      "OrigoOccupation": "Barman"
    },
    {
      "OrigoCode": "BAB00160",
      "OrigoOccupation": "Barrelman"
    },
    {
      "OrigoCode": "BAC02612",
      "OrigoOccupation": "Barrister"
    },
    {
      "OrigoCode": "BAB00161",
      "OrigoOccupation": "Barrister, Advocate"
    },
    {
      "OrigoCode": "BAB00163",
      "OrigoOccupation": "Batman"
    },
    {
      "OrigoCode": "BAB00164",
      "OrigoOccupation": "Battery Assembler"
    },
    {
      "OrigoCode": "BAB00165",
      "OrigoOccupation": "Battery Repairer"
    },
    {
      "OrigoCode": "BAB00167",
      "OrigoOccupation": "Beautician"
    },
    {
      "OrigoCode": "BAC02613",
      "OrigoOccupation": "Beautician Shop Manager/Proprietor"
    },
    {
      "OrigoCode": "BAC02614",
      "OrigoOccupation": "Bed & Breakfast Proprietor"
    },
    {
      "OrigoCode": "BAB00168",
      "OrigoOccupation": "Beekeeper, Apiarist"
    },
    {
      "OrigoCode": "BAB00169",
      "OrigoOccupation": "Belt Maker"
    },
    {
      "OrigoCode": "BAB00170",
      "OrigoOccupation": "Belt Patrol Man"
    },
    {
      "OrigoCode": "BAB00171",
      "OrigoOccupation": "Bench Hand - Production Fitting - Metal Manufacture"
    },
    {
      "OrigoCode": "BAB00172",
      "OrigoOccupation": "Bench Hand - Rubber Industry - Natural"
    },
    {
      "OrigoCode": "BAB00173",
      "OrigoOccupation": "Berthing Superintendent"
    },
    {
      "OrigoCode": "BAC02615",
      "OrigoOccupation": "Betting Shop Manager (on course)"
    },
    {
      "OrigoCode": "BAC02616",
      "OrigoOccupation": "Betting Shop Manager (shop based)"
    },
    {
      "OrigoCode": "BAB00174",
      "OrigoOccupation": "Bill Poster/Sticker"
    },
    {
      "OrigoCode": "BAD03029",
      "OrigoOccupation": "Bin Man"
    },
    {
      "OrigoCode": "BAB00176",
      "OrigoOccupation": "Bindery Assistant"
    },
    {
      "OrigoCode": "BAB00177",
      "OrigoOccupation": "Binding Machine Attendant"
    },
    {
      "OrigoCode": "BAD03030",
      "OrigoOccupation": "Bingo Hall Manager"
    },
    {
      "OrigoCode": "BAC02618",
      "OrigoOccupation": "Biochemist"
    },
    {
      "OrigoCode": "BAC02619",
      "OrigoOccupation": "Biochemist - Lecturing and research"
    },
    {
      "OrigoCode": "BAC02620",
      "OrigoOccupation": "Biological scientist"
    },
    {
      "OrigoCode": "BAB00178",
      "OrigoOccupation": "Biologist (No travel/ underwater)"
    },
    {
      "OrigoCode": "BAB00179",
      "OrigoOccupation": "Biologist (Overseas travel)"
    },
    {
      "OrigoCode": "BAB00180",
      "OrigoOccupation": "Biologist (Underwater work)"
    },
    {
      "OrigoCode": "BAB00181",
      "OrigoOccupation": "Biscuit Baker"
    },
    {
      "OrigoCode": "BAB00182",
      "OrigoOccupation": "Blacksmith"
    },
    {
      "OrigoCode": "BAB00183",
      "OrigoOccupation": "Blancher"
    },
    {
      "OrigoCode": "BAD03031",
      "OrigoOccupation": "Blaster - quarry"
    },
    {
      "OrigoCode": "BAB00184",
      "OrigoOccupation": "Bleacher - Paper & Board Manufacture"
    },
    {
      "OrigoCode": "BAB00185",
      "OrigoOccupation": "Bleacher - Textile & Clothing Industry"
    },
    {
      "OrigoCode": "BAB00186",
      "OrigoOccupation": "Blender"
    },
    {
      "OrigoCode": "BAB00187",
      "OrigoOccupation": "Block Cutter"
    },
    {
      "OrigoCode": "BAB00188",
      "OrigoOccupation": "Boarding School Matron"
    },
    {
      "OrigoCode": "BAB00189",
      "OrigoOccupation": "Boat Builder"
    },
    {
      "OrigoCode": "BAB00190",
      "OrigoOccupation": "Boatswain - Fishing Industry"
    },
    {
      "OrigoCode": "BAB00191",
      "OrigoOccupation": "Boatswain - Merchant Marine"
    },
    {
      "OrigoCode": "BAB00192",
      "OrigoOccupation": "Boatswain's Mate"
    },
    {
      "OrigoCode": "BAB00193",
      "OrigoOccupation": "Bodyguard"
    },
    {
      "OrigoCode": "BAB00194",
      "OrigoOccupation": "Boiler - Confectionery etc - Food & Drink"
    },
    {
      "OrigoCode": "BAB00195",
      "OrigoOccupation": "Boiler - Fruit & Veg. - Food & Drink"
    },
    {
      "OrigoCode": "BAB00196",
      "OrigoOccupation": "Boiler - Meat, Fish etc - Food & Drink"
    },
    {
      "OrigoCode": "BAB00197",
      "OrigoOccupation": "Boiler Cleaner"
    },
    {
      "OrigoCode": "BAB00198",
      "OrigoOccupation": "Boiler Operator - Electrical Supply"
    },
    {
      "OrigoCode": "BAB00199",
      "OrigoOccupation": "Boiler Operator - Water Supply Industry"
    },
    {
      "OrigoCode": "BAB00200",
      "OrigoOccupation": "Boiler Operator/Fireman"
    },
    {
      "OrigoCode": "BAB00201",
      "OrigoOccupation": "Bomb Disposal - Elsewhere"
    },
    {
      "OrigoCode": "BAB00202",
      "OrigoOccupation": "Bomb Disposal - Mainland Britain"
    },
    {
      "OrigoCode": "BAB00203",
      "OrigoOccupation": "Book Illustrator"
    },
    {
      "OrigoCode": "BAD03032",
      "OrigoOccupation": "Book Seller"
    },
    {
      "OrigoCode": "BAB00204",
      "OrigoOccupation": "Book-Keeper"
    },
    {
      "OrigoCode": "BAB00205",
      "OrigoOccupation": "Bookbinder"
    },
    {
      "OrigoCode": "BAB00206",
      "OrigoOccupation": "Bookmaker - On course"
    },
    {
      "OrigoCode": "BAB00207",
      "OrigoOccupation": "Bookmaker - Shop Manager"
    },
    {
      "OrigoCode": "BAB00208",
      "OrigoOccupation": "Bookmaker - Shop Owner"
    },
    {
      "OrigoCode": "BAB00209",
      "OrigoOccupation": "Bookmaker - Shop Staff"
    },
    {
      "OrigoCode": "BAB00210",
      "OrigoOccupation": "Boom Operator"
    },
    {
      "OrigoCode": "BAB00212",
      "OrigoOccupation": "Borer - Mining"
    },
    {
      "OrigoCode": "BAB00211",
      "OrigoOccupation": "Borer - Tunnelling"
    },
    {
      "OrigoCode": "BAB00213",
      "OrigoOccupation": "Borstal Matron"
    },
    {
      "OrigoCode": "BAB00214",
      "OrigoOccupation": "Bosun (Third Hand)"
    },
    {
      "OrigoCode": "BAB00215",
      "OrigoOccupation": "Botanist (No overseas field work)"
    },
    {
      "OrigoCode": "BAB00216",
      "OrigoOccupation": "Bottle Washer (hand or machine)"
    },
    {
      "OrigoCode": "BAB00217",
      "OrigoOccupation": "Bottling Machine Attendant"
    },
    {
      "OrigoCode": "BAB00218",
      "OrigoOccupation": "Box Maker"
    },
    {
      "OrigoCode": "BAD03033",
      "OrigoOccupation": "Box Office Cashier"
    },
    {
      "OrigoCode": "BAD03034",
      "OrigoOccupation": "Box Office Clerk"
    },
    {
      "OrigoCode": "BAB00224",
      "OrigoOccupation": "Box Office Manager"
    },
    {
      "OrigoCode": "BAB00226",
      "OrigoOccupation": "Brakesman"
    },
    {
      "OrigoCode": "BAB00227",
      "OrigoOccupation": "Brazer"
    },
    {
      "OrigoCode": "BAB00228",
      "OrigoOccupation": "Bread Baker"
    },
    {
      "OrigoCode": "BAB00229",
      "OrigoOccupation": "Bread Roundsman"
    },
    {
      "OrigoCode": "BAD03035",
      "OrigoOccupation": "Breakdown Recovery Man"
    },
    {
      "OrigoCode": "BAB00230",
      "OrigoOccupation": "Brewer"
    },
    {
      "OrigoCode": "BAB00231",
      "OrigoOccupation": "Brewery Manager"
    },
    {
      "OrigoCode": "BAB00232",
      "OrigoOccupation": "Bricklayer"
    },
    {
      "OrigoCode": "BAB00233",
      "OrigoOccupation": "Bridge Man"
    },
    {
      "OrigoCode": "BAD03036",
      "OrigoOccupation": "Briner"
    },
    {
      "OrigoCode": "BAD03037",
      "OrigoOccupation": "Broker - Insurance IFA"
    },
    {
      "OrigoCode": "BAD03038",
      "OrigoOccupation": "Broker - Insurance Non IFA"
    },
    {
      "OrigoCode": "BAD03039",
      "OrigoOccupation": "Broker - Money/investments"
    },
    {
      "OrigoCode": "BAD03388",
      "OrigoOccupation": "Broker - Oil"
    },
    {
      "OrigoCode": "BAD03040",
      "OrigoOccupation": "Broker - Other"
    },
    {
      "OrigoCode": "BAB00236",
      "OrigoOccupation": "Bronzer"
    },
    {
      "OrigoCode": "BAB00237",
      "OrigoOccupation": "Broom/Brush Maker"
    },
    {
      "OrigoCode": "BAB00238",
      "OrigoOccupation": "Buffet Car Attendant"
    },
    {
      "OrigoCode": "BAC02621",
      "OrigoOccupation": "Builder"
    },
    {
      "OrigoCode": "BAB00239",
      "OrigoOccupation": "Building Inspector"
    },
    {
      "OrigoCode": "BAC02623",
      "OrigoOccupation": "Building Site Agent - Building and construction"
    },
    {
      "OrigoCode": "BAD03041",
      "OrigoOccupation": "Building Society worker"
    },
    {
      "OrigoCode": "BAB00241",
      "OrigoOccupation": "Building Surveyor"
    },
    {
      "OrigoCode": "BAB00243",
      "OrigoOccupation": "Bulldozer Driver"
    },
    {
      "OrigoCode": "BAD03042",
      "OrigoOccupation": "Bunker Control man"
    },
    {
      "OrigoCode": "BAC02625",
      "OrigoOccupation": "Burglar Alarm Fitter"
    },
    {
      "OrigoCode": "BAB00250",
      "OrigoOccupation": "Bus Conductor (No driving)"
    },
    {
      "OrigoCode": "BAB00251",
      "OrigoOccupation": "Bus Driver"
    },
    {
      "OrigoCode": "BAB00252",
      "OrigoOccupation": "Bus Inspector"
    },
    {
      "OrigoCode": "BAB00253",
      "OrigoOccupation": "Business Consultant"
    },
    {
      "OrigoCode": "BAB00255",
      "OrigoOccupation": "Butcher"
    },
    {
      "OrigoCode": "BAC02627",
      "OrigoOccupation": "Butcher Shop Proprietor"
    },
    {
      "OrigoCode": "BAB00256",
      "OrigoOccupation": "Butler"
    },
    {
      "OrigoCode": "BAB00257",
      "OrigoOccupation": "Butter Blender"
    },
    {
      "OrigoCode": "BAB00258",
      "OrigoOccupation": "Butter Maker"
    },
    {
      "OrigoCode": "BAC02628",
      "OrigoOccupation": "Buyer - retail"
    },
    {
      "OrigoCode": "BAD03044",
      "OrigoOccupation": "Buyer - stocks and shares"
    },
    {
      "OrigoCode": "CAD03055",
      "OrigoOccupation": "CC TV Installer/Maintenance - 40 ft and over"
    },
    {
      "OrigoCode": "CAD03056",
      "OrigoOccupation": "CC TV Installer/Maintenance - under 40 ft"
    },
    {
      "OrigoCode": "CAB00259",
      "OrigoOccupation": "Cabin Boy"
    },
    {
      "OrigoCode": "CAB00260",
      "OrigoOccupation": "Cabinet Maker"
    },
    {
      "OrigoCode": "CAB00261",
      "OrigoOccupation": "Cable Former"
    },
    {
      "OrigoCode": "CAB00262",
      "OrigoOccupation": "Cable Hand"
    },
    {
      "OrigoCode": "CAB00263",
      "OrigoOccupation": "Cable Jointer"
    },
    {
      "OrigoCode": "CAB00264",
      "OrigoOccupation": "Cable Laying Diver"
    },
    {
      "OrigoCode": "CAB00265",
      "OrigoOccupation": "Cable Tester"
    },
    {
      "OrigoCode": "CAB00266",
      "OrigoOccupation": "Cafe Cashier"
    },
    {
      "OrigoCode": "CAC02629",
      "OrigoOccupation": "Cafe Manager"
    },
    {
      "OrigoCode": "CAB00267",
      "OrigoOccupation": "Cafe Proprietor (Licensed)"
    },
    {
      "OrigoCode": "CAB00268",
      "OrigoOccupation": "Cafe Proprietor (Unlicensed)"
    },
    {
      "OrigoCode": "CAD03046",
      "OrigoOccupation": "Cafe Worker"
    },
    {
      "OrigoCode": "CAB00270",
      "OrigoOccupation": "Calibrator"
    },
    {
      "OrigoCode": "CAD03047",
      "OrigoOccupation": "Call Centre Manager"
    },
    {
      "OrigoCode": "CAD03048",
      "OrigoOccupation": "Call Centre Worker"
    },
    {
      "OrigoCode": "CAB00271",
      "OrigoOccupation": "Caller"
    },
    {
      "OrigoCode": "CAC02630",
      "OrigoOccupation": "Calligrapher"
    },
    {
      "OrigoCode": "CAC02631",
      "OrigoOccupation": "Camera Repair Technician"
    },
    {
      "OrigoCode": "CAD03049",
      "OrigoOccupation": "Cameraman Outside Work"
    },
    {
      "OrigoCode": "CAB00273",
      "OrigoOccupation": "Cameraman Studio"
    },
    {
      "OrigoCode": "CAD03050",
      "OrigoOccupation": "Cameraman War or Disaster reporting"
    },
    {
      "OrigoCode": "CAB00275",
      "OrigoOccupation": "Candle Maker"
    },
    {
      "OrigoCode": "CAB00276",
      "OrigoOccupation": "Canine Beautician"
    },
    {
      "OrigoCode": "CAC02632",
      "OrigoOccupation": "Canine Behaviourist"
    },
    {
      "OrigoCode": "CAB00277",
      "OrigoOccupation": "Canning Machine Attendant"
    },
    {
      "OrigoCode": "CAB00278",
      "OrigoOccupation": "Canteen Assistant"
    },
    {
      "OrigoCode": "CAB00279",
      "OrigoOccupation": "Canteen Manager"
    },
    {
      "OrigoCode": "CAB00280",
      "OrigoOccupation": "Canvasser"
    },
    {
      "OrigoCode": "CAB00282",
      "OrigoOccupation": "Captain - Merchant Marine"
    },
    {
      "OrigoCode": "CAB00281",
      "OrigoOccupation": "Captain - Oil & Natural Gas Industries"
    },
    {
      "OrigoCode": "CAB00284",
      "OrigoOccupation": "Car Delivery Driver"
    },
    {
      "OrigoCode": "CAC02633",
      "OrigoOccupation": "Car Hire Company Proprietor (admin. and driving)"
    },
    {
      "OrigoCode": "CAB00285",
      "OrigoOccupation": "Car Lasher"
    },
    {
      "OrigoCode": "CAB00286",
      "OrigoOccupation": "Car Park Attendant"
    },
    {
      "OrigoCode": "CAC02634",
      "OrigoOccupation": "Car Rental Company Manager"
    },
    {
      "OrigoCode": "CAD03051",
      "OrigoOccupation": "Car Rental Company Worker"
    },
    {
      "OrigoCode": "CAB00287",
      "OrigoOccupation": "Car Salesman (S/E or commission)"
    },
    {
      "OrigoCode": "CAB00288",
      "OrigoOccupation": "Car Salesman (Salaried)"
    },
    {
      "OrigoCode": "CAB00289",
      "OrigoOccupation": "Car Valeter"
    },
    {
      "OrigoCode": "CAB00306",
      "OrigoOccupation": "Car Wash Attendant"
    },
    {
      "OrigoCode": "CAD03052",
      "OrigoOccupation": "Caravan Site Manager"
    },
    {
      "OrigoCode": "CAD03053",
      "OrigoOccupation": "Caravan Site Staff"
    },
    {
      "OrigoCode": "CAB00290",
      "OrigoOccupation": "Carbon Printer"
    },
    {
      "OrigoCode": "CAB00291",
      "OrigoOccupation": "Carbonation Man"
    },
    {
      "OrigoCode": "CAB00292",
      "OrigoOccupation": "Carboniser"
    },
    {
      "OrigoCode": "CAB00293",
      "OrigoOccupation": "Care Assistant"
    },
    {
      "OrigoCode": "CAC02635",
      "OrigoOccupation": "Care Worker - Residential"
    },
    {
      "OrigoCode": "CAC02636",
      "OrigoOccupation": "Careers Advisor"
    },
    {
      "OrigoCode": "CAB00294",
      "OrigoOccupation": "Caretaker, Janitor"
    },
    {
      "OrigoCode": "CAB00295",
      "OrigoOccupation": "Cargo Clerk"
    },
    {
      "OrigoCode": "CAB00296",
      "OrigoOccupation": "Cargo Superintendent"
    },
    {
      "OrigoCode": "CAB00299",
      "OrigoOccupation": "Carpenter & Joiner"
    },
    {
      "OrigoCode": "CAB00297",
      "OrigoOccupation": "Carpenter - Construction Industry"
    },
    {
      "OrigoCode": "CAB00298",
      "OrigoOccupation": "Carpenter - Film Industry - Entertainment"
    },
    {
      "OrigoCode": "CAC02637",
      "OrigoOccupation": "Carpet Cleaner"
    },
    {
      "OrigoCode": "CAC02638",
      "OrigoOccupation": "Carpet Company director (office based admin. only)"
    },
    {
      "OrigoCode": "CAC02639",
      "OrigoOccupation": "Carpet Designer"
    },
    {
      "OrigoCode": "CAB00300",
      "OrigoOccupation": "Carpet Fitter"
    },
    {
      "OrigoCode": "CAC02640",
      "OrigoOccupation": "Carpet Salesman"
    },
    {
      "OrigoCode": "CAC02642",
      "OrigoOccupation": "Carpet Shop Assistant"
    },
    {
      "OrigoCode": "CAC02643",
      "OrigoOccupation": "Carpet Shop Manager (admin.)"
    },
    {
      "OrigoCode": "CAC02644",
      "OrigoOccupation": "Carpet Shop Owner (no manual duties)"
    },
    {
      "OrigoCode": "CAB00302",
      "OrigoOccupation": "Carriage Cleaner"
    },
    {
      "OrigoCode": "CAB00301",
      "OrigoOccupation": "Carriage Examiner"
    },
    {
      "OrigoCode": "CAB00303",
      "OrigoOccupation": "Cartographer"
    },
    {
      "OrigoCode": "CAB00304",
      "OrigoOccupation": "Cartoonist"
    },
    {
      "OrigoCode": "CAB00305",
      "OrigoOccupation": "Cartridge Filler"
    },
    {
      "OrigoCode": "CAC02645",
      "OrigoOccupation": "Cashier - Bank, Building Society"
    },
    {
      "OrigoCode": "CAC02646",
      "OrigoOccupation": "Cashier - Shop, cafe, supermarket, bingo"
    },
    {
      "OrigoCode": "CAB00309",
      "OrigoOccupation": "Casino Cashier"
    },
    {
      "OrigoCode": "CAB00310",
      "OrigoOccupation": "Caster"
    },
    {
      "OrigoCode": "CAB00311",
      "OrigoOccupation": "Casting Director"
    },
    {
      "OrigoCode": "CAB00312",
      "OrigoOccupation": "Casting Machine Operator"
    },
    {
      "OrigoCode": "CAC02647",
      "OrigoOccupation": "Caterer - offshore/at sea"
    },
    {
      "OrigoCode": "CAC02648",
      "OrigoOccupation": "Catering Assistant"
    },
    {
      "OrigoCode": "CAC02649",
      "OrigoOccupation": "Catering Manager"
    },
    {
      "OrigoCode": "CAB00317",
      "OrigoOccupation": "Cathead Man"
    },
    {
      "OrigoCode": "CAD03054",
      "OrigoOccupation": "Caulker"
    },
    {
      "OrigoCode": "CAB00323",
      "OrigoOccupation": "Ceiling Fixer"
    },
    {
      "OrigoCode": "CAB00324",
      "OrigoOccupation": "Cell Tester"
    },
    {
      "OrigoCode": "CAB00325",
      "OrigoOccupation": "Cementer"
    },
    {
      "OrigoCode": "CAD03057",
      "OrigoOccupation": "Cemetery Worker"
    },
    {
      "OrigoCode": "CAB00326",
      "OrigoOccupation": "Ceramicist"
    },
    {
      "OrigoCode": "CAB00328",
      "OrigoOccupation": "Chain Maker"
    },
    {
      "OrigoCode": "CAB00329",
      "OrigoOccupation": "Chair Maker"
    },
    {
      "OrigoCode": "CAC02653",
      "OrigoOccupation": "Chambermaid - Housekeeper"
    },
    {
      "OrigoCode": "CAB00330",
      "OrigoOccupation": "Charge Nurse"
    },
    {
      "OrigoCode": "CAD03058",
      "OrigoOccupation": "Charity Worker - Admin Only"
    },
    {
      "OrigoCode": "CAD03390",
      "OrigoOccupation": "Charity Worker - UK Work"
    },
    {
      "OrigoCode": "CAD03391",
      "OrigoOccupation": "Charity Worker - Overseas Work"
    },
    {
      "OrigoCode": "CAC02654",
      "OrigoOccupation": "Chartered Engineer (some site duties)"
    },
    {
      "OrigoCode": "CAC02655",
      "OrigoOccupation": "Chartered Engineered (admin. only)"
    },
    {
      "OrigoCode": "CAC02656",
      "OrigoOccupation": "Chartered Surveyor (admin only)"
    },
    {
      "OrigoCode": "CAC02657",
      "OrigoOccupation": "Chartered Surveyor (some site duties)"
    },
    {
      "OrigoCode": "CAB00332",
      "OrigoOccupation": "Chassis Builder"
    },
    {
      "OrigoCode": "CAB00333",
      "OrigoOccupation": "Chauffeur"
    },
    {
      "OrigoCode": "CAB00336",
      "OrigoOccupation": "Chef"
    },
    {
      "OrigoCode": "CAB00344",
      "OrigoOccupation": "Chemical Plumber"
    },
    {
      "OrigoCode": "CAB00345",
      "OrigoOccupation": "Chemical Plumber's Mate"
    },
    {
      "OrigoCode": "CAD03059",
      "OrigoOccupation": "Chemical engineer - UK"
    },
    {
      "OrigoCode": "CAB00340",
      "OrigoOccupation": "Chemical engineer - offshore"
    },
    {
      "OrigoCode": "CAD03060",
      "OrigoOccupation": "Chemist - industrial"
    },
    {
      "OrigoCode": "CAD03061",
      "OrigoOccupation": "Chemist - retail"
    },
    {
      "OrigoCode": "CAC02658",
      "OrigoOccupation": "Child Protection Co-ordinator"
    },
    {
      "OrigoCode": "CAD03062",
      "OrigoOccupation": "Child Support Agency (CSA) worker"
    },
    {
      "OrigoCode": "CAC02659",
      "OrigoOccupation": "Child Welfare Officer"
    },
    {
      "OrigoCode": "CAB00368",
      "OrigoOccupation": "Childminder"
    },
    {
      "OrigoCode": "CAB00370",
      "OrigoOccupation": "Children's Inspector"
    },
    {
      "OrigoCode": "CAB00371",
      "OrigoOccupation": "Children's Matron"
    },
    {
      "OrigoCode": "CAC02660",
      "OrigoOccupation": "Children's Nursery Proprietor"
    },
    {
      "OrigoCode": "CAC02661",
      "OrigoOccupation": "Children's Play-group Leader"
    },
    {
      "OrigoCode": "CAB00372",
      "OrigoOccupation": "Chimney Sweep"
    },
    {
      "OrigoCode": "CAC02662",
      "OrigoOccupation": "Chip Shop Owner"
    },
    {
      "OrigoCode": "CAD03063",
      "OrigoOccupation": "Chip Shop Worker"
    },
    {
      "OrigoCode": "CAB00373",
      "OrigoOccupation": "Chip/Money Changer"
    },
    {
      "OrigoCode": "CAB00374",
      "OrigoOccupation": "Chipper & Painter"
    },
    {
      "OrigoCode": "CAB00375",
      "OrigoOccupation": "Chipper (hand)"
    },
    {
      "OrigoCode": "CAB00376",
      "OrigoOccupation": "Chipping Driver"
    },
    {
      "OrigoCode": "CAB00377",
      "OrigoOccupation": "Chiropodist"
    },
    {
      "OrigoCode": "CAB00378",
      "OrigoOccupation": "Chiropracter"
    },
    {
      "OrigoCode": "CAC02664",
      "OrigoOccupation": "Church Organist"
    },
    {
      "OrigoCode": "CAD03064",
      "OrigoOccupation": "Cinema Projectionist"
    },
    {
      "OrigoCode": "CAB00383",
      "OrigoOccupation": "Circus Hand"
    },
    {
      "OrigoCode": "CAD03065",
      "OrigoOccupation": "Circus Manager"
    },
    {
      "OrigoCode": "CAD03066",
      "OrigoOccupation": "Civil Engineer"
    },
    {
      "OrigoCode": "CAD03067",
      "OrigoOccupation": "Civil Servant"
    },
    {
      "OrigoCode": "CAB00390",
      "OrigoOccupation": "Claims Adjuster"
    },
    {
      "OrigoCode": "CAB00391",
      "OrigoOccupation": "Claims Assessor"
    },
    {
      "OrigoCode": "CAD03068",
      "OrigoOccupation": "Cleaner - commercial premises"
    },
    {
      "OrigoCode": "CAC02666",
      "OrigoOccupation": "Cleaner - domestic premises"
    },
    {
      "OrigoCode": "CAD03069",
      "OrigoOccupation": "Cleaner - industrial"
    },
    {
      "OrigoCode": "CAB00405",
      "OrigoOccupation": "Clergy"
    },
    {
      "OrigoCode": "CAB00406",
      "OrigoOccupation": "Clerical Staff"
    },
    {
      "OrigoCode": "CAB00407",
      "OrigoOccupation": "Clerical Worker"
    },
    {
      "OrigoCode": "CAB00409",
      "OrigoOccupation": "Clerk of Works"
    },
    {
      "OrigoCode": "CAB00412",
      "OrigoOccupation": "Cloakroom Attendant - Club/Nightclub - Entertainment"
    },
    {
      "OrigoCode": "CAB00413",
      "OrigoOccupation": "Cloakroom Attendant - Theatre, Ballet etc - Entertainment"
    },
    {
      "OrigoCode": "CAB00414",
      "OrigoOccupation": "Clock & Watch Assembler"
    },
    {
      "OrigoCode": "CAB00415",
      "OrigoOccupation": "Clock/Watch Maker"
    },
    {
      "OrigoCode": "CAB00416",
      "OrigoOccupation": "Clock/Watch Repairer"
    },
    {
      "OrigoCode": "CAB00417",
      "OrigoOccupation": "Cloth Cutter"
    },
    {
      "OrigoCode": "CAC02671",
      "OrigoOccupation": "Clothing Designer"
    },
    {
      "OrigoCode": "CAB00418",
      "OrigoOccupation": "Clown"
    },
    {
      "OrigoCode": "CAB00419",
      "OrigoOccupation": "Club Manager"
    },
    {
      "OrigoCode": "CAB00420",
      "OrigoOccupation": "Club Proprietor"
    },
    {
      "OrigoCode": "CAB00421",
      "OrigoOccupation": "Club Steward"
    },
    {
      "OrigoCode": "CAD03070",
      "OrigoOccupation": "Coach - Sports"
    },
    {
      "OrigoCode": "CAB00427",
      "OrigoOccupation": "Coach Driver"
    },
    {
      "OrigoCode": "CAB00428",
      "OrigoOccupation": "Coach Painter"
    },
    {
      "OrigoCode": "CAB00431",
      "OrigoOccupation": "Coal Cutter Mover"
    },
    {
      "OrigoCode": "CAB00432",
      "OrigoOccupation": "Coal Cutterman"
    },
    {
      "OrigoCode": "CAB00433",
      "OrigoOccupation": "Coal Dry Cleaning Plant Operator"
    },
    {
      "OrigoCode": "CAB00434",
      "OrigoOccupation": "Coal Face Workers"
    },
    {
      "OrigoCode": "CAB00435",
      "OrigoOccupation": "Coal Melter"
    },
    {
      "OrigoCode": "CAB00436",
      "OrigoOccupation": "Coal Merchant - admin only"
    },
    {
      "OrigoCode": "CAB00437",
      "OrigoOccupation": "Coal Merchant - some delivery"
    },
    {
      "OrigoCode": "CAB00438",
      "OrigoOccupation": "Coal Trimmer"
    },
    {
      "OrigoCode": "CAB00439",
      "OrigoOccupation": "Coal Washery Operator"
    },
    {
      "OrigoCode": "CAB00440",
      "OrigoOccupation": "Coal Yard Foreman"
    },
    {
      "OrigoCode": "CAB00441",
      "OrigoOccupation": "Coal Yard Man"
    },
    {
      "OrigoCode": "CAB00442",
      "OrigoOccupation": "Coastguard (Office based)"
    },
    {
      "OrigoCode": "CAB00443",
      "OrigoOccupation": "Coastguard (Otherwise)"
    },
    {
      "OrigoCode": "CAB00444",
      "OrigoOccupation": "Coffin Maker"
    },
    {
      "OrigoCode": "CAB00445",
      "OrigoOccupation": "Coil Former"
    },
    {
      "OrigoCode": "CAB00446",
      "OrigoOccupation": "Coil Winder"
    },
    {
      "OrigoCode": "CAB00447",
      "OrigoOccupation": "College Lecturer"
    },
    {
      "OrigoCode": "CAB00449",
      "OrigoOccupation": "Colour Calculator"
    },
    {
      "OrigoCode": "CAB00450",
      "OrigoOccupation": "Colour Matcher"
    },
    {
      "OrigoCode": "CAB00451",
      "OrigoOccupation": "Colour Mixer"
    },
    {
      "OrigoCode": "CAB00452",
      "OrigoOccupation": "Columnist"
    },
    {
      "OrigoCode": "CAB00453",
      "OrigoOccupation": "Comedian"
    },
    {
      "OrigoCode": "CAB00454",
      "OrigoOccupation": "Commentator - no overseas travel etc"
    },
    {
      "OrigoCode": "CAB00455",
      "OrigoOccupation": "Commentator - otherwise"
    },
    {
      "OrigoCode": "CAB00456",
      "OrigoOccupation": "Commercial Diving"
    },
    {
      "OrigoCode": "CAC02674",
      "OrigoOccupation": "Commercial Manager (office sales)"
    },
    {
      "OrigoCode": "CAB00457",
      "OrigoOccupation": "Commercial Pilots"
    },
    {
      "OrigoCode": "CAC02675",
      "OrigoOccupation": "Commercial Traveller"
    },
    {
      "OrigoCode": "CAB00458",
      "OrigoOccupation": "Commissionaire"
    },
    {
      "OrigoCode": "CAD03071",
      "OrigoOccupation": "Community Development Worker"
    },
    {
      "OrigoCode": "CAB00460",
      "OrigoOccupation": "Community Nurse"
    },
    {
      "OrigoCode": "CAC02678",
      "OrigoOccupation": "Company Director (admin. duties only)"
    },
    {
      "OrigoCode": "CAC02679",
      "OrigoOccupation": "Company Secretary"
    },
    {
      "OrigoCode": "CAB00462",
      "OrigoOccupation": "Compass Adjuster"
    },
    {
      "OrigoCode": "CAC02680",
      "OrigoOccupation": "Compliance Manager"
    },
    {
      "OrigoCode": "CAB00463",
      "OrigoOccupation": "Composer"
    },
    {
      "OrigoCode": "CAB00464",
      "OrigoOccupation": "Compositor"
    },
    {
      "OrigoCode": "CAB00465",
      "OrigoOccupation": "Compounder"
    },
    {
      "OrigoCode": "CAC02681",
      "OrigoOccupation": "Computer Analyst"
    },
    {
      "OrigoCode": "CAC02682",
      "OrigoOccupation": "Computer Company Technical Support Manager"
    },
    {
      "OrigoCode": "CAB00466",
      "OrigoOccupation": "Computer Operator"
    },
    {
      "OrigoCode": "CAC02683",
      "OrigoOccupation": "Computer Programmer"
    },
    {
      "OrigoCode": "CAB00467",
      "OrigoOccupation": "Computer Programmer/Analyst"
    },
    {
      "OrigoCode": "CAC02684",
      "OrigoOccupation": "Computer Salesman (office based)"
    },
    {
      "OrigoCode": "CAC02685",
      "OrigoOccupation": "Computer Software Manager"
    },
    {
      "OrigoCode": "CAC02686",
      "OrigoOccupation": "Computer Software Salesman (includes travelling)"
    },
    {
      "OrigoCode": "CAC02687",
      "OrigoOccupation": "Computer Systems Installer"
    },
    {
      "OrigoCode": "CAC02688",
      "OrigoOccupation": "Computer Wirer"
    },
    {
      "OrigoCode": "CAC02689",
      "OrigoOccupation": "Computer Workshop Technical Engineer"
    },
    {
      "OrigoCode": "CAC02690",
      "OrigoOccupation": "Concert Promoter"
    },
    {
      "OrigoCode": "CAB00468",
      "OrigoOccupation": "Concrete Erector - 40' up"
    },
    {
      "OrigoCode": "CAB00469",
      "OrigoOccupation": "Concrete Erector - up to 40'"
    },
    {
      "OrigoCode": "CAB00470",
      "OrigoOccupation": "Concrete Finisher"
    },
    {
      "OrigoCode": "CAB00471",
      "OrigoOccupation": "Concrete Paving Driver"
    },
    {
      "OrigoCode": "CAB00472",
      "OrigoOccupation": "Concrete Shutterer"
    },
    {
      "OrigoCode": "CAB00473",
      "OrigoOccupation": "Concreter"
    },
    {
      "OrigoCode": "CAB00474",
      "OrigoOccupation": "Conductor - Music Industry - Entertainment"
    },
    {
      "OrigoCode": "CAB00475",
      "OrigoOccupation": "Conductor - Train Crew - Railways"
    },
    {
      "OrigoCode": "CAC02691",
      "OrigoOccupation": "Confectioner"
    },
    {
      "OrigoCode": "CAC02692",
      "OrigoOccupation": "Conference Organising Assistant"
    },
    {
      "OrigoCode": "CAC02693",
      "OrigoOccupation": "Conference Organising Manager"
    },
    {
      "OrigoCode": "CAB00476",
      "OrigoOccupation": "Conjurer"
    },
    {
      "OrigoCode": "CAB00478",
      "OrigoOccupation": "Construction Work"
    },
    {
      "OrigoCode": "CAB00480",
      "OrigoOccupation": "Contact Lens Technician"
    },
    {
      "OrigoCode": "CAB00481",
      "OrigoOccupation": "Continuity Clerk"
    },
    {
      "OrigoCode": "CAB00482",
      "OrigoOccupation": "Control Engineer"
    },
    {
      "OrigoCode": "CAD03072",
      "OrigoOccupation": "Control Room Operator"
    },
    {
      "OrigoCode": "CAD03073",
      "OrigoOccupation": "Conveyancer"
    },
    {
      "OrigoCode": "CAD03074",
      "OrigoOccupation": "Conveyer Operator"
    },
    {
      "OrigoCode": "CAB00508",
      "OrigoOccupation": "Cook"
    },
    {
      "OrigoCode": "CAB00514",
      "OrigoOccupation": "Cooper"
    },
    {
      "OrigoCode": "CAB00515",
      "OrigoOccupation": "Coppersmith"
    },
    {
      "OrigoCode": "CAB00516",
      "OrigoOccupation": "Copyholder"
    },
    {
      "OrigoCode": "CAB00517",
      "OrigoOccupation": "Copyholder (Newspapers etc)"
    },
    {
      "OrigoCode": "CAB00518",
      "OrigoOccupation": "Copytaster"
    },
    {
      "OrigoCode": "CAC02695",
      "OrigoOccupation": "Copywriter"
    },
    {
      "OrigoCode": "CAB00519",
      "OrigoOccupation": "Core Borer"
    },
    {
      "OrigoCode": "CAB00520",
      "OrigoOccupation": "Core Builder"
    },
    {
      "OrigoCode": "CAB00521",
      "OrigoOccupation": "Coremaker"
    },
    {
      "OrigoCode": "CAB00522",
      "OrigoOccupation": "Coroner"
    },
    {
      "OrigoCode": "CAB00524",
      "OrigoOccupation": "Correspondent - no overseas travel etc - Journalism"
    },
    {
      "OrigoCode": "CAB00523",
      "OrigoOccupation": "Correspondent - no overseas travel etc - Radio & TV - Entertainment"
    },
    {
      "OrigoCode": "CAB00526",
      "OrigoOccupation": "Correspondent - otherwise - Journalism"
    },
    {
      "OrigoCode": "CAB00525",
      "OrigoOccupation": "Correspondent - otherwise - Radio & TV - Entertainment"
    },
    {
      "OrigoCode": "CAB00527",
      "OrigoOccupation": "Costermonger"
    },
    {
      "OrigoCode": "CAB00528",
      "OrigoOccupation": "Costume Designer"
    },
    {
      "OrigoCode": "CAC02696",
      "OrigoOccupation": "Costumier"
    },
    {
      "OrigoCode": "CAB00529",
      "OrigoOccupation": "Counsellor"
    },
    {
      "OrigoCode": "CAB00530",
      "OrigoOccupation": "Counter Staff - Dry Cleaning"
    },
    {
      "OrigoCode": "CAB00531",
      "OrigoOccupation": "Counter Staff - Laundry"
    },
    {
      "OrigoCode": "CAB00532",
      "OrigoOccupation": "Counter Staff - Post Office/Telecommunications"
    },
    {
      "OrigoCode": "CAC02697",
      "OrigoOccupation": "Couriers"
    },
    {
      "OrigoCode": "CAB00533",
      "OrigoOccupation": "Court Bailiff"
    },
    {
      "OrigoCode": "CAB00534",
      "OrigoOccupation": "Court Usher"
    },
    {
      "OrigoCode": "CAB00535",
      "OrigoOccupation": "Crab Fisherman"
    },
    {
      "OrigoCode": "CAB00542",
      "OrigoOccupation": "Crane Erector"
    },
    {
      "OrigoCode": "CAD03075",
      "OrigoOccupation": "Crane Operator"
    },
    {
      "OrigoCode": "CAD03076",
      "OrigoOccupation": "Crane Slinger"
    },
    {
      "OrigoCode": "CAC02698",
      "OrigoOccupation": "Credit Agent"
    },
    {
      "OrigoCode": "CAC02699",
      "OrigoOccupation": "Credit Controller"
    },
    {
      "OrigoCode": "CAB00559",
      "OrigoOccupation": "Critic"
    },
    {
      "OrigoCode": "CAD03077",
      "OrigoOccupation": "Crop Sprayer - on ground"
    },
    {
      "OrigoCode": "CAD03078",
      "OrigoOccupation": "Crop Sprayer - pilot"
    },
    {
      "OrigoCode": "CAB00562",
      "OrigoOccupation": "Crossing Keeper"
    },
    {
      "OrigoCode": "CAB00563",
      "OrigoOccupation": "Croupier"
    },
    {
      "OrigoCode": "CAB00566",
      "OrigoOccupation": "Crushing Worker"
    },
    {
      "OrigoCode": "CAC02700",
      "OrigoOccupation": "Curator (museum)"
    },
    {
      "OrigoCode": "CAB00567",
      "OrigoOccupation": "Curator - Zoo"
    },
    {
      "OrigoCode": "CAB00568",
      "OrigoOccupation": "Curer"
    },
    {
      "OrigoCode": "CAC02701",
      "OrigoOccupation": "Customer Care Officer"
    },
    {
      "OrigoCode": "CAD03079",
      "OrigoOccupation": "Customer Service Staff"
    },
    {
      "OrigoCode": "CAD03080",
      "OrigoOccupation": "Customs and Excise"
    },
    {
      "OrigoCode": "CAD03081",
      "OrigoOccupation": "Cutter"
    },
    {
      "OrigoCode": "CAD03082",
      "OrigoOccupation": "Cutting Machine Operator"
    },
    {
      "OrigoCode": "DAC02702",
      "OrigoOccupation": "Dairyman"
    },
    {
      "OrigoCode": "DAB00582",
      "OrigoOccupation": "Dancer"
    },
    {
      "OrigoCode": "DAC02703",
      "OrigoOccupation": "Dancing Teacher"
    },
    {
      "OrigoCode": "DAD03084",
      "OrigoOccupation": "Data Controller"
    },
    {
      "OrigoCode": "DAD03085",
      "OrigoOccupation": "Data Inputter"
    },
    {
      "OrigoCode": "DAD03086",
      "OrigoOccupation": "Dealer - money/shares/investment"
    },
    {
      "OrigoCode": "DAB00585",
      "OrigoOccupation": "Debt Collector"
    },
    {
      "OrigoCode": "DAB00586",
      "OrigoOccupation": "Deck Chair Attendant"
    },
    {
      "OrigoCode": "DAB00587",
      "OrigoOccupation": "Deck Hand"
    },
    {
      "OrigoCode": "DAB00589",
      "OrigoOccupation": "Deck Officer"
    },
    {
      "OrigoCode": "DAC02706",
      "OrigoOccupation": "Decorator"
    },
    {
      "OrigoCode": "DAB00593",
      "OrigoOccupation": "Delivery Driver"
    },
    {
      "OrigoCode": "DAB00594",
      "OrigoOccupation": "Demolition Worker-no explosives"
    },
    {
      "OrigoCode": "DAB00595",
      "OrigoOccupation": "Demolition Worker-using explosives"
    },
    {
      "OrigoCode": "DAD03087",
      "OrigoOccupation": "Demonstrator"
    },
    {
      "OrigoCode": "DAB00598",
      "OrigoOccupation": "Dental Assistant"
    },
    {
      "OrigoCode": "DAC02707",
      "OrigoOccupation": "Dental Auxiliary"
    },
    {
      "OrigoCode": "DAB00599",
      "OrigoOccupation": "Dental Consultant"
    },
    {
      "OrigoCode": "DAB00600",
      "OrigoOccupation": "Dental Hygienist"
    },
    {
      "OrigoCode": "DAB00601",
      "OrigoOccupation": "Dental Nurse"
    },
    {
      "OrigoCode": "DAB00602",
      "OrigoOccupation": "Dental Practitioner"
    },
    {
      "OrigoCode": "DAD03088",
      "OrigoOccupation": "Dental Receptionist"
    },
    {
      "OrigoCode": "DAB00603",
      "OrigoOccupation": "Dental Technician"
    },
    {
      "OrigoCode": "DAB00604",
      "OrigoOccupation": "Dental Therapist"
    },
    {
      "OrigoCode": "DAB00605",
      "OrigoOccupation": "Dentist"
    },
    {
      "OrigoCode": "DAD03089",
      "OrigoOccupation": "Department Store Manager"
    },
    {
      "OrigoCode": "DAD03090",
      "OrigoOccupation": "Department Store worker"
    },
    {
      "OrigoCode": "DAC02708",
      "OrigoOccupation": "Dermatologist"
    },
    {
      "OrigoCode": "DAD03091",
      "OrigoOccupation": "Design cutter"
    },
    {
      "OrigoCode": "DAB00612",
      "OrigoOccupation": "Designer"
    },
    {
      "OrigoCode": "DAB00616",
      "OrigoOccupation": "Detention Centre Warden"
    },
    {
      "OrigoCode": "DAD03092",
      "OrigoOccupation": "Diamond workers"
    },
    {
      "OrigoCode": "DAB00626",
      "OrigoOccupation": "Die Cutter"
    },
    {
      "OrigoCode": "DAB00627",
      "OrigoOccupation": "Die Setter"
    },
    {
      "OrigoCode": "DAB00629",
      "OrigoOccupation": "Diesel Locomotive Fitter"
    },
    {
      "OrigoCode": "DAC02709",
      "OrigoOccupation": "Dietician"
    },
    {
      "OrigoCode": "DAB00631",
      "OrigoOccupation": "Dining Car Attendant"
    },
    {
      "OrigoCode": "DAD03093",
      "OrigoOccupation": "Dinner Lady"
    },
    {
      "OrigoCode": "DAD03375",
      "OrigoOccupation": "Director - Company - admin only"
    },
    {
      "OrigoCode": "DAD03376",
      "OrigoOccupation": "Director - Company - other"
    },
    {
      "OrigoCode": "DAD03377",
      "OrigoOccupation": "Director - Managing - admin only"
    },
    {
      "OrigoCode": "DAD03378",
      "OrigoOccupation": "Director - Managing - other"
    },
    {
      "OrigoCode": "DAD03379",
      "OrigoOccupation": "Director - Musical"
    },
    {
      "OrigoCode": "DAD03380",
      "OrigoOccupation": "Director - Other"
    },
    {
      "OrigoCode": "DAD03381",
      "OrigoOccupation": "Director - Sales - management only"
    },
    {
      "OrigoCode": "DAD03386",
      "OrigoOccupation": "Director - Sales - some selling"
    },
    {
      "OrigoCode": "FAC02771",
      "OrigoOccupation": "Director - TV and film"
    },
    {
      "OrigoCode": "DAC02710",
      "OrigoOccupation": "Director & medical Consultant"
    },
    {
      "OrigoCode": "DAB00637",
      "OrigoOccupation": "Disc Jockey"
    },
    {
      "OrigoCode": "DAB00638",
      "OrigoOccupation": "Disinfecting Officer"
    },
    {
      "OrigoCode": "DAB00639",
      "OrigoOccupation": "Disinfestor"
    },
    {
      "OrigoCode": "DAC02711",
      "OrigoOccupation": "Dispatch Rider"
    },
    {
      "OrigoCode": "DAB00640",
      "OrigoOccupation": "Dispatcher"
    },
    {
      "OrigoCode": "DAC02712",
      "OrigoOccupation": "Distiller"
    },
    {
      "OrigoCode": "DAB00641",
      "OrigoOccupation": "Distillery Manager"
    },
    {
      "OrigoCode": "DAB00642",
      "OrigoOccupation": "District Nurse"
    },
    {
      "OrigoCode": "DAC02713",
      "OrigoOccupation": "Diver"
    },
    {
      "OrigoCode": "DAC02714",
      "OrigoOccupation": "Diver (North Sea)"
    },
    {
      "OrigoCode": "DAB00643",
      "OrigoOccupation": "Diver's Linesman -Coastal etc"
    },
    {
      "OrigoCode": "DAB00644",
      "OrigoOccupation": "Diver's Linesman -Deep Sea"
    },
    {
      "OrigoCode": "DAB00647",
      "OrigoOccupation": "Dock Foreman"
    },
    {
      "OrigoCode": "DAB00648",
      "OrigoOccupation": "Dock Master"
    },
    {
      "OrigoCode": "DAB00649",
      "OrigoOccupation": "Dock Superintendent"
    },
    {
      "OrigoCode": "DAB00650",
      "OrigoOccupation": "Docker"
    },
    {
      "OrigoCode": "DAB00651",
      "OrigoOccupation": "Doctor - Health"
    },
    {
      "OrigoCode": "DAB00652",
      "OrigoOccupation": "Doctor - Merchant Marine"
    },
    {
      "OrigoCode": "DAC02715",
      "OrigoOccupation": "Dog Catcher"
    },
    {
      "OrigoCode": "DAB00655",
      "OrigoOccupation": "Dogger"
    },
    {
      "OrigoCode": "DAB00656",
      "OrigoOccupation": "Domestic Electrician"
    },
    {
      "OrigoCode": "DAB00657",
      "OrigoOccupation": "Domestic Premises Cleaner"
    },
    {
      "OrigoCode": "DAB00658",
      "OrigoOccupation": "Domestic Supervisor (Hospital)"
    },
    {
      "OrigoCode": "DAC02716",
      "OrigoOccupation": "Domestic Tiler"
    },
    {
      "OrigoCode": "DAB00659",
      "OrigoOccupation": "Donkeyman"
    },
    {
      "OrigoCode": "DAB00660",
      "OrigoOccupation": "Door to Door Salesman"
    },
    {
      "OrigoCode": "DAD03094",
      "OrigoOccupation": "Doorman"
    },
    {
      "OrigoCode": "DAC02717",
      "OrigoOccupation": "Double Glazing - Installer/fitter"
    },
    {
      "OrigoCode": "DAC02719",
      "OrigoOccupation": "Double Glazing Surveyor"
    },
    {
      "OrigoCode": "DAD03095",
      "OrigoOccupation": "Drainage Layer/Clearer"
    },
    {
      "OrigoCode": "DAC02720",
      "OrigoOccupation": "Draper"
    },
    {
      "OrigoCode": "DAB00665",
      "OrigoOccupation": "Draughtsman"
    },
    {
      "OrigoCode": "DAB00666",
      "OrigoOccupation": "Drawer (Bar, Plate, Rod, etc)"
    },
    {
      "OrigoCode": "DAB00667",
      "OrigoOccupation": "Drayman"
    },
    {
      "OrigoCode": "DAB00668",
      "OrigoOccupation": "Dredger Driver"
    },
    {
      "OrigoCode": "DAB00669",
      "OrigoOccupation": "Dredgerman"
    },
    {
      "OrigoCode": "DAB00672",
      "OrigoOccupation": "Dresser"
    },
    {
      "OrigoCode": "DAB00673",
      "OrigoOccupation": "Dressmaker"
    },
    {
      "OrigoCode": "DAD03096",
      "OrigoOccupation": "Drier"
    },
    {
      "OrigoCode": "DAD03097",
      "OrigoOccupation": "Driller - offshore"
    },
    {
      "OrigoCode": "DAD03098",
      "OrigoOccupation": "Driller - onshore"
    },
    {
      "OrigoCode": "DAD03101",
      "OrigoOccupation": "Driver - HGV"
    },
    {
      "OrigoCode": "DAD03103",
      "OrigoOccupation": "Driver - PSV"
    },
    {
      "OrigoCode": "DAD03099",
      "OrigoOccupation": "Driver - construction"
    },
    {
      "OrigoCode": "DAD03100",
      "OrigoOccupation": "Driver - delivery"
    },
    {
      "OrigoCode": "DAD03102",
      "OrigoOccupation": "Driver - industrial plant"
    },
    {
      "OrigoCode": "DAC02724",
      "OrigoOccupation": "Driver - refuse"
    },
    {
      "OrigoCode": "DAC02725",
      "OrigoOccupation": "Driver - tractor"
    },
    {
      "OrigoCode": "DAB00699",
      "OrigoOccupation": "Driving Examiner"
    },
    {
      "OrigoCode": "DAB00700",
      "OrigoOccupation": "Driving Instructor"
    },
    {
      "OrigoCode": "DAB00701",
      "OrigoOccupation": "Drop Ball Operator"
    },
    {
      "OrigoCode": "DAB00702",
      "OrigoOccupation": "Dry Cleaning Machine Operator"
    },
    {
      "OrigoCode": "DAB00703",
      "OrigoOccupation": "Dry Salter"
    },
    {
      "OrigoCode": "DAB00708",
      "OrigoOccupation": "Dustman/Refuse Collector"
    },
    {
      "OrigoCode": "DAB00710",
      "OrigoOccupation": "Dyer"
    },
    {
      "OrigoCode": "EAD03104",
      "OrigoOccupation": "Ecological Consultant Outside UK"
    },
    {
      "OrigoCode": "EAD03105",
      "OrigoOccupation": "Ecological Consultant UK"
    },
    {
      "OrigoCode": "EAB00712",
      "OrigoOccupation": "Economist"
    },
    {
      "OrigoCode": "EAD03106",
      "OrigoOccupation": "Editor"
    },
    {
      "OrigoCode": "EAD03107",
      "OrigoOccupation": "Editorial Assistant"
    },
    {
      "OrigoCode": "EAD03108",
      "OrigoOccupation": "Education Assistant"
    },
    {
      "OrigoCode": "EAC02729",
      "OrigoOccupation": "Educational Advisor"
    },
    {
      "OrigoCode": "EAB00715",
      "OrigoOccupation": "Effluent Inspector"
    },
    {
      "OrigoCode": "EAB00716",
      "OrigoOccupation": "Electric Logger"
    },
    {
      "OrigoCode": "EAB00717",
      "OrigoOccupation": "Electrical Contractor"
    },
    {
      "OrigoCode": "EAD03109",
      "OrigoOccupation": "Electrical Engineer"
    },
    {
      "OrigoCode": "EAB00722",
      "OrigoOccupation": "Electrical Fitter"
    },
    {
      "OrigoCode": "EAB00724",
      "OrigoOccupation": "Electrical Wireman"
    },
    {
      "OrigoCode": "EAD03110",
      "OrigoOccupation": "Electrician - offshore"
    },
    {
      "OrigoCode": "EAD03111",
      "OrigoOccupation": "Electrician UK based - domestic"
    },
    {
      "OrigoCode": "EAD03112",
      "OrigoOccupation": "Electrician UK based - industrial"
    },
    {
      "OrigoCode": "EAB00736",
      "OrigoOccupation": "Electronics Fitter"
    },
    {
      "OrigoCode": "EAB00737",
      "OrigoOccupation": "Electronics Installer"
    },
    {
      "OrigoCode": "EAB00738",
      "OrigoOccupation": "Electronics Mechanic"
    },
    {
      "OrigoCode": "EAB00739",
      "OrigoOccupation": "Electronics Repairer"
    },
    {
      "OrigoCode": "EAB00740",
      "OrigoOccupation": "Electronics Service Mechanic"
    },
    {
      "OrigoCode": "EAB00741",
      "OrigoOccupation": "Electronics Wireman"
    },
    {
      "OrigoCode": "EAB00742",
      "OrigoOccupation": "Electroplater"
    },
    {
      "OrigoCode": "EAB00743",
      "OrigoOccupation": "Electrotyper"
    },
    {
      "OrigoCode": "EAB00744",
      "OrigoOccupation": "Embalmer"
    },
    {
      "OrigoCode": "EAD03113",
      "OrigoOccupation": "Embassy Employee"
    },
    {
      "OrigoCode": "EAB00745",
      "OrigoOccupation": "Embroiderer"
    },
    {
      "OrigoCode": "EAC02740",
      "OrigoOccupation": "Employment Agency Owner (admin. only)"
    },
    {
      "OrigoCode": "EAD03114",
      "OrigoOccupation": "Employment Agency worker"
    },
    {
      "OrigoCode": "EAB00746",
      "OrigoOccupation": "Enameller"
    },
    {
      "OrigoCode": "EAB00747",
      "OrigoOccupation": "Engine Driver"
    },
    {
      "OrigoCode": "EAB00748",
      "OrigoOccupation": "Engine Tester"
    },
    {
      "OrigoCode": "EAD03115",
      "OrigoOccupation": "Engineer - admin and site visits only"
    },
    {
      "OrigoCode": "EAD03116",
      "OrigoOccupation": "Engineer - admin only"
    },
    {
      "OrigoCode": "EAD03117",
      "OrigoOccupation": "Engineer - heavy manual"
    },
    {
      "OrigoCode": "EAD03118",
      "OrigoOccupation": "Engineer - light manual"
    },
    {
      "OrigoCode": "EAC02742",
      "OrigoOccupation": "Engineer - offshore"
    },
    {
      "OrigoCode": "EAD03120",
      "OrigoOccupation": "Engineer - sales"
    },
    {
      "OrigoCode": "EAD03121",
      "OrigoOccupation": "Engineer - works at heights over 40 ft"
    },
    {
      "OrigoCode": "EAB00762",
      "OrigoOccupation": "Engineering Fitter"
    },
    {
      "OrigoCode": "EAB00764",
      "OrigoOccupation": "Engineering Technician"
    },
    {
      "OrigoCode": "EAD03122",
      "OrigoOccupation": "Engraver"
    },
    {
      "OrigoCode": "EAB00770",
      "OrigoOccupation": "Enrolled Nurse"
    },
    {
      "OrigoCode": "EAC02746",
      "OrigoOccupation": "Entertainer - Entertainment industry"
    },
    {
      "OrigoCode": "EAC02747",
      "OrigoOccupation": "Entertainment Agent - Entertainment industry"
    },
    {
      "OrigoCode": "EAC02748",
      "OrigoOccupation": "Entertainment Manager - Entertainment industry"
    },
    {
      "OrigoCode": "EAB00771",
      "OrigoOccupation": "Entertainments Officer"
    },
    {
      "OrigoCode": "EAC02749",
      "OrigoOccupation": "Environmental Health Officer"
    },
    {
      "OrigoCode": "EAB00772",
      "OrigoOccupation": "Equestrian Artiste"
    },
    {
      "OrigoCode": "EAB00773",
      "OrigoOccupation": "Equestrianism - Riding Instructor"
    },
    {
      "OrigoCode": "EAB00774",
      "OrigoOccupation": "Equestrianism - Show Jumping"
    },
    {
      "OrigoCode": "EAB00775",
      "OrigoOccupation": "Equipment Cleaner"
    },
    {
      "OrigoCode": "EAB00776",
      "OrigoOccupation": "Erector - Aircraft/Aerospace"
    },
    {
      "OrigoCode": "EAB00777",
      "OrigoOccupation": "Erector - Production Fitting - Metal Manufacture"
    },
    {
      "OrigoCode": "EAB00778",
      "OrigoOccupation": "Escapologist"
    },
    {
      "OrigoCode": "EAB00779",
      "OrigoOccupation": "Estate Agent"
    },
    {
      "OrigoCode": "EAC02750",
      "OrigoOccupation": "Estate Manager - all aspects (no manual work)"
    },
    {
      "OrigoCode": "EAB00780",
      "OrigoOccupation": "Estate Ranger"
    },
    {
      "OrigoCode": "EAB00781",
      "OrigoOccupation": "Estimator"
    },
    {
      "OrigoCode": "EAC02751",
      "OrigoOccupation": "Estimator (mainly office duties)"
    },
    {
      "OrigoCode": "EAB00785",
      "OrigoOccupation": "Etcher (creative)"
    },
    {
      "OrigoCode": "EAB00783",
      "OrigoOccupation": "Etcher - Pottery Industry"
    },
    {
      "OrigoCode": "EAB00782",
      "OrigoOccupation": "Etcher - Precious Metals, Engraving etc - Metal Manufacture"
    },
    {
      "OrigoCode": "EAB00784",
      "OrigoOccupation": "Etcher - Printing Industry"
    },
    {
      "OrigoCode": "EAD03124",
      "OrigoOccupation": "Examiner - process"
    },
    {
      "OrigoCode": "EAB00795",
      "OrigoOccupation": "Excavator Driver"
    },
    {
      "OrigoCode": "EAD03125",
      "OrigoOccupation": "Exhaust Fitter"
    },
    {
      "OrigoCode": "EAB00796",
      "OrigoOccupation": "Exhausterman"
    },
    {
      "OrigoCode": "EAC02752",
      "OrigoOccupation": "Exhibition Foreman"
    },
    {
      "OrigoCode": "EAC02753",
      "OrigoOccupation": "Exhibition Space Sales Manager"
    },
    {
      "OrigoCode": "EAB00797",
      "OrigoOccupation": "Exhibition Stand Fitter"
    },
    {
      "OrigoCode": "EAB00798",
      "OrigoOccupation": "Explosives Inspector"
    },
    {
      "OrigoCode": "EAC02754",
      "OrigoOccupation": "Export Agent"
    },
    {
      "OrigoCode": "EAB00800",
      "OrigoOccupation": "Extruder"
    },
    {
      "OrigoCode": "FAD03126",
      "OrigoOccupation": "Fabric Designer"
    },
    {
      "OrigoCode": "FAD03127",
      "OrigoOccupation": "Fabricator - welder/fitter"
    },
    {
      "OrigoCode": "FAD03128",
      "OrigoOccupation": "Facilities Assistant"
    },
    {
      "OrigoCode": "FAB00803",
      "OrigoOccupation": "Facilities Procurement Officer"
    },
    {
      "OrigoCode": "FAB00806",
      "OrigoOccupation": "Factory Inspector"
    },
    {
      "OrigoCode": "FAC02755",
      "OrigoOccupation": "Factory (worker)"
    },
    {
      "OrigoCode": "FAC02756",
      "OrigoOccupation": "Factory Manager (mainly admin.)"
    },
    {
      "OrigoCode": "FAC02757",
      "OrigoOccupation": "Fairground Worker"
    },
    {
      "OrigoCode": "FAC02760",
      "OrigoOccupation": "Farm Manager (manual duties)"
    },
    {
      "OrigoCode": "FAC02761",
      "OrigoOccupation": "Farm Manager (no manual duties)"
    },
    {
      "OrigoCode": "FAC02762",
      "OrigoOccupation": "Farm Owner (manual duties)"
    },
    {
      "OrigoCode": "FAC02763",
      "OrigoOccupation": "Farm Owner (no manual duties)"
    },
    {
      "OrigoCode": "FAC02764",
      "OrigoOccupation": "Farm Worker/Labourer"
    },
    {
      "OrigoCode": "FAB00811",
      "OrigoOccupation": "Farrier"
    },
    {
      "OrigoCode": "FAB00812",
      "OrigoOccupation": "Fashion Model"
    },
    {
      "OrigoCode": "FAB00813",
      "OrigoOccupation": "Fashion Photographer"
    },
    {
      "OrigoCode": "FAD03129",
      "OrigoOccupation": "Fashion Stylist"
    },
    {
      "OrigoCode": "FAD03130",
      "OrigoOccupation": "Fast Food Restaurant Assistant"
    },
    {
      "OrigoCode": "FAC02767",
      "OrigoOccupation": "Fast Food Restaurant Manager (admin. only)"
    },
    {
      "OrigoCode": "FAB00814",
      "OrigoOccupation": "Fat Extractor Man"
    },
    {
      "OrigoCode": "FAC02768",
      "OrigoOccupation": "Fencing Contractor"
    },
    {
      "OrigoCode": "FAB00816",
      "OrigoOccupation": "Ferryman"
    },
    {
      "OrigoCode": "FAB00821",
      "OrigoOccupation": "Fight Arranger"
    },
    {
      "OrigoCode": "FAB00823",
      "OrigoOccupation": "Film Developer"
    },
    {
      "OrigoCode": "FAB00824",
      "OrigoOccupation": "Film Joiner"
    },
    {
      "OrigoCode": "FAB00825",
      "OrigoOccupation": "Film Processor"
    },
    {
      "OrigoCode": "FAB00827",
      "OrigoOccupation": "Filmsetting Machine Operator"
    },
    {
      "OrigoCode": "FAC02774",
      "OrigoOccupation": "Financial Adviser"
    },
    {
      "OrigoCode": "FAD03131",
      "OrigoOccupation": "Financial Planner/Paraplanner"
    },
    {
      "OrigoCode": "FAD03132",
      "OrigoOccupation": "Finisher - toys and textiles"
    },
    {
      "OrigoCode": "FAB00832",
      "OrigoOccupation": "Fire Eater"
    },
    {
      "OrigoCode": "FAB00833",
      "OrigoOccupation": "Fire Prevention Officer"
    },
    {
      "OrigoCode": "FAB00835",
      "OrigoOccupation": "Firefighter - Fire Service"
    },
    {
      "OrigoCode": "FAB00843",
      "OrigoOccupation": "Fish Farmer"
    },
    {
      "OrigoCode": "FAB00848",
      "OrigoOccupation": "Fish Preparer"
    },
    {
      "OrigoCode": "FAD03133",
      "OrigoOccupation": "Fish and chip shop owner"
    },
    {
      "OrigoCode": "FAD03134",
      "OrigoOccupation": "Fish and chip shop worker"
    },
    {
      "OrigoCode": "FAB00849",
      "OrigoOccupation": "Fisherman"
    },
    {
      "OrigoCode": "FAB00850",
      "OrigoOccupation": "Fishery Officer/Warden"
    },
    {
      "OrigoCode": "FAC02779",
      "OrigoOccupation": "Fishmonger"
    },
    {
      "OrigoCode": "FAC02782",
      "OrigoOccupation": "Fitness instructor"
    },
    {
      "OrigoCode": "FAB00852",
      "OrigoOccupation": "Fitter - Motor Vehicle & Cycle Industry"
    },
    {
      "OrigoCode": "FAB00855",
      "OrigoOccupation": "Fitter-Assembler"
    },
    {
      "OrigoCode": "FAB00858",
      "OrigoOccupation": "Fixer Mason"
    },
    {
      "OrigoCode": "FAB00864",
      "OrigoOccupation": "Flame cutter - 40 ft +"
    },
    {
      "OrigoCode": "FAB00865",
      "OrigoOccupation": "Flame cutter - under 40 ft"
    },
    {
      "OrigoCode": "FAD03135",
      "OrigoOccupation": "Flight Attendant"
    },
    {
      "OrigoCode": "FAB00867",
      "OrigoOccupation": "Flight Dispatcher"
    },
    {
      "OrigoCode": "FAD03138",
      "OrigoOccupation": "Flight Operations Manager"
    },
    {
      "OrigoCode": "FAB00869",
      "OrigoOccupation": "Flight Planner"
    },
    {
      "OrigoCode": "FAB00870",
      "OrigoOccupation": "Floor Layer"
    },
    {
      "OrigoCode": "FAB00871",
      "OrigoOccupation": "Floor Manager"
    },
    {
      "OrigoCode": "FAB00872",
      "OrigoOccupation": "Floor Tiler"
    },
    {
      "OrigoCode": "FAB00873",
      "OrigoOccupation": "Floorman"
    },
    {
      "OrigoCode": "FAC02785",
      "OrigoOccupation": "Floorman - Oil Rig Industry"
    },
    {
      "OrigoCode": "FAC02786",
      "OrigoOccupation": "Florist"
    },
    {
      "OrigoCode": "FAB00874",
      "OrigoOccupation": "Flour Confectioner"
    },
    {
      "OrigoCode": "FAB00876",
      "OrigoOccupation": "Food Technologist"
    },
    {
      "OrigoCode": "FAC02787",
      "OrigoOccupation": "Football Manager - Professional players"
    },
    {
      "OrigoCode": "FAC02788",
      "OrigoOccupation": "Forecourt Attendant"
    },
    {
      "OrigoCode": "FAD03139",
      "OrigoOccupation": "Foreman - heavy manual"
    },
    {
      "OrigoCode": "FAD03140",
      "OrigoOccupation": "Foreman - light manual"
    },
    {
      "OrigoCode": "FAD03141",
      "OrigoOccupation": "Foreman - no manual"
    },
    {
      "OrigoCode": "FAD03142",
      "OrigoOccupation": "Foreman - offshore"
    },
    {
      "OrigoCode": "FAD03143",
      "OrigoOccupation": "Foreman - other"
    },
    {
      "OrigoCode": "FAB00937",
      "OrigoOccupation": "Forest Ranger"
    },
    {
      "OrigoCode": "FAB00938",
      "OrigoOccupation": "Forest Worker"
    },
    {
      "OrigoCode": "FAB00940",
      "OrigoOccupation": "Forestry Officer"
    },
    {
      "OrigoCode": "FAB00941",
      "OrigoOccupation": "Forge Hammerman"
    },
    {
      "OrigoCode": "FAB00942",
      "OrigoOccupation": "Forge Pressman"
    },
    {
      "OrigoCode": "FAB00943",
      "OrigoOccupation": "Forger"
    },
    {
      "OrigoCode": "FAB00944",
      "OrigoOccupation": "Fork Lift Truck Driver"
    },
    {
      "OrigoCode": "FAB00945",
      "OrigoOccupation": "Fortune Teller"
    },
    {
      "OrigoCode": "FAD03136",
      "OrigoOccupation": "Foster Parent"
    },
    {
      "OrigoCode": "FAB00946",
      "OrigoOccupation": "Frame Finisher"
    },
    {
      "OrigoCode": "FAB00947",
      "OrigoOccupation": "Freezer Operator"
    },
    {
      "OrigoCode": "FAB00948",
      "OrigoOccupation": "Freight Clerk"
    },
    {
      "OrigoCode": "FAB00949",
      "OrigoOccupation": "Freight Manager - Airport"
    },
    {
      "OrigoCode": "FAB00950",
      "OrigoOccupation": "Freight Manager - Docks"
    },
    {
      "OrigoCode": "FAB00951",
      "OrigoOccupation": "French Polisher"
    },
    {
      "OrigoCode": "FAC02793",
      "OrigoOccupation": "Fruitier"
    },
    {
      "OrigoCode": "FAB00952",
      "OrigoOccupation": "Fuel Technologist"
    },
    {
      "OrigoCode": "FAB00953",
      "OrigoOccupation": "Funeral Director"
    },
    {
      "OrigoCode": "FAB00954",
      "OrigoOccupation": "Funeral Director's Assistant"
    },
    {
      "OrigoCode": "FAB00955",
      "OrigoOccupation": "Furnace Control Room Operator"
    },
    {
      "OrigoCode": "FAB00956",
      "OrigoOccupation": "Furnace Operator - Cemetery, Crematorium"
    },
    {
      "OrigoCode": "FAD03137",
      "OrigoOccupation": "Furnace Operator - Other"
    },
    {
      "OrigoCode": "FAB00961",
      "OrigoOccupation": "Furniture Designer"
    },
    {
      "OrigoCode": "FAC02794",
      "OrigoOccupation": "Furniture Maker"
    },
    {
      "OrigoCode": "FAB00962",
      "OrigoOccupation": "Furniture Remover"
    },
    {
      "OrigoCode": "FAC02795",
      "OrigoOccupation": "Furniture Restorer"
    },
    {
      "OrigoCode": "FAC02796",
      "OrigoOccupation": "Furniture Retailer"
    },
    {
      "OrigoCode": "GAD03146",
      "OrigoOccupation": "GP - general practitioner - Doctor"
    },
    {
      "OrigoCode": "GAD03150",
      "OrigoOccupation": "Gallery Assistant"
    },
    {
      "OrigoCode": "GAC02797",
      "OrigoOccupation": "Gallery Owner (admin. only)"
    },
    {
      "OrigoCode": "GAC02798",
      "OrigoOccupation": "Gallery Owner (including manual work)"
    },
    {
      "OrigoCode": "GAD03155",
      "OrigoOccupation": "Galley Hand"
    },
    {
      "OrigoCode": "GAB00965",
      "OrigoOccupation": "Galvaniser"
    },
    {
      "OrigoCode": "GAB00966",
      "OrigoOccupation": "Gamekeeper"
    },
    {
      "OrigoCode": "GAB00968",
      "OrigoOccupation": "Ganger"
    },
    {
      "OrigoCode": "GAD03156",
      "OrigoOccupation": "Gantry Crane Driver"
    },
    {
      "OrigoCode": "GAC02800",
      "OrigoOccupation": "Garage - Mechanic"
    },
    {
      "OrigoCode": "GAC02801",
      "OrigoOccupation": "Garage - Petrol Pump Attendant"
    },
    {
      "OrigoCode": "GAC02802",
      "OrigoOccupation": "Garage Proprietor - admin only"
    },
    {
      "OrigoCode": "GAC02803",
      "OrigoOccupation": "Garage Repair Shop Supervisor (including manual duties)"
    },
    {
      "OrigoCode": "GAC02804",
      "OrigoOccupation": "Gardener"
    },
    {
      "OrigoCode": "GAB00971",
      "OrigoOccupation": "Gas Appliance Mechanic"
    },
    {
      "OrigoCode": "GAB00972",
      "OrigoOccupation": "Gas Compressor Operator"
    },
    {
      "OrigoCode": "GAB00976",
      "OrigoOccupation": "Gas Fitter"
    },
    {
      "OrigoCode": "GAB00979",
      "OrigoOccupation": "Gas Meter Tester"
    },
    {
      "OrigoCode": "GAB00984",
      "OrigoOccupation": "Gem Cutter"
    },
    {
      "OrigoCode": "GAB00985",
      "OrigoOccupation": "Gem Polisher"
    },
    {
      "OrigoCode": "GAB00986",
      "OrigoOccupation": "Gem Setter"
    },
    {
      "OrigoCode": "GAB00987",
      "OrigoOccupation": "Geologist - Mining"
    },
    {
      "OrigoCode": "GAB00988",
      "OrigoOccupation": "Geologist - Oil & Natural Gas Industries"
    },
    {
      "OrigoCode": "GAB00989",
      "OrigoOccupation": "Geologist - no aerial/offshore etc"
    },
    {
      "OrigoCode": "GAB00990",
      "OrigoOccupation": "Geophysicist - Mining"
    },
    {
      "OrigoCode": "GAB00991",
      "OrigoOccupation": "Geophysicist - Oil & Natural Gas Industries"
    },
    {
      "OrigoCode": "GAD03145",
      "OrigoOccupation": "Glamour Model"
    },
    {
      "OrigoCode": "GAC02806",
      "OrigoOccupation": "Glass Blower"
    },
    {
      "OrigoCode": "GAD03151",
      "OrigoOccupation": "Glass Worker"
    },
    {
      "OrigoCode": "GAB00996",
      "OrigoOccupation": "Glazer"
    },
    {
      "OrigoCode": "GAD03152",
      "OrigoOccupation": "Glazier"
    },
    {
      "OrigoCode": "GAB01000",
      "OrigoOccupation": "Gold Beater"
    },
    {
      "OrigoCode": "GAC02807",
      "OrigoOccupation": "Goldsmith"
    },
    {
      "OrigoCode": "GAB01001",
      "OrigoOccupation": "Golf - Caddie"
    },
    {
      "OrigoCode": "GAB01003",
      "OrigoOccupation": "Governor"
    },
    {
      "OrigoCode": "GAB01004",
      "OrigoOccupation": "Grader"
    },
    {
      "OrigoCode": "GAC02808",
      "OrigoOccupation": "Grain Merchant (office based)"
    },
    {
      "OrigoCode": "GAC02809",
      "OrigoOccupation": "Graphic Designer"
    },
    {
      "OrigoCode": "GAB01007",
      "OrigoOccupation": "Grave Digger"
    },
    {
      "OrigoCode": "GAD03154",
      "OrigoOccupation": "Greaser"
    },
    {
      "OrigoCode": "GAB01010",
      "OrigoOccupation": "Greenkeeper"
    },
    {
      "OrigoCode": "GAD03144",
      "OrigoOccupation": "Grinder"
    },
    {
      "OrigoCode": "GAC02813",
      "OrigoOccupation": "Grocer"
    },
    {
      "OrigoCode": "GAB01019",
      "OrigoOccupation": "Groom"
    },
    {
      "OrigoCode": "GAB01020",
      "OrigoOccupation": "Ground Equipment Service Mechanic"
    },
    {
      "OrigoCode": "GAB01021",
      "OrigoOccupation": "Ground Hostess/Steward"
    },
    {
      "OrigoCode": "GAB01022",
      "OrigoOccupation": "Ground Movement Controller"
    },
    {
      "OrigoCode": "GAB01023",
      "OrigoOccupation": "Groundsman"
    },
    {
      "OrigoCode": "GAD03147",
      "OrigoOccupation": "Groundworker"
    },
    {
      "OrigoCode": "GAD03148",
      "OrigoOccupation": "Guard - railway"
    },
    {
      "OrigoCode": "GAD03149",
      "OrigoOccupation": "Guard - security"
    },
    {
      "OrigoCode": "GAC02815",
      "OrigoOccupation": "Guest House Proprietor"
    },
    {
      "OrigoCode": "GAC02816",
      "OrigoOccupation": "Guest House Proprietor (admin. only)"
    },
    {
      "OrigoCode": "GAB01025",
      "OrigoOccupation": "Guillotine Operator"
    },
    {
      "OrigoCode": "GAB01026",
      "OrigoOccupation": "Gummer"
    },
    {
      "OrigoCode": "GAD03153",
      "OrigoOccupation": "Gunsmith"
    },
    {
      "OrigoCode": "HAC02817",
      "OrigoOccupation": "Haberdasher"
    },
    {
      "OrigoCode": "HAB01028",
      "OrigoOccupation": "Hairdresser - Mobile"
    },
    {
      "OrigoCode": "HAB01029",
      "OrigoOccupation": "Hairdresser - Salon"
    },
    {
      "OrigoCode": "HAC02818",
      "OrigoOccupation": "Hairdresser Shop Manager - admin only"
    },
    {
      "OrigoCode": "HAC02819",
      "OrigoOccupation": "Hairdresser Shop Proprietor"
    },
    {
      "OrigoCode": "HAD03164",
      "OrigoOccupation": "Hammerman"
    },
    {
      "OrigoCode": "HAB01037",
      "OrigoOccupation": "Handyman"
    },
    {
      "OrigoCode": "HAB01038",
      "OrigoOccupation": "Harbour Master"
    },
    {
      "OrigoCode": "HAC02820",
      "OrigoOccupation": "Harbour Pilot"
    },
    {
      "OrigoCode": "HAD03157",
      "OrigoOccupation": "Hardware Shop Retailer"
    },
    {
      "OrigoCode": "HAB01041",
      "OrigoOccupation": "Harness Maker"
    },
    {
      "OrigoCode": "HAB01043",
      "OrigoOccupation": "Hat Maker"
    },
    {
      "OrigoCode": "HAB01044",
      "OrigoOccupation": "Hatchery Worker"
    },
    {
      "OrigoCode": "HAC02822",
      "OrigoOccupation": "Haulage Contractor"
    },
    {
      "OrigoCode": "HAB01046",
      "OrigoOccupation": "Haulier (no driving)"
    },
    {
      "OrigoCode": "HAB01047",
      "OrigoOccupation": "Head Gardener"
    },
    {
      "OrigoCode": "HAB01048",
      "OrigoOccupation": "Head Groundsman"
    },
    {
      "OrigoCode": "HAB01049",
      "OrigoOccupation": "Head Keeper - Zoo"
    },
    {
      "OrigoCode": "HAB01050",
      "OrigoOccupation": "Head Roustabout"
    },
    {
      "OrigoCode": "HAC02823",
      "OrigoOccupation": "Headteacher"
    },
    {
      "OrigoCode": "HAC02824",
      "OrigoOccupation": "Health & Safety Officer"
    },
    {
      "OrigoCode": "HAC02827",
      "OrigoOccupation": "Health Counsellor"
    },
    {
      "OrigoCode": "HAB01053",
      "OrigoOccupation": "Health Radiation Monitor"
    },
    {
      "OrigoCode": "HAC02828",
      "OrigoOccupation": "Health Visitor"
    },
    {
      "OrigoCode": "HAC02825",
      "OrigoOccupation": "Health and Fitness Club Manager (admin. only)"
    },
    {
      "OrigoCode": "HAC02826",
      "OrigoOccupation": "Health and Fitness Club Trainer"
    },
    {
      "OrigoCode": "HAC02829",
      "OrigoOccupation": "Heating and Ventilating Fitter"
    },
    {
      "OrigoCode": "HAC02830",
      "OrigoOccupation": "Heavy Goods Driver (no loading) UK only"
    },
    {
      "OrigoCode": "HAB01064",
      "OrigoOccupation": "Heavy Goods Vehicle Driver"
    },
    {
      "OrigoCode": "HAC02831",
      "OrigoOccupation": "Helicopter Engineer"
    },
    {
      "OrigoCode": "HAC02832",
      "OrigoOccupation": "Helicopter Pilot - Oil Rig Industry"
    },
    {
      "OrigoCode": "HAC02833",
      "OrigoOccupation": "Helicopter Pilot - Onshore"
    },
    {
      "OrigoCode": "HAB01066",
      "OrigoOccupation": "Historic Building Guide"
    },
    {
      "OrigoCode": "HAD03162",
      "OrigoOccupation": "Hod Carrier - construction"
    },
    {
      "OrigoCode": "HAB01067",
      "OrigoOccupation": "Hoist Driver"
    },
    {
      "OrigoCode": "HAD03163",
      "OrigoOccupation": "Hoist Operator"
    },
    {
      "OrigoCode": "HAD03165",
      "OrigoOccupation": "Holiday Representative"
    },
    {
      "OrigoCode": "HAC02834",
      "OrigoOccupation": "Home Help"
    },
    {
      "OrigoCode": "HAC02835",
      "OrigoOccupation": "Homeless Centre Manager (admin. only)"
    },
    {
      "OrigoCode": "HAC02836",
      "OrigoOccupation": "Homeopath"
    },
    {
      "OrigoCode": "HAB01076",
      "OrigoOccupation": "Horse Breeder"
    },
    {
      "OrigoCode": "HAB01077",
      "OrigoOccupation": "Horse Racing - Flat Jockey"
    },
    {
      "OrigoCode": "HAB01078",
      "OrigoOccupation": "Horse Racing - National Hunt"
    },
    {
      "OrigoCode": "HAC02837",
      "OrigoOccupation": "Horticulturist"
    },
    {
      "OrigoCode": "HAB01082",
      "OrigoOccupation": "Hospital Matron"
    },
    {
      "OrigoCode": "HAB01084",
      "OrigoOccupation": "Hospital Porter - Health"
    },
    {
      "OrigoCode": "HAB01085",
      "OrigoOccupation": "Hospital Storeman"
    },
    {
      "OrigoCode": "HAB01086",
      "OrigoOccupation": "Hospital Ward Orderly"
    },
    {
      "OrigoCode": "HAB01087",
      "OrigoOccupation": "Hostel Matron"
    },
    {
      "OrigoCode": "HAB01088",
      "OrigoOccupation": "Hostel Warden"
    },
    {
      "OrigoCode": "HAB01089",
      "OrigoOccupation": "Hostess"
    },
    {
      "OrigoCode": "HAD03166",
      "OrigoOccupation": "Hotel Concierge"
    },
    {
      "OrigoCode": "HAB01090",
      "OrigoOccupation": "Hotel Detective"
    },
    {
      "OrigoCode": "HAB01091",
      "OrigoOccupation": "Hotel Doorman"
    },
    {
      "OrigoCode": "HAB01092",
      "OrigoOccupation": "Hotel Maid"
    },
    {
      "OrigoCode": "HAC02838",
      "OrigoOccupation": "Hotel Manager (office based)"
    },
    {
      "OrigoCode": "HAB01094",
      "OrigoOccupation": "Hotel Porter"
    },
    {
      "OrigoCode": "HAB01095",
      "OrigoOccupation": "Hotel Proprietor"
    },
    {
      "OrigoCode": "HAB01096",
      "OrigoOccupation": "Hotel Receptionist"
    },
    {
      "OrigoCode": "HAB01097",
      "OrigoOccupation": "House Maid"
    },
    {
      "OrigoCode": "HAB01098",
      "OrigoOccupation": "Housekeeper"
    },
    {
      "OrigoCode": "HAB01099",
      "OrigoOccupation": "Housewife/House-Husband"
    },
    {
      "OrigoCode": "HAC02839",
      "OrigoOccupation": "Housing Association Development Manager (inc. site visits)"
    },
    {
      "OrigoCode": "HAB01100",
      "OrigoOccupation": "Housing Inspector"
    },
    {
      "OrigoCode": "HAC02840",
      "OrigoOccupation": "Housing Manager"
    },
    {
      "OrigoCode": "HAD03158",
      "OrigoOccupation": "Human Resources Advisor"
    },
    {
      "OrigoCode": "HAD03159",
      "OrigoOccupation": "Human Resources Analyst"
    },
    {
      "OrigoCode": "HAD03161",
      "OrigoOccupation": "Human Resources Assistant"
    },
    {
      "OrigoCode": "HAD03386",
      "OrigoOccupation": "Human Resources Consultant"
    },
    {
      "OrigoCode": "HAD03387",
      "OrigoOccupation": "Human Resources Officer"
    },
    {
      "OrigoCode": "HAD03160",
      "OrigoOccupation": "Hydro-Extractor Operator"
    },
    {
      "OrigoCode": "HAC02841",
      "OrigoOccupation": "Hydrographic Engineer/Surveyor"
    },
    {
      "OrigoCode": "HAC02842",
      "OrigoOccupation": "Hygienist"
    },
    {
      "OrigoCode": "HAB01104",
      "OrigoOccupation": "Hypnotherapist"
    },
    {
      "OrigoCode": "HAB01105",
      "OrigoOccupation": "Hypnotist"
    },
    {
      "OrigoCode": "IAD03170",
      "OrigoOccupation": "IT Analyst"
    },
    {
      "OrigoCode": "IAD03172",
      "OrigoOccupation": "IT Manager - admin only"
    },
    {
      "OrigoCode": "IAD03174",
      "OrigoOccupation": "IT Programmer"
    },
    {
      "OrigoCode": "IAD03176",
      "OrigoOccupation": "IT Technician"
    },
    {
      "OrigoCode": "IAB01106",
      "OrigoOccupation": "Ice Cream Van Driver"
    },
    {
      "OrigoCode": "IAB01110",
      "OrigoOccupation": "Illusionist"
    },
    {
      "OrigoCode": "IAC02846",
      "OrigoOccupation": "Illustrator"
    },
    {
      "OrigoCode": "IAD03175",
      "OrigoOccupation": "Immigration Officer - admin only"
    },
    {
      "OrigoCode": "IAD03177",
      "OrigoOccupation": "Immigration Officer - otherwise"
    },
    {
      "OrigoCode": "IAB01111",
      "OrigoOccupation": "Impersonator"
    },
    {
      "OrigoCode": "IAB01112",
      "OrigoOccupation": "Importer"
    },
    {
      "OrigoCode": "IAB01114",
      "OrigoOccupation": "Incinerator Operator"
    },
    {
      "OrigoCode": "IAD03171",
      "OrigoOccupation": "Independent Financial Adviser - IFA"
    },
    {
      "OrigoCode": "IAD03173",
      "OrigoOccupation": "Industrial Chemist"
    },
    {
      "OrigoCode": "IAC02847",
      "OrigoOccupation": "Industrial Designer"
    },
    {
      "OrigoCode": "IAC02848",
      "OrigoOccupation": "Industrial Relations Officer"
    },
    {
      "OrigoCode": "IAC02849",
      "OrigoOccupation": "Industrial Trainer"
    },
    {
      "OrigoCode": "IAB01118",
      "OrigoOccupation": "Inseminator"
    },
    {
      "OrigoCode": "IAD03178",
      "OrigoOccupation": "Inspector (not police)"
    },
    {
      "OrigoCode": "IAD03179",
      "OrigoOccupation": "Instructor - aviation, diving, etc"
    },
    {
      "OrigoCode": "IAD03180",
      "OrigoOccupation": "Instructor - no special risks"
    },
    {
      "OrigoCode": "IAC02851",
      "OrigoOccupation": "Instrument Maker"
    },
    {
      "OrigoCode": "IAC02852",
      "OrigoOccupation": "Instrument Repairer"
    },
    {
      "OrigoCode": "IAD03167",
      "OrigoOccupation": "Insulator - asbestos work inc"
    },
    {
      "OrigoCode": "IAD03168",
      "OrigoOccupation": "Insulator - no asbestos work"
    },
    {
      "OrigoCode": "IAB01154",
      "OrigoOccupation": "Insurance Agent"
    },
    {
      "OrigoCode": "IAB01155",
      "OrigoOccupation": "Insurance Assessor"
    },
    {
      "OrigoCode": "IAB01156",
      "OrigoOccupation": "Insurance Broker"
    },
    {
      "OrigoCode": "IAB01157",
      "OrigoOccupation": "Insurance Inspector"
    },
    {
      "OrigoCode": "IAC02853",
      "OrigoOccupation": "Interior Designer"
    },
    {
      "OrigoCode": "IAD03169",
      "OrigoOccupation": "Internal Auditor"
    },
    {
      "OrigoCode": "IAB01158",
      "OrigoOccupation": "Interpreter"
    },
    {
      "OrigoCode": "IAC02854",
      "OrigoOccupation": "Investment Analyst"
    },
    {
      "OrigoCode": "IAB01163",
      "OrigoOccupation": "Ironer"
    },
    {
      "OrigoCode": "JAC02855",
      "OrigoOccupation": "Janitor"
    },
    {
      "OrigoCode": "JAB01167",
      "OrigoOccupation": "Jetty Hand"
    },
    {
      "OrigoCode": "JAC02856",
      "OrigoOccupation": "Jeweller"
    },
    {
      "OrigoCode": "JAB01168",
      "OrigoOccupation": "Jewellery Enameller"
    },
    {
      "OrigoCode": "JAB01169",
      "OrigoOccupation": "Jewellery Making & Repair"
    },
    {
      "OrigoCode": "JAB01170",
      "OrigoOccupation": "Jewellery Mounter"
    },
    {
      "OrigoCode": "JAB01173",
      "OrigoOccupation": "Joiner - Construction Industry"
    },
    {
      "OrigoCode": "JAB01174",
      "OrigoOccupation": "Joiner - Ship Building, Ship Repair & Marine Engineering"
    },
    {
      "OrigoCode": "JAB01175",
      "OrigoOccupation": "Jointer"
    },
    {
      "OrigoCode": "JAB01176",
      "OrigoOccupation": "Journalist - no overseas travel etc"
    },
    {
      "OrigoCode": "JAB01177",
      "OrigoOccupation": "Journalist - otherwise"
    },
    {
      "OrigoCode": "JAB01178",
      "OrigoOccupation": "Judge"
    },
    {
      "OrigoCode": "JAB01179",
      "OrigoOccupation": "Judge's Clerk"
    },
    {
      "OrigoCode": "JAB01180",
      "OrigoOccupation": "Juggler"
    },
    {
      "OrigoCode": "JAD03181",
      "OrigoOccupation": "Justice of the Peace"
    },
    {
      "OrigoCode": "KAD03182",
      "OrigoOccupation": "Kebab Van Vendor"
    },
    {
      "OrigoCode": "KAB01181",
      "OrigoOccupation": "Keeper - Zoo"
    },
    {
      "OrigoCode": "KAC02857",
      "OrigoOccupation": "Kennel Hand"
    },
    {
      "OrigoCode": "KAB01184",
      "OrigoOccupation": "Kerb Layer"
    },
    {
      "OrigoCode": "KAD03183",
      "OrigoOccupation": "Key Cutter"
    },
    {
      "OrigoCode": "KAB01185",
      "OrigoOccupation": "Keyboard Operator (type setting)"
    },
    {
      "OrigoCode": "KAB01186",
      "OrigoOccupation": "Kiln Attendant"
    },
    {
      "OrigoCode": "KAB01187",
      "OrigoOccupation": "Kiln Operator"
    },
    {
      "OrigoCode": "KAB01192",
      "OrigoOccupation": "Kitchen Porter"
    },
    {
      "OrigoCode": "KAC02858",
      "OrigoOccupation": "Kitchen Staff"
    },
    {
      "OrigoCode": "KAB01194",
      "OrigoOccupation": "Knife Thrower"
    },
    {
      "OrigoCode": "KAB01195",
      "OrigoOccupation": "Knitter"
    },
    {
      "OrigoCode": "LAB01196",
      "OrigoOccupation": "Labeller"
    },
    {
      "OrigoCode": "LAC02859",
      "OrigoOccupation": "Laboratory Manager (supervisory and some testing)"
    },
    {
      "OrigoCode": "LAC02860",
      "OrigoOccupation": "Laboratory Technician"
    },
    {
      "OrigoCode": "LAD03184",
      "OrigoOccupation": "Labourer"
    },
    {
      "OrigoCode": "LAB01246",
      "OrigoOccupation": "Lagger"
    },
    {
      "OrigoCode": "LAB01249",
      "OrigoOccupation": "Laminator"
    },
    {
      "OrigoCode": "LAB01251",
      "OrigoOccupation": "Land Agent"
    },
    {
      "OrigoCode": "LAB01252",
      "OrigoOccupation": "Land Drainage Worker"
    },
    {
      "OrigoCode": "LAB01253",
      "OrigoOccupation": "Land Surveyor"
    },
    {
      "OrigoCode": "LAD03189",
      "OrigoOccupation": "Landlord (Property -inc manual work)"
    },
    {
      "OrigoCode": "LAC02864",
      "OrigoOccupation": "Landlord (Property -no manual work )"
    },
    {
      "OrigoCode": "LAB01254",
      "OrigoOccupation": "Landscape Gardener"
    },
    {
      "OrigoCode": "LAB01255",
      "OrigoOccupation": "Landscape Painter"
    },
    {
      "OrigoCode": "LAD03191",
      "OrigoOccupation": "Lathe Operator"
    },
    {
      "OrigoCode": "LAB01259",
      "OrigoOccupation": "Launderette Assistant"
    },
    {
      "OrigoCode": "LAB01261",
      "OrigoOccupation": "Laundryman"
    },
    {
      "OrigoCode": "LAB01262",
      "OrigoOccupation": "Lavatory Attendant"
    },
    {
      "OrigoCode": "LAC02868",
      "OrigoOccupation": "Lawyer"
    },
    {
      "OrigoCode": "LAB01265",
      "OrigoOccupation": "Leading Fireman"
    },
    {
      "OrigoCode": "LAB01268",
      "OrigoOccupation": "Leather Technologist"
    },
    {
      "OrigoCode": "LAD03195",
      "OrigoOccupation": "Leather worker"
    },
    {
      "OrigoCode": "LAB01269",
      "OrigoOccupation": "Lecturer"
    },
    {
      "OrigoCode": "LAB01271",
      "OrigoOccupation": "Left Luggage Attendant"
    },
    {
      "OrigoCode": "LAC02869",
      "OrigoOccupation": "Legal Adviser"
    },
    {
      "OrigoCode": "LAC02870",
      "OrigoOccupation": "Legal Executive"
    },
    {
      "OrigoCode": "LAD03185",
      "OrigoOccupation": "Legal Practice Manager"
    },
    {
      "OrigoCode": "LAD03187",
      "OrigoOccupation": "Legal Secretary"
    },
    {
      "OrigoCode": "LAC02872",
      "OrigoOccupation": "Letting Agent - Holiday homes"
    },
    {
      "OrigoCode": "LAB01275",
      "OrigoOccupation": "Librarian"
    },
    {
      "OrigoCode": "LAC02874",
      "OrigoOccupation": "Library Assistant"
    },
    {
      "OrigoCode": "LAD03190",
      "OrigoOccupation": "Life Coach"
    },
    {
      "OrigoCode": "LAB01276",
      "OrigoOccupation": "Lifeboatman (enrolled crew)"
    },
    {
      "OrigoCode": "LAC02875",
      "OrigoOccupation": "Lifeboatman - Crew"
    },
    {
      "OrigoCode": "LAB01277",
      "OrigoOccupation": "Lifeguard"
    },
    {
      "OrigoCode": "LAB01278",
      "OrigoOccupation": "Lift Attendant"
    },
    {
      "OrigoCode": "LAC02876",
      "OrigoOccupation": "Lift Engineer"
    },
    {
      "OrigoCode": "LAC02877",
      "OrigoOccupation": "Lift Erector"
    },
    {
      "OrigoCode": "LAB01280",
      "OrigoOccupation": "Light Goods Vehicle Driver"
    },
    {
      "OrigoCode": "LAD03188",
      "OrigoOccupation": "Lighterman"
    },
    {
      "OrigoCode": "LAB01284",
      "OrigoOccupation": "Lighting Manager"
    },
    {
      "OrigoCode": "LAB01285",
      "OrigoOccupation": "Lighting Technician"
    },
    {
      "OrigoCode": "LAD03192",
      "OrigoOccupation": "Linesman"
    },
    {
      "OrigoCode": "LAB01289",
      "OrigoOccupation": "Linesman's Mate"
    },
    {
      "OrigoCode": "LAC02878",
      "OrigoOccupation": "Liquidator"
    },
    {
      "OrigoCode": "LAB01293",
      "OrigoOccupation": "Literary Agent"
    },
    {
      "OrigoCode": "LAC02879",
      "OrigoOccupation": "Lithographer"
    },
    {
      "OrigoCode": "LAB01294",
      "OrigoOccupation": "Lithographic Assistant"
    },
    {
      "OrigoCode": "LAB01295",
      "OrigoOccupation": "Lithographic Plate Grainer"
    },
    {
      "OrigoCode": "LAB01296",
      "OrigoOccupation": "Lithographic Plate Preparer"
    },
    {
      "OrigoCode": "LAD03193",
      "OrigoOccupation": "Loader"
    },
    {
      "OrigoCode": "LAD03194",
      "OrigoOccupation": "Loader Operator"
    },
    {
      "OrigoCode": "LAB01306",
      "OrigoOccupation": "Lobster Fisherman"
    },
    {
      "OrigoCode": "LAB01307",
      "OrigoOccupation": "Local Government Officer"
    },
    {
      "OrigoCode": "LAC02880",
      "OrigoOccupation": "Local Newspaper Editor"
    },
    {
      "OrigoCode": "LAB01309",
      "OrigoOccupation": "Lock Keeper"
    },
    {
      "OrigoCode": "LAB01311",
      "OrigoOccupation": "Locksmith"
    },
    {
      "OrigoCode": "LAB01312",
      "OrigoOccupation": "Locomotive Driver"
    },
    {
      "OrigoCode": "LAB01313",
      "OrigoOccupation": "Locomotive Guard"
    },
    {
      "OrigoCode": "SAB02293",
      "OrigoOccupation": "Lollipop Man/Lady"
    },
    {
      "OrigoCode": "LAB01315",
      "OrigoOccupation": "Loss Adjuster"
    },
    {
      "OrigoCode": "LAB01317",
      "OrigoOccupation": "Lumberjack"
    },
    {
      "OrigoCode": "MAB01318",
      "OrigoOccupation": "Machine Attendant"
    },
    {
      "OrigoCode": "MAB01319",
      "OrigoOccupation": "Machine Maintenance Worker"
    },
    {
      "OrigoCode": "MAD03224",
      "OrigoOccupation": "Machine Operator - processing"
    },
    {
      "OrigoCode": "MAB01358",
      "OrigoOccupation": "Machine Tool Setter-Operator"
    },
    {
      "OrigoCode": "MAB01363",
      "OrigoOccupation": "Machinery Electrician"
    },
    {
      "OrigoCode": "MAD03201",
      "OrigoOccupation": "Machinist"
    },
    {
      "OrigoCode": "MAD03204",
      "OrigoOccupation": "Magazine Editor"
    },
    {
      "OrigoCode": "MAD03207",
      "OrigoOccupation": "Magazine Illustrator"
    },
    {
      "OrigoCode": "MAD03211",
      "OrigoOccupation": "Magazine Writer"
    },
    {
      "OrigoCode": "MAB01367",
      "OrigoOccupation": "Magician"
    },
    {
      "OrigoCode": "MAC02881",
      "OrigoOccupation": "Magistrate - Stipendiary"
    },
    {
      "OrigoCode": "MAC02882",
      "OrigoOccupation": "Mail Sorter"
    },
    {
      "OrigoCode": "MAD03198",
      "OrigoOccupation": "Maintenance Fitter"
    },
    {
      "OrigoCode": "MAD03202",
      "OrigoOccupation": "Maintenance Manager"
    },
    {
      "OrigoCode": "MAB01377",
      "OrigoOccupation": "Maintenance Repairer"
    },
    {
      "OrigoCode": "MAD03216",
      "OrigoOccupation": "Maintenance Technician"
    },
    {
      "OrigoCode": "MAD03221",
      "OrigoOccupation": "Maitre d'Hotel"
    },
    {
      "OrigoCode": "MAB01390",
      "OrigoOccupation": "Make-up Artist"
    },
    {
      "OrigoCode": "MAB01393",
      "OrigoOccupation": "Malt Roaster"
    },
    {
      "OrigoCode": "MAB01394",
      "OrigoOccupation": "Maltster"
    },
    {
      "OrigoCode": "MAB01395",
      "OrigoOccupation": "Management Consultant - Usually"
    },
    {
      "OrigoCode": "MAD03222",
      "OrigoOccupation": "Manager - Offshore"
    },
    {
      "OrigoCode": "MAD03228",
      "OrigoOccupation": "Manager - Retail"
    },
    {
      "OrigoCode": "MAD03230",
      "OrigoOccupation": "Manager - Sales"
    },
    {
      "OrigoCode": "MAD03208",
      "OrigoOccupation": "Manager - admin only"
    },
    {
      "OrigoCode": "MAD03212",
      "OrigoOccupation": "Manager - heavy manual work"
    },
    {
      "OrigoCode": "MAD03217",
      "OrigoOccupation": "Manager - light manual work"
    },
    {
      "OrigoCode": "MAD03225",
      "OrigoOccupation": "Manager - other"
    },
    {
      "OrigoCode": "MAB01436",
      "OrigoOccupation": "Manager (off site)"
    },
    {
      "OrigoCode": "MAD03235",
      "OrigoOccupation": "Managing Director - Other"
    },
    {
      "OrigoCode": "MAD03236",
      "OrigoOccupation": "Managing Director - Retail"
    },
    {
      "OrigoCode": "MAD03237",
      "OrigoOccupation": "Managing Director - Sales Management"
    },
    {
      "OrigoCode": "MAD03238",
      "OrigoOccupation": "Managing Director - Selling"
    },
    {
      "OrigoCode": "MAD03232",
      "OrigoOccupation": "Managing Director - admin/office based only"
    },
    {
      "OrigoCode": "MAD03233",
      "OrigoOccupation": "Managing Director - heavy manual duties"
    },
    {
      "OrigoCode": "MAD03234",
      "OrigoOccupation": "Managing Director - light manual duties"
    },
    {
      "OrigoCode": "MAD03239",
      "OrigoOccupation": "Manhole Maker"
    },
    {
      "OrigoCode": "MAB01451",
      "OrigoOccupation": "Manicurist"
    },
    {
      "OrigoCode": "MAD03197",
      "OrigoOccupation": "Marine Biologist"
    },
    {
      "OrigoCode": "MAB01453",
      "OrigoOccupation": "Marine Engineer"
    },
    {
      "OrigoCode": "MAB01454",
      "OrigoOccupation": "Marine Installation Fitter"
    },
    {
      "OrigoCode": "MAB01456",
      "OrigoOccupation": "Marine Surveyor"
    },
    {
      "OrigoCode": "MAB01459",
      "OrigoOccupation": "Market Gardener"
    },
    {
      "OrigoCode": "MAB01460",
      "OrigoOccupation": "Market Porter - Usually"
    },
    {
      "OrigoCode": "MAB01461",
      "OrigoOccupation": "Market Research Analyst"
    },
    {
      "OrigoCode": "MAB01462",
      "OrigoOccupation": "Market Research Interviewer"
    },
    {
      "OrigoCode": "MAC02886",
      "OrigoOccupation": "Market Researcher (office based)"
    },
    {
      "OrigoCode": "MAC02887",
      "OrigoOccupation": "Market Researcher - Street research"
    },
    {
      "OrigoCode": "MAD03226",
      "OrigoOccupation": "Market or Street Trader"
    },
    {
      "OrigoCode": "MAC02885",
      "OrigoOccupation": "Market or Street Traders Assistant"
    },
    {
      "OrigoCode": "MAC02888",
      "OrigoOccupation": "Marketing Consultant - International"
    },
    {
      "OrigoCode": "MAC02889",
      "OrigoOccupation": "Marketing Manager"
    },
    {
      "OrigoCode": "MAC02890",
      "OrigoOccupation": "Marketing Research Manager (office based)"
    },
    {
      "OrigoCode": "MAD03196",
      "OrigoOccupation": "Marriage Guidance Counsellor"
    },
    {
      "OrigoCode": "MAB01464",
      "OrigoOccupation": "Martial Arts Instructor"
    },
    {
      "OrigoCode": "MAB01465",
      "OrigoOccupation": "Mason"
    },
    {
      "OrigoCode": "MAB01466",
      "OrigoOccupation": "Mason Bricklayer"
    },
    {
      "OrigoCode": "MAB01467",
      "OrigoOccupation": "Mason's Labourer"
    },
    {
      "OrigoCode": "MAB01468",
      "OrigoOccupation": "Masseur"
    },
    {
      "OrigoCode": "MAB01469",
      "OrigoOccupation": "Masseuse"
    },
    {
      "OrigoCode": "MAB01476",
      "OrigoOccupation": "Mate, Second Hand"
    },
    {
      "OrigoCode": "MAC02891",
      "OrigoOccupation": "Mathematician"
    },
    {
      "OrigoCode": "MAB01478",
      "OrigoOccupation": "Mattress Maker"
    },
    {
      "OrigoCode": "MAB01482",
      "OrigoOccupation": "Meat Cutter"
    },
    {
      "OrigoCode": "MAB01483",
      "OrigoOccupation": "Meat Inspector"
    },
    {
      "OrigoCode": "MAB01484",
      "OrigoOccupation": "Meat Trimmer"
    },
    {
      "OrigoCode": "MAD03205",
      "OrigoOccupation": "Mechanic"
    },
    {
      "OrigoCode": "MAD03209",
      "OrigoOccupation": "Mechanic - Oil Rig"
    },
    {
      "OrigoCode": "MAD03213",
      "OrigoOccupation": "Mechanical Engineer"
    },
    {
      "OrigoCode": "MAB01496",
      "OrigoOccupation": "Medical Practitioner"
    },
    {
      "OrigoCode": "MAD03214",
      "OrigoOccupation": "Medical Receptionist"
    },
    {
      "OrigoCode": "MAD03218",
      "OrigoOccupation": "Medical Secretary"
    },
    {
      "OrigoCode": "MAB01497",
      "OrigoOccupation": "Medium"
    },
    {
      "OrigoCode": "MAB01499",
      "OrigoOccupation": "Member of Parliament, Politician"
    },
    {
      "OrigoCode": "MAC02896",
      "OrigoOccupation": "Messenger - Motorcycle"
    },
    {
      "OrigoCode": "MAC02897",
      "OrigoOccupation": "Messenger - Not motorcycle"
    },
    {
      "OrigoCode": "MAB01503",
      "OrigoOccupation": "Metallographer"
    },
    {
      "OrigoCode": "MAB01504",
      "OrigoOccupation": "Metallurgist"
    },
    {
      "OrigoCode": "MAB01505",
      "OrigoOccupation": "Meteorologist"
    },
    {
      "OrigoCode": "MAD03210",
      "OrigoOccupation": "Meter Collector"
    },
    {
      "OrigoCode": "MAD03215",
      "OrigoOccupation": "Meter Fixer/Tester"
    },
    {
      "OrigoCode": "MAD03219",
      "OrigoOccupation": "Meter Reader"
    },
    {
      "OrigoCode": "MAB01515",
      "OrigoOccupation": "Microfilm Operator"
    },
    {
      "OrigoCode": "MAC02901",
      "OrigoOccupation": "Midwife"
    },
    {
      "OrigoCode": "MAB01516",
      "OrigoOccupation": "Milk Roundsman"
    },
    {
      "OrigoCode": "MAB01517",
      "OrigoOccupation": "Milker"
    },
    {
      "OrigoCode": "MAD03220",
      "OrigoOccupation": "Miller"
    },
    {
      "OrigoCode": "MAD03223",
      "OrigoOccupation": "Milliner"
    },
    {
      "OrigoCode": "MAC02902",
      "OrigoOccupation": "Miner"
    },
    {
      "OrigoCode": "MAD03199",
      "OrigoOccupation": "Mineralogist"
    },
    {
      "OrigoCode": "MAC02903",
      "OrigoOccupation": "Mini Cab Driver"
    },
    {
      "OrigoCode": "MAB01529",
      "OrigoOccupation": "Mining Engineer"
    },
    {
      "OrigoCode": "MAB01530",
      "OrigoOccupation": "Mining Officer"
    },
    {
      "OrigoCode": "MAB01531",
      "OrigoOccupation": "Mining Surveyor"
    },
    {
      "OrigoCode": "MAC02904",
      "OrigoOccupation": "Minister of Religion"
    },
    {
      "OrigoCode": "MAB01532",
      "OrigoOccupation": "Missionary"
    },
    {
      "OrigoCode": "MAD03200",
      "OrigoOccupation": "Mixer - processing"
    },
    {
      "OrigoCode": "MAD03203",
      "OrigoOccupation": "Mobile Crane Driver"
    },
    {
      "OrigoCode": "MAC02905",
      "OrigoOccupation": "Model Maker"
    },
    {
      "OrigoCode": "MAB01546",
      "OrigoOccupation": "Money Broker"
    },
    {
      "OrigoCode": "MAB01548",
      "OrigoOccupation": "Mortuary Attendant"
    },
    {
      "OrigoCode": "MAC02906",
      "OrigoOccupation": "Motor Bike Instructor"
    },
    {
      "OrigoCode": "MAB01551",
      "OrigoOccupation": "Motor Cycle Courier"
    },
    {
      "OrigoCode": "MAC02907",
      "OrigoOccupation": "Motor Cycle Messenger"
    },
    {
      "OrigoCode": "MAC02908",
      "OrigoOccupation": "Motor Fleet Manager"
    },
    {
      "OrigoCode": "MAC02909",
      "OrigoOccupation": "Motor Service Manager - admin only"
    },
    {
      "OrigoCode": "MAD03227",
      "OrigoOccupation": "Motorman"
    },
    {
      "OrigoCode": "MAD03229",
      "OrigoOccupation": "Mould Maker"
    },
    {
      "OrigoCode": "MAD03231",
      "OrigoOccupation": "Moulder"
    },
    {
      "OrigoCode": "MAB01564",
      "OrigoOccupation": "Mud Engineer"
    },
    {
      "OrigoCode": "MAB01565",
      "OrigoOccupation": "Mud Logger"
    },
    {
      "OrigoCode": "MAB01566",
      "OrigoOccupation": "Mud Man"
    },
    {
      "OrigoCode": "MAB01568",
      "OrigoOccupation": "Museum Attendant"
    },
    {
      "OrigoCode": "MAB01569",
      "OrigoOccupation": "Museum Curator"
    },
    {
      "OrigoCode": "MAB01570",
      "OrigoOccupation": "Museum Guide"
    },
    {
      "OrigoCode": "MAB01571",
      "OrigoOccupation": "Music Teacher (Private)"
    },
    {
      "OrigoCode": "MAB01573",
      "OrigoOccupation": "Musical Instrument Maker"
    },
    {
      "OrigoCode": "MAB01574",
      "OrigoOccupation": "Musical Instrument Repairer"
    },
    {
      "OrigoCode": "MAD03206",
      "OrigoOccupation": "Musician - Professional"
    },
    {
      "OrigoCode": "NAD03241",
      "OrigoOccupation": "NHS Manager"
    },
    {
      "OrigoCode": "NAD03243",
      "OrigoOccupation": "Nail Technician/Beautician"
    },
    {
      "OrigoCode": "NAC01811",
      "OrigoOccupation": "Nanny"
    },
    {
      "OrigoCode": "NAD03240",
      "OrigoOccupation": "Nature Reserve Worker/Warden"
    },
    {
      "OrigoCode": "NAB01575",
      "OrigoOccupation": "Navigator"
    },
    {
      "OrigoCode": "NAB01578",
      "OrigoOccupation": "News Photographer - no overseas travel etc"
    },
    {
      "OrigoCode": "NAB01579",
      "OrigoOccupation": "News Photographer - otherwise"
    },
    {
      "OrigoCode": "NAC01812",
      "OrigoOccupation": "Newsagent (not delivering papers)"
    },
    {
      "OrigoCode": "NAC01814",
      "OrigoOccupation": "Newspaper Reporter - Freelance"
    },
    {
      "OrigoCode": "NAB01580",
      "OrigoOccupation": "Newsreader"
    },
    {
      "OrigoCode": "NAB01581",
      "OrigoOccupation": "Newsvendor"
    },
    {
      "OrigoCode": "NAD03242",
      "OrigoOccupation": "Night Watchman"
    },
    {
      "OrigoCode": "NAB01587",
      "OrigoOccupation": "Nuclear Engineer"
    },
    {
      "OrigoCode": "NAD03244",
      "OrigoOccupation": "Nuclear Plant Attendant"
    },
    {
      "OrigoCode": "NAB01591",
      "OrigoOccupation": "Nuclear Scientist"
    },
    {
      "OrigoCode": "NAB01592",
      "OrigoOccupation": "Nurse"
    },
    {
      "OrigoCode": "NAB01597",
      "OrigoOccupation": "Nurse - Midwife"
    },
    {
      "OrigoCode": "NAD03245",
      "OrigoOccupation": "Nurse - Sister"
    },
    {
      "OrigoCode": "NAB01598",
      "OrigoOccupation": "Nurse - Teaching duties only"
    },
    {
      "OrigoCode": "NAD03246",
      "OrigoOccupation": "Nursery School Assistant"
    },
    {
      "OrigoCode": "NAB01599",
      "OrigoOccupation": "Nurseryman"
    },
    {
      "OrigoCode": "NAB01600",
      "OrigoOccupation": "Nursing Auxiliary"
    },
    {
      "OrigoCode": "NAC01817",
      "OrigoOccupation": "Nursing Home Proprietor (admin. only)"
    },
    {
      "OrigoCode": "OAC01818",
      "OrigoOccupation": "Obstetrician"
    },
    {
      "OrigoCode": "OAB01601",
      "OrigoOccupation": "Occupational Therapist"
    },
    {
      "OrigoCode": "OAD03247",
      "OrigoOccupation": "Oceanographer"
    },
    {
      "OrigoCode": "OAD03251",
      "OrigoOccupation": "Off-Licence Employee"
    },
    {
      "OrigoCode": "OAB01602",
      "OrigoOccupation": "Off-Licence Manager"
    },
    {
      "OrigoCode": "OAB01604",
      "OrigoOccupation": "Office Clerk"
    },
    {
      "OrigoCode": "OAB01605",
      "OrigoOccupation": "Office Fitter"
    },
    {
      "OrigoCode": "OAB01606",
      "OrigoOccupation": "Office Messenger"
    },
    {
      "OrigoCode": "OAB01608",
      "OrigoOccupation": "Office Receptionist"
    },
    {
      "OrigoCode": "OAD03248",
      "OrigoOccupation": "Oiler and Greaser"
    },
    {
      "OrigoCode": "OAB01612",
      "OrigoOccupation": "Old People's Home Matron"
    },
    {
      "OrigoCode": "OAB01613",
      "OrigoOccupation": "Old People's Home Warden"
    },
    {
      "OrigoCode": "OAC08123",
      "OrigoOccupation": "Operations Manager - Light engineering company"
    },
    {
      "OrigoCode": "OAB01616",
      "OrigoOccupation": "Operations Officer"
    },
    {
      "OrigoCode": "OAB01617",
      "OrigoOccupation": "Optical Instrument Fitter"
    },
    {
      "OrigoCode": "OAB01618",
      "OrigoOccupation": "Optical Instrument Maker"
    },
    {
      "OrigoCode": "OAB01619",
      "OrigoOccupation": "Optical Instrument Mechanic"
    },
    {
      "OrigoCode": "OAB01620",
      "OrigoOccupation": "Optical Instrument Repairer"
    },
    {
      "OrigoCode": "OAB01621",
      "OrigoOccupation": "Optical Printer"
    },
    {
      "OrigoCode": "OAB01622",
      "OrigoOccupation": "Optician"
    },
    {
      "OrigoCode": "OAC01826",
      "OrigoOccupation": "Opticians Assistant"
    },
    {
      "OrigoCode": "OAB01625",
      "OrigoOccupation": "Orchestrator"
    },
    {
      "OrigoCode": "OAB01628",
      "OrigoOccupation": "Orthodontic Technician"
    },
    {
      "OrigoCode": "OAB01629",
      "OrigoOccupation": "Orthodontist"
    },
    {
      "OrigoCode": "OAC01827",
      "OrigoOccupation": "Orthopaedic Surgeon"
    },
    {
      "OrigoCode": "OAB01630",
      "OrigoOccupation": "Orthoptist"
    },
    {
      "OrigoCode": "OAB01631",
      "OrigoOccupation": "Osteopath"
    },
    {
      "OrigoCode": "OAD03382",
      "OrigoOccupation": "Other - Occupation not listed"
    },
    {
      "OrigoCode": "OAD03249",
      "OrigoOccupation": "Outdoor Pursuits Centre Instructor"
    },
    {
      "OrigoCode": "OAD03250",
      "OrigoOccupation": "Ovensman"
    },
    {
      "OrigoCode": "OAB01637",
      "OrigoOccupation": "Overhead Crane Driver"
    },
    {
      "OrigoCode": "OAB01638",
      "OrigoOccupation": "Overhead Linesman"
    },
    {
      "OrigoCode": "OAB01639",
      "OrigoOccupation": "Overhead Linesman's Mate"
    },
    {
      "OrigoCode": "OAB01643",
      "OrigoOccupation": "Oyster Fisherman"
    },
    {
      "OrigoCode": "PAD03258",
      "OrigoOccupation": "P E Teacher"
    },
    {
      "OrigoCode": "PAC01857",
      "OrigoOccupation": "PR Executive"
    },
    {
      "OrigoCode": "PAB01644",
      "OrigoOccupation": "Packaging Machine Attendant"
    },
    {
      "OrigoCode": "PAD03261",
      "OrigoOccupation": "Packer"
    },
    {
      "OrigoCode": "PAC01828",
      "OrigoOccupation": "Painter"
    },
    {
      "OrigoCode": "PAB01666",
      "OrigoOccupation": "Painter & Decorator (Interior)"
    },
    {
      "OrigoCode": "PAB01668",
      "OrigoOccupation": "Painter (Exterior) - 40' up"
    },
    {
      "OrigoCode": "PAB01669",
      "OrigoOccupation": "Painter (Exterior) - up to 40'"
    },
    {
      "OrigoCode": "PAB01665",
      "OrigoOccupation": "Painter - Woodworking Industry"
    },
    {
      "OrigoCode": "PAD03272",
      "OrigoOccupation": "Panel Beater"
    },
    {
      "OrigoCode": "PAB01673",
      "OrigoOccupation": "Paper Maker (hand)"
    },
    {
      "OrigoCode": "PAB01674",
      "OrigoOccupation": "Paper Merchant"
    },
    {
      "OrigoCode": "PAB01676",
      "OrigoOccupation": "Paramedic (Driver)"
    },
    {
      "OrigoCode": "PAB01677",
      "OrigoOccupation": "Paramedic (No driving)"
    },
    {
      "OrigoCode": "PAB01678",
      "OrigoOccupation": "Park Keeper"
    },
    {
      "OrigoCode": "PAB01679",
      "OrigoOccupation": "Park Ranger"
    },
    {
      "OrigoCode": "PAB01680",
      "OrigoOccupation": "Parks Superintendent"
    },
    {
      "OrigoCode": "PAB01681",
      "OrigoOccupation": "Parliamentary Agent"
    },
    {
      "OrigoCode": "PAC01832",
      "OrigoOccupation": "Party Organiser"
    },
    {
      "OrigoCode": "PAB01682",
      "OrigoOccupation": "Passenger Officer"
    },
    {
      "OrigoCode": "PAB01685",
      "OrigoOccupation": "Pasteuriser"
    },
    {
      "OrigoCode": "PAB01687",
      "OrigoOccupation": "Patent Agent"
    },
    {
      "OrigoCode": "PAB01688",
      "OrigoOccupation": "Pathologist"
    },
    {
      "OrigoCode": "PAB01691",
      "OrigoOccupation": "Pattern Maker - Metal, Plastic etc"
    },
    {
      "OrigoCode": "PAB01692",
      "OrigoOccupation": "Pavior"
    },
    {
      "OrigoCode": "PAC01834",
      "OrigoOccupation": "Pawnbroker"
    },
    {
      "OrigoCode": "PAB01693",
      "OrigoOccupation": "Pedicurist"
    },
    {
      "OrigoCode": "PAD03259",
      "OrigoOccupation": "Personal Assistant (PA)"
    },
    {
      "OrigoCode": "PAB01696",
      "OrigoOccupation": "Pest Control Manager"
    },
    {
      "OrigoCode": "PAB01697",
      "OrigoOccupation": "Pest Control Operator"
    },
    {
      "OrigoCode": "PAB01699",
      "OrigoOccupation": "Petrol Pump Attendant"
    },
    {
      "OrigoCode": "PAB01701",
      "OrigoOccupation": "Pharmacist"
    },
    {
      "OrigoCode": "PAB01702",
      "OrigoOccupation": "Pharmacologist"
    },
    {
      "OrigoCode": "PAB01703",
      "OrigoOccupation": "Pharmacy Assistant"
    },
    {
      "OrigoCode": "PAC01844",
      "OrigoOccupation": "Phlebotomist"
    },
    {
      "OrigoCode": "PAD03383",
      "OrigoOccupation": "Photocopier Engineer"
    },
    {
      "OrigoCode": "PAB01704",
      "OrigoOccupation": "Photocopying Machine Operator"
    },
    {
      "OrigoCode": "PAC01845",
      "OrigoOccupation": "Photographer"
    },
    {
      "OrigoCode": "PAC01846",
      "OrigoOccupation": "Photographer - Aerial"
    },
    {
      "OrigoCode": "PAB01705",
      "OrigoOccupation": "Photographic Finisher"
    },
    {
      "OrigoCode": "PAB01706",
      "OrigoOccupation": "Photographic Model"
    },
    {
      "OrigoCode": "PAB01707",
      "OrigoOccupation": "Photographic Mounter"
    },
    {
      "OrigoCode": "PAB01708",
      "OrigoOccupation": "Physician"
    },
    {
      "OrigoCode": "PAB01709",
      "OrigoOccupation": "Physicist"
    },
    {
      "OrigoCode": "PAB01710",
      "OrigoOccupation": "Physiotherapist"
    },
    {
      "OrigoCode": "PAB01711",
      "OrigoOccupation": "Piano/Organ Tuner"
    },
    {
      "OrigoCode": "PAB01712",
      "OrigoOccupation": "Pickler"
    },
    {
      "OrigoCode": "PAC01848",
      "OrigoOccupation": "Picture Framer"
    },
    {
      "OrigoCode": "PAB01713",
      "OrigoOccupation": "Pier Master"
    },
    {
      "OrigoCode": "PAB01714",
      "OrigoOccupation": "Pile Driver"
    },
    {
      "OrigoCode": "PAB01715",
      "OrigoOccupation": "Pilot"
    },
    {
      "OrigoCode": "PAD03263",
      "OrigoOccupation": "Pipe Fitter"
    },
    {
      "OrigoCode": "PAD03264",
      "OrigoOccupation": "Pipe Jointer"
    },
    {
      "OrigoCode": "PAD03268",
      "OrigoOccupation": "Pipe Layer"
    },
    {
      "OrigoCode": "PAB01729",
      "OrigoOccupation": "Pipe/Powerline Survey Work"
    },
    {
      "OrigoCode": "PAB01731",
      "OrigoOccupation": "Pitch Melter"
    },
    {
      "OrigoCode": "PAD03253",
      "OrigoOccupation": "Planning Engineer"
    },
    {
      "OrigoCode": "PAB01735",
      "OrigoOccupation": "Planning Inspector"
    },
    {
      "OrigoCode": "PAB01736",
      "OrigoOccupation": "Plant Attendant"
    },
    {
      "OrigoCode": "PAC01849",
      "OrigoOccupation": "Plant Hire Manager (some manual work)"
    },
    {
      "OrigoCode": "PAC01850",
      "OrigoOccupation": "Plant Hire Owner (some manual work)"
    },
    {
      "OrigoCode": "PAC01851",
      "OrigoOccupation": "Plant Hire Proprietor (admin. only)"
    },
    {
      "OrigoCode": "PAD03254",
      "OrigoOccupation": "Plant Operator"
    },
    {
      "OrigoCode": "PAB01747",
      "OrigoOccupation": "Plasterer"
    },
    {
      "OrigoCode": "PAB01748",
      "OrigoOccupation": "Plastic Coating Operator"
    },
    {
      "OrigoCode": "PAB01752",
      "OrigoOccupation": "Plate Cutter"
    },
    {
      "OrigoCode": "PAB01753",
      "OrigoOccupation": "Plate Moulder"
    },
    {
      "OrigoCode": "PAB01754",
      "OrigoOccupation": "Plate Separator"
    },
    {
      "OrigoCode": "PAB01755",
      "OrigoOccupation": "Platelayer"
    },
    {
      "OrigoCode": "PAB01756",
      "OrigoOccupation": "Plateman"
    },
    {
      "OrigoCode": "PAB01760",
      "OrigoOccupation": "Plater (including Boiler)"
    },
    {
      "OrigoCode": "PAB01757",
      "OrigoOccupation": "Plater - Aircraft/Aerospace"
    },
    {
      "OrigoCode": "PAB01758",
      "OrigoOccupation": "Plater - Motor Vehicle & Cycle Industry"
    },
    {
      "OrigoCode": "PAB01759",
      "OrigoOccupation": "Plater - Ship Building, Ship Repair & Marine Engineering"
    },
    {
      "OrigoCode": "PAD03252",
      "OrigoOccupation": "Playschool/Group Worker/Leader"
    },
    {
      "OrigoCode": "PAB01761",
      "OrigoOccupation": "Playwright"
    },
    {
      "OrigoCode": "PAB01762",
      "OrigoOccupation": "Plumber - Construction/Industrial"
    },
    {
      "OrigoCode": "PAC01852",
      "OrigoOccupation": "Plumber - Domestic"
    },
    {
      "OrigoCode": "PAB01765",
      "OrigoOccupation": "Pneumatic Drill Operator"
    },
    {
      "OrigoCode": "PAB01766",
      "OrigoOccupation": "Poet"
    },
    {
      "OrigoCode": "PAB01767",
      "OrigoOccupation": "Pointsman"
    },
    {
      "OrigoCode": "PAB01768",
      "OrigoOccupation": "Police"
    },
    {
      "OrigoCode": "PAB01773",
      "OrigoOccupation": "Police Frogman"
    },
    {
      "OrigoCode": "PAD03260",
      "OrigoOccupation": "Politician"
    },
    {
      "OrigoCode": "PAB01777",
      "OrigoOccupation": "Pollution Inspector"
    },
    {
      "OrigoCode": "PAB01780",
      "OrigoOccupation": "Pop Musician"
    },
    {
      "OrigoCode": "PAB01781",
      "OrigoOccupation": "Pop Singer"
    },
    {
      "OrigoCode": "PAB01782",
      "OrigoOccupation": "Port Control Signalman"
    },
    {
      "OrigoCode": "PAB01783",
      "OrigoOccupation": "Port Health Inspector"
    },
    {
      "OrigoCode": "PAB01785",
      "OrigoOccupation": "Porter - Meat, Fish etc - Food & Drink"
    },
    {
      "OrigoCode": "PAB01786",
      "OrigoOccupation": "Porter - Station Personnel - Railways"
    },
    {
      "OrigoCode": "PAB01787",
      "OrigoOccupation": "Portrait Painter"
    },
    {
      "OrigoCode": "PAB01788",
      "OrigoOccupation": "Portrait Photographer"
    },
    {
      "OrigoCode": "PAC01853",
      "OrigoOccupation": "Postal Courier Driver"
    },
    {
      "OrigoCode": "PAC01854",
      "OrigoOccupation": "Postal Courier Manager"
    },
    {
      "OrigoCode": "PAB01790",
      "OrigoOccupation": "Postman"
    },
    {
      "OrigoCode": "PAB01789",
      "OrigoOccupation": "Postman (no driving)"
    },
    {
      "OrigoCode": "PAB01791",
      "OrigoOccupation": "Pot Fisherman"
    },
    {
      "OrigoCode": "PAB01792",
      "OrigoOccupation": "Potman"
    },
    {
      "OrigoCode": "PAB01793",
      "OrigoOccupation": "Potter"
    },
    {
      "OrigoCode": "PAB01794",
      "OrigoOccupation": "Poultry Dresser"
    },
    {
      "OrigoCode": "PAB01795",
      "OrigoOccupation": "Poultry Plucker"
    },
    {
      "OrigoCode": "PAB01797",
      "OrigoOccupation": "Poultryman"
    },
    {
      "OrigoCode": "PAB01798",
      "OrigoOccupation": "Power Loader Man"
    },
    {
      "OrigoCode": "PAB01799",
      "OrigoOccupation": "Power Loader Operator"
    },
    {
      "OrigoCode": "PAB01800",
      "OrigoOccupation": "Power Station Charge Engineer"
    },
    {
      "OrigoCode": "PAB01801",
      "OrigoOccupation": "Power Station Manager"
    },
    {
      "OrigoCode": "PAB01802",
      "OrigoOccupation": "Power Station Superintendent"
    },
    {
      "OrigoCode": "PAC01858",
      "OrigoOccupation": "Practice Manager"
    },
    {
      "OrigoCode": "PAB01806",
      "OrigoOccupation": "Precision Instrument Fitter"
    },
    {
      "OrigoCode": "PAB01807",
      "OrigoOccupation": "Precision Instrument Maker"
    },
    {
      "OrigoCode": "PAB01808",
      "OrigoOccupation": "Precision Instrument Repairer"
    },
    {
      "OrigoCode": "PAB01809",
      "OrigoOccupation": "Preparer"
    },
    {
      "OrigoCode": "PAB01810",
      "OrigoOccupation": "Press Cutter"
    },
    {
      "OrigoCode": "PAB01811",
      "OrigoOccupation": "Press Officer"
    },
    {
      "OrigoCode": "PAD03255",
      "OrigoOccupation": "Press Operator - processing"
    },
    {
      "OrigoCode": "PAB01814",
      "OrigoOccupation": "Press Tool Setter"
    },
    {
      "OrigoCode": "PAB01817",
      "OrigoOccupation": "Presser - Fruit & Veg. - Food & Drink"
    },
    {
      "OrigoCode": "PAB01818",
      "OrigoOccupation": "Presser - Laundry"
    },
    {
      "OrigoCode": "PAB01819",
      "OrigoOccupation": "Priest"
    },
    {
      "OrigoCode": "PAB01821",
      "OrigoOccupation": "Printer"
    },
    {
      "OrigoCode": "PAC01860",
      "OrigoOccupation": "Printing Director (purely admin.)"
    },
    {
      "OrigoCode": "PAB01826",
      "OrigoOccupation": "Prison Officer"
    },
    {
      "OrigoCode": "PAB01827",
      "OrigoOccupation": "Private Detective"
    },
    {
      "OrigoCode": "PAB01831",
      "OrigoOccupation": "Probation Officer"
    },
    {
      "OrigoCode": "PAD03256",
      "OrigoOccupation": "Process worker"
    },
    {
      "OrigoCode": "PAD03257",
      "OrigoOccupation": "Producer - TV/film/theatre"
    },
    {
      "OrigoCode": "PAB01868",
      "OrigoOccupation": "Production Manager"
    },
    {
      "OrigoCode": "PAC01865",
      "OrigoOccupation": "Professional Sportsperson"
    },
    {
      "OrigoCode": "PAC01866",
      "OrigoOccupation": "Project Co-ordinator"
    },
    {
      "OrigoCode": "PAD03266",
      "OrigoOccupation": "Project Manager/Programme Manager"
    },
    {
      "OrigoCode": "PAB01899",
      "OrigoOccupation": "Projectionist"
    },
    {
      "OrigoCode": "PAB01900",
      "OrigoOccupation": "Prompter"
    },
    {
      "OrigoCode": "PAB01901",
      "OrigoOccupation": "Proofer"
    },
    {
      "OrigoCode": "PAC01867",
      "OrigoOccupation": "Property & Estate Manager"
    },
    {
      "OrigoCode": "PAD03271",
      "OrigoOccupation": "Property Developer(no manual work)"
    },
    {
      "OrigoCode": "PAB01902",
      "OrigoOccupation": "Property Manager"
    },
    {
      "OrigoCode": "PAB01905",
      "OrigoOccupation": "Proprietor"
    },
    {
      "OrigoCode": "PAB01906",
      "OrigoOccupation": "Psychiatrist"
    },
    {
      "OrigoCode": "PAB01907",
      "OrigoOccupation": "Psychologist"
    },
    {
      "OrigoCode": "PAC01870",
      "OrigoOccupation": "Psychotherapist"
    },
    {
      "OrigoCode": "PAC01871",
      "OrigoOccupation": "Public Hall Bookings Office Manager"
    },
    {
      "OrigoCode": "PAB01909",
      "OrigoOccupation": "Public Health Inspector"
    },
    {
      "OrigoCode": "PAB01910",
      "OrigoOccupation": "Public House Manager (salaried)"
    },
    {
      "OrigoCode": "PAB01911",
      "OrigoOccupation": "Public Lighting Fitter-Erector"
    },
    {
      "OrigoCode": "PAB01913",
      "OrigoOccupation": "Public Relations Officer"
    },
    {
      "OrigoCode": "PAB01915",
      "OrigoOccupation": "Publican"
    },
    {
      "OrigoCode": "PAB01916",
      "OrigoOccupation": "Publisher"
    },
    {
      "OrigoCode": "PAD03267",
      "OrigoOccupation": "Pumpman"
    },
    {
      "OrigoCode": "PAB01923",
      "OrigoOccupation": "Puppeteer"
    },
    {
      "OrigoCode": "PAC01872",
      "OrigoOccupation": "Purchasing Officer/Manager (not retail)"
    },
    {
      "OrigoCode": "PAB01924",
      "OrigoOccupation": "Purifier Man"
    },
    {
      "OrigoCode": "PAB01925",
      "OrigoOccupation": "Purser"
    },
    {
      "OrigoCode": "PAB01927",
      "OrigoOccupation": "Pusherman"
    },
    {
      "OrigoCode": "QAD03273",
      "OrigoOccupation": "Quality Control Engineer"
    },
    {
      "OrigoCode": "QAD03274",
      "OrigoOccupation": "Quality Control Inspector"
    },
    {
      "OrigoCode": "QAB01930",
      "OrigoOccupation": "Quantity Surveyor"
    },
    {
      "OrigoCode": "QAB01931",
      "OrigoOccupation": "Quarry Manager"
    },
    {
      "OrigoCode": "QAC01874",
      "OrigoOccupation": "Quarryman"
    },
    {
      "OrigoCode": "QAB01933",
      "OrigoOccupation": "Quartermaster"
    },
    {
      "OrigoCode": "QAB01934",
      "OrigoOccupation": "Queen's Counsel"
    },
    {
      "OrigoCode": "RAB02043",
      "OrigoOccupation": "RSPCA Inspector"
    },
    {
      "OrigoCode": "RAB01935",
      "OrigoOccupation": "Rabbi"
    },
    {
      "OrigoCode": "RAD03278",
      "OrigoOccupation": "Racetrack Steward"
    },
    {
      "OrigoCode": "RAB01936",
      "OrigoOccupation": "Radar Controller/Operator"
    },
    {
      "OrigoCode": "RAB01937",
      "OrigoOccupation": "Radar Observer"
    },
    {
      "OrigoCode": "RAB01942",
      "OrigoOccupation": "Radio Station Manager"
    },
    {
      "OrigoCode": "RAC01877",
      "OrigoOccupation": "Radio and TV Repairer"
    },
    {
      "OrigoCode": "RAB01943",
      "OrigoOccupation": "Radio/Radar Operator"
    },
    {
      "OrigoCode": "RAC01875",
      "OrigoOccupation": "Radio/TV Announcer/Presenter"
    },
    {
      "OrigoCode": "RAC01876",
      "OrigoOccupation": "Radio/TV Director/Producer"
    },
    {
      "OrigoCode": "RAB01947",
      "OrigoOccupation": "Radiographer - Health"
    },
    {
      "OrigoCode": "RAB01948",
      "OrigoOccupation": "Radiologist"
    },
    {
      "OrigoCode": "RAD03281",
      "OrigoOccupation": "Radiotherapist"
    },
    {
      "OrigoCode": "RAB01950",
      "OrigoOccupation": "Rag & Bone Dealer"
    },
    {
      "OrigoCode": "RAB01959",
      "OrigoOccupation": "Receptionist"
    },
    {
      "OrigoCode": "RAC01881",
      "OrigoOccupation": "Record Producer - Entertainment Industry"
    },
    {
      "OrigoCode": "RAB01960",
      "OrigoOccupation": "Recording Engineer"
    },
    {
      "OrigoCode": "RAD03275",
      "OrigoOccupation": "Recruitment Consultant"
    },
    {
      "OrigoCode": "RAB01961",
      "OrigoOccupation": "Rector"
    },
    {
      "OrigoCode": "RAB01963",
      "OrigoOccupation": "Reflexologist"
    },
    {
      "OrigoCode": "RAC01882",
      "OrigoOccupation": "Refuse Collector"
    },
    {
      "OrigoCode": "RAB01964",
      "OrigoOccupation": "Registrar"
    },
    {
      "OrigoCode": "RAC01883",
      "OrigoOccupation": "Reinsurance Broker"
    },
    {
      "OrigoCode": "RAD03277",
      "OrigoOccupation": "Relationship Manager"
    },
    {
      "OrigoCode": "RAB01967",
      "OrigoOccupation": "Rent Collector"
    },
    {
      "OrigoCode": "RAB01973",
      "OrigoOccupation": "Reporter/Writer - no overseas travel etc"
    },
    {
      "OrigoCode": "RAB01974",
      "OrigoOccupation": "Reporter/Writer - otherwise"
    },
    {
      "OrigoCode": "RAB01975",
      "OrigoOccupation": "Rescue Diver"
    },
    {
      "OrigoCode": "RAC01885",
      "OrigoOccupation": "Research Chemist (Managerial)"
    },
    {
      "OrigoCode": "RAC01886",
      "OrigoOccupation": "Research Information Officer (Office based)"
    },
    {
      "OrigoCode": "RAC01887",
      "OrigoOccupation": "Research Projects Manager (Deals with hazardous substances)"
    },
    {
      "OrigoCode": "RAB01976",
      "OrigoOccupation": "Research Survey Clerk"
    },
    {
      "OrigoCode": "RAB01978",
      "OrigoOccupation": "Researcher - Journalism"
    },
    {
      "OrigoCode": "RAB01977",
      "OrigoOccupation": "Researcher - Radio & TV - Entertainment"
    },
    {
      "OrigoCode": "RAB01979",
      "OrigoOccupation": "Reservations Clerk"
    },
    {
      "OrigoCode": "RAB01980",
      "OrigoOccupation": "Reservoir Attendant"
    },
    {
      "OrigoCode": "RAC01888",
      "OrigoOccupation": "Residential Care Worker"
    },
    {
      "OrigoCode": "RAC01890",
      "OrigoOccupation": "Residential Home Proprietor (Admin. only)"
    },
    {
      "OrigoCode": "RAC01891",
      "OrigoOccupation": "Residential Home Proprietor (full involvement in caring)"
    },
    {
      "OrigoCode": "RAC01892",
      "OrigoOccupation": "Restaurant Proprietor - no cooking"
    },
    {
      "OrigoCode": "RAB01983",
      "OrigoOccupation": "Restorer (Paintings)"
    },
    {
      "OrigoCode": "RAB01984",
      "OrigoOccupation": "Restorer (Stone, Brickwork)"
    },
    {
      "OrigoCode": "RAC01894",
      "OrigoOccupation": "Retail Shop Manager"
    },
    {
      "OrigoCode": "RAC01895",
      "OrigoOccupation": "Retail Shop Manager - admin only"
    },
    {
      "OrigoCode": "RAC01896",
      "OrigoOccupation": "Retired"
    },
    {
      "OrigoCode": "RAC01897",
      "OrigoOccupation": "Riding Instructor"
    },
    {
      "OrigoCode": "RAB01985",
      "OrigoOccupation": "Rig Electrician"
    },
    {
      "OrigoCode": "RAB01986",
      "OrigoOccupation": "Rig Maintenance Diver"
    },
    {
      "OrigoCode": "RAB01987",
      "OrigoOccupation": "Rig Mechanic"
    },
    {
      "OrigoCode": "RAB01988",
      "OrigoOccupation": "Rig Medic"
    },
    {
      "OrigoCode": "RAB01989",
      "OrigoOccupation": "Rigger - Docks"
    },
    {
      "OrigoCode": "RAB01990",
      "OrigoOccupation": "Rigger - Film Industry - Entertainment"
    },
    {
      "OrigoCode": "RAB01991",
      "OrigoOccupation": "Rigger - Gas Supply Industry"
    },
    {
      "OrigoCode": "RAB01994",
      "OrigoOccupation": "Rigger - Industrial/Plant Machinery"
    },
    {
      "OrigoCode": "RAB01992",
      "OrigoOccupation": "Rigger - Oil & Natural Gas Industries"
    },
    {
      "OrigoCode": "RAB01993",
      "OrigoOccupation": "Rigger - Ship Building, Ship Repair & Marine Engineering"
    },
    {
      "OrigoCode": "RAB01995",
      "OrigoOccupation": "Ripper"
    },
    {
      "OrigoCode": "RAB01996",
      "OrigoOccupation": "River Inspector"
    },
    {
      "OrigoCode": "RAD03279",
      "OrigoOccupation": "Riveter"
    },
    {
      "OrigoCode": "RAB02008",
      "OrigoOccupation": "Road Crew Member - 'Roadie'"
    },
    {
      "OrigoCode": "RAC01900",
      "OrigoOccupation": "Road Manager - Rock band"
    },
    {
      "OrigoCode": "RAB02010",
      "OrigoOccupation": "Road Marker"
    },
    {
      "OrigoCode": "RAD03384",
      "OrigoOccupation": "Road Patrolman"
    },
    {
      "OrigoCode": "RAB02013",
      "OrigoOccupation": "Road Safety Officer"
    },
    {
      "OrigoCode": "RAB02015",
      "OrigoOccupation": "Road Sweeper (hand)"
    },
    {
      "OrigoCode": "RAC01902",
      "OrigoOccupation": "Road Sweeper - Mechanical"
    },
    {
      "OrigoCode": "RAB02016",
      "OrigoOccupation": "Road Tester - Garage Trade"
    },
    {
      "OrigoCode": "RAB02017",
      "OrigoOccupation": "Road Tester - Motor Vehicle & Cycle Industry"
    },
    {
      "OrigoCode": "RAD03280",
      "OrigoOccupation": "Road Worker/Labourer"
    },
    {
      "OrigoCode": "RAB02019",
      "OrigoOccupation": "Roaster"
    },
    {
      "OrigoCode": "RAB02020",
      "OrigoOccupation": "Rodent Destroyer"
    },
    {
      "OrigoCode": "RAB02022",
      "OrigoOccupation": "Roller Blind Maker"
    },
    {
      "OrigoCode": "RAD03276",
      "OrigoOccupation": "Rollerman"
    },
    {
      "OrigoCode": "RAB02032",
      "OrigoOccupation": "Roofer - 40' up"
    },
    {
      "OrigoCode": "RAB02033",
      "OrigoOccupation": "Roofer - up to 40'"
    },
    {
      "OrigoCode": "RAC01903",
      "OrigoOccupation": "Roofing Inspector (Mostly office based - some estimating)"
    },
    {
      "OrigoCode": "RAB02035",
      "OrigoOccupation": "Rope Maker"
    },
    {
      "OrigoCode": "RAB02037",
      "OrigoOccupation": "Roughneck"
    },
    {
      "OrigoCode": "RAB02039",
      "OrigoOccupation": "Roustabout"
    },
    {
      "OrigoCode": "RAC01905",
      "OrigoOccupation": "Roustabout Pusher - Oil Rig Industry"
    },
    {
      "OrigoCode": "RAC01906",
      "OrigoOccupation": "Rubber & Plastics Worker"
    },
    {
      "OrigoCode": "RAB02044",
      "OrigoOccupation": "Rubber Technologist"
    },
    {
      "OrigoCode": "SAB02047",
      "OrigoOccupation": "Saddler"
    },
    {
      "OrigoCode": "SAD03299",
      "OrigoOccupation": "Safety Inspector"
    },
    {
      "OrigoCode": "SAB02048",
      "OrigoOccupation": "Safety Officer"
    },
    {
      "OrigoCode": "SAC01907",
      "OrigoOccupation": "Safety Officer - Oil Rig Industry"
    },
    {
      "OrigoCode": "SAD03300",
      "OrigoOccupation": "Sailing Instructor"
    },
    {
      "OrigoCode": "SAD03305",
      "OrigoOccupation": "Salary Administrator"
    },
    {
      "OrigoCode": "SAC01908",
      "OrigoOccupation": "Sales & Marketing Manager"
    },
    {
      "OrigoCode": "SAD03306",
      "OrigoOccupation": "Sales Assistant - retail"
    },
    {
      "OrigoCode": "SAD03312",
      "OrigoOccupation": "Sales Executive"
    },
    {
      "OrigoCode": "SAD03317",
      "OrigoOccupation": "Sales Manager"
    },
    {
      "OrigoCode": "SAD03319",
      "OrigoOccupation": "Sales Support Administrator"
    },
    {
      "OrigoCode": "SAB02050",
      "OrigoOccupation": "Salter"
    },
    {
      "OrigoCode": "SAB02051",
      "OrigoOccupation": "Salvage Diver"
    },
    {
      "OrigoCode": "SAB02052",
      "OrigoOccupation": "Salvage Man"
    },
    {
      "OrigoCode": "SAD03283",
      "OrigoOccupation": "Sandblaster"
    },
    {
      "OrigoCode": "SAC01910",
      "OrigoOccupation": "Satellite Aerial Fixer (domestic only)"
    },
    {
      "OrigoCode": "SAB02056",
      "OrigoOccupation": "Saturation Tank Attendant"
    },
    {
      "OrigoCode": "SAD03296",
      "OrigoOccupation": "Saw Doctor or Repairer or Sharpener"
    },
    {
      "OrigoCode": "SAB02064",
      "OrigoOccupation": "Scaffolder"
    },
    {
      "OrigoCode": "SAD03291",
      "OrigoOccupation": "Scaffolder Offshore Oil or Gas"
    },
    {
      "OrigoCode": "SAB02068",
      "OrigoOccupation": "Scene Shifter"
    },
    {
      "OrigoCode": "SAB02069",
      "OrigoOccupation": "Scenery Painter"
    },
    {
      "OrigoCode": "SAD03313",
      "OrigoOccupation": "School Assistant"
    },
    {
      "OrigoCode": "SAC01912",
      "OrigoOccupation": "School Bursar"
    },
    {
      "OrigoCode": "SAC01913",
      "OrigoOccupation": "School Inspector"
    },
    {
      "OrigoCode": "SAB02074",
      "OrigoOccupation": "Scrap Breaker"
    },
    {
      "OrigoCode": "SAB02076",
      "OrigoOccupation": "Scrap Dealer"
    },
    {
      "OrigoCode": "SAB02079",
      "OrigoOccupation": "Screen Printer"
    },
    {
      "OrigoCode": "SAB02081",
      "OrigoOccupation": "Screener - Quarrying"
    },
    {
      "OrigoCode": "SAB02082",
      "OrigoOccupation": "Screenmaker"
    },
    {
      "OrigoCode": "SAB02084",
      "OrigoOccupation": "Screwman"
    },
    {
      "OrigoCode": "SAB02085",
      "OrigoOccupation": "Script Writer"
    },
    {
      "OrigoCode": "SAB02087",
      "OrigoOccupation": "Sculptor"
    },
    {
      "OrigoCode": "SAB02088",
      "OrigoOccupation": "Seaman"
    },
    {
      "OrigoCode": "SAD03307",
      "OrigoOccupation": "Secretary"
    },
    {
      "OrigoCode": "SAD03314",
      "OrigoOccupation": "Security Consultant"
    },
    {
      "OrigoCode": "SAB02093",
      "OrigoOccupation": "Security Guard"
    },
    {
      "OrigoCode": "SAD03285",
      "OrigoOccupation": "Security Manager"
    },
    {
      "OrigoCode": "SAB02096",
      "OrigoOccupation": "Seismologist"
    },
    {
      "OrigoCode": "SAD03301",
      "OrigoOccupation": "Set Designer"
    },
    {
      "OrigoCode": "SAB02106",
      "OrigoOccupation": "Sewage Works Attendant"
    },
    {
      "OrigoCode": "SAB02107",
      "OrigoOccupation": "Sewage Works Manager"
    },
    {
      "OrigoCode": "SAB02108",
      "OrigoOccupation": "Sewerman"
    },
    {
      "OrigoCode": "SAC01919",
      "OrigoOccupation": "Sewing Machine Mechanic"
    },
    {
      "OrigoCode": "SAD03315",
      "OrigoOccupation": "Sewing Machinist"
    },
    {
      "OrigoCode": "SAB02113",
      "OrigoOccupation": "Shaftsman"
    },
    {
      "OrigoCode": "SAB02114",
      "OrigoOccupation": "Sheep Shearer"
    },
    {
      "OrigoCode": "SAB02115",
      "OrigoOccupation": "Sheet Fixer"
    },
    {
      "OrigoCode": "SAD03294",
      "OrigoOccupation": "Sheet Metal Worker"
    },
    {
      "OrigoCode": "SAC01920",
      "OrigoOccupation": "Shelf Filler"
    },
    {
      "OrigoCode": "SAB02119",
      "OrigoOccupation": "Shepherd"
    },
    {
      "OrigoCode": "SAB02121",
      "OrigoOccupation": "Ship's Broker"
    },
    {
      "OrigoCode": "SAB02123",
      "OrigoOccupation": "Shipping Clerk"
    },
    {
      "OrigoCode": "SAB02127",
      "OrigoOccupation": "Shoe Maker"
    },
    {
      "OrigoCode": "SAB02128",
      "OrigoOccupation": "Shoe Repairer"
    },
    {
      "OrigoCode": "SAB02129",
      "OrigoOccupation": "Shop Assistant"
    },
    {
      "OrigoCode": "SAB02133",
      "OrigoOccupation": "Shop Fitter"
    },
    {
      "OrigoCode": "SAD03286",
      "OrigoOccupation": "Shotblaster"
    },
    {
      "OrigoCode": "SAD03292",
      "OrigoOccupation": "Shotfirer"
    },
    {
      "OrigoCode": "SAB02145",
      "OrigoOccupation": "Shredding Machine Operator"
    },
    {
      "OrigoCode": "SAB02146",
      "OrigoOccupation": "Shunter - Marshalling/Goods Yard - Railways"
    },
    {
      "OrigoCode": "SAB02147",
      "OrigoOccupation": "Shunter - Mining"
    },
    {
      "OrigoCode": "SAD03302",
      "OrigoOccupation": "Sieve Operator - food"
    },
    {
      "OrigoCode": "SAD03308",
      "OrigoOccupation": "Sieve Operator - quarry"
    },
    {
      "OrigoCode": "SAD03316",
      "OrigoOccupation": "Sifter - food"
    },
    {
      "OrigoCode": "SAD03318",
      "OrigoOccupation": "Sifter - quarry"
    },
    {
      "OrigoCode": "SAB02159",
      "OrigoOccupation": "Sign Writer (40' up)"
    },
    {
      "OrigoCode": "SAB02160",
      "OrigoOccupation": "Sign Writer (no work at heights)"
    },
    {
      "OrigoCode": "SAB02163",
      "OrigoOccupation": "Signalman"
    },
    {
      "OrigoCode": "SAD03287",
      "OrigoOccupation": "Siloman"
    },
    {
      "OrigoCode": "SAB02167",
      "OrigoOccupation": "Siloman - docks"
    },
    {
      "OrigoCode": "SAB02169",
      "OrigoOccupation": "Siloman - quarry"
    },
    {
      "OrigoCode": "SAC01922",
      "OrigoOccupation": "Silversmith"
    },
    {
      "OrigoCode": "SAB02171",
      "OrigoOccupation": "Sister (Hospital)"
    },
    {
      "OrigoCode": "SAB02172",
      "OrigoOccupation": "Site Agent"
    },
    {
      "OrigoCode": "SAB02176",
      "OrigoOccupation": "Skiing - Snow - Prof Instructor"
    },
    {
      "OrigoCode": "SAB02177",
      "OrigoOccupation": "Skipper"
    },
    {
      "OrigoCode": "SAB02181",
      "OrigoOccupation": "Slate Cutter"
    },
    {
      "OrigoCode": "SAB02182",
      "OrigoOccupation": "Slate Dresser"
    },
    {
      "OrigoCode": "SAB02183",
      "OrigoOccupation": "Slate Splitter"
    },
    {
      "OrigoCode": "SAB02184",
      "OrigoOccupation": "Slater - 40' up"
    },
    {
      "OrigoCode": "SAB02185",
      "OrigoOccupation": "Slater - up to 40'"
    },
    {
      "OrigoCode": "SAB02186",
      "OrigoOccupation": "Slaughterer"
    },
    {
      "OrigoCode": "SAB02187",
      "OrigoOccupation": "Slaughterhouse Manager"
    },
    {
      "OrigoCode": "SAB02188",
      "OrigoOccupation": "Sleeping Car Attendant"
    },
    {
      "OrigoCode": "SAB02189",
      "OrigoOccupation": "Sleeve Designer"
    },
    {
      "OrigoCode": "SAB02190",
      "OrigoOccupation": "Smeller"
    },
    {
      "OrigoCode": "SAB02191",
      "OrigoOccupation": "Smith - Gold, Silver etc"
    },
    {
      "OrigoCode": "SAB02192",
      "OrigoOccupation": "Smoker"
    },
    {
      "OrigoCode": "SAB02197",
      "OrigoOccupation": "Social Worker"
    },
    {
      "OrigoCode": "SAB02198",
      "OrigoOccupation": "Sociologist"
    },
    {
      "OrigoCode": "SAB02199",
      "OrigoOccupation": "Solderer"
    },
    {
      "OrigoCode": "SAB02200",
      "OrigoOccupation": "Solicitor"
    },
    {
      "OrigoCode": "SAB02201",
      "OrigoOccupation": "Song Writer"
    },
    {
      "OrigoCode": "SAB02205",
      "OrigoOccupation": "Sorter (scrap metal)"
    },
    {
      "OrigoCode": "SAB02202",
      "OrigoOccupation": "Sorter - Dry Cleaning"
    },
    {
      "OrigoCode": "SAB02203",
      "OrigoOccupation": "Sorter - Laundry"
    },
    {
      "OrigoCode": "SAB02204",
      "OrigoOccupation": "Sorter - Post Office/Telecommunications"
    },
    {
      "OrigoCode": "SAD03295",
      "OrigoOccupation": "Sound Balancer"
    },
    {
      "OrigoCode": "SAD03297",
      "OrigoOccupation": "Sound Mixer"
    },
    {
      "OrigoCode": "SAD03303",
      "OrigoOccupation": "Sound Recordist"
    },
    {
      "OrigoCode": "SAB02212",
      "OrigoOccupation": "Sound Technician"
    },
    {
      "OrigoCode": "SAB02214",
      "OrigoOccupation": "Special Air Service (SAS)"
    },
    {
      "OrigoCode": "SAB02215",
      "OrigoOccupation": "Special Boat Service (SBS)"
    },
    {
      "OrigoCode": "SAB02217",
      "OrigoOccupation": "Special Effects Technician"
    },
    {
      "OrigoCode": "SAB02218",
      "OrigoOccupation": "Speech Therapist"
    },
    {
      "OrigoCode": "SAB02219",
      "OrigoOccupation": "Spiderman"
    },
    {
      "OrigoCode": "SAB02220",
      "OrigoOccupation": "Spinner"
    },
    {
      "OrigoCode": "SAB02224",
      "OrigoOccupation": "Sports Equipment Maker"
    },
    {
      "OrigoCode": "SAB02225",
      "OrigoOccupation": "Sports Equipment Repairer"
    },
    {
      "OrigoCode": "SAB02226",
      "OrigoOccupation": "Spot-Welder"
    },
    {
      "OrigoCode": "SAD03298",
      "OrigoOccupation": "Spray Painter"
    },
    {
      "OrigoCode": "SAB02235",
      "OrigoOccupation": "Stablehand"
    },
    {
      "OrigoCode": "SAB02236",
      "OrigoOccupation": "Staff Nurse"
    },
    {
      "OrigoCode": "SAB02237",
      "OrigoOccupation": "Stage Doorkeeper"
    },
    {
      "OrigoCode": "SAB02238",
      "OrigoOccupation": "Stage Hand"
    },
    {
      "OrigoCode": "SAD03309",
      "OrigoOccupation": "Stage Manager"
    },
    {
      "OrigoCode": "SAC01928",
      "OrigoOccupation": "Stage Technician"
    },
    {
      "OrigoCode": "SAB02242",
      "OrigoOccupation": "Stamper (identification markings)"
    },
    {
      "OrigoCode": "SAD03284",
      "OrigoOccupation": "Station Manager"
    },
    {
      "OrigoCode": "SAD03288",
      "OrigoOccupation": "Station Officer - Fire"
    },
    {
      "OrigoCode": "SAC01929",
      "OrigoOccupation": "Stationer"
    },
    {
      "OrigoCode": "SAB02253",
      "OrigoOccupation": "Statistician"
    },
    {
      "OrigoCode": "SAB02254",
      "OrigoOccupation": "Steel Erector - 40' up"
    },
    {
      "OrigoCode": "SAB02255",
      "OrigoOccupation": "Steel Erector - up to 40'"
    },
    {
      "OrigoCode": "SAB02258",
      "OrigoOccupation": "Steeplejack"
    },
    {
      "OrigoCode": "SAB02263",
      "OrigoOccupation": "Stenographer"
    },
    {
      "OrigoCode": "SAD03289",
      "OrigoOccupation": "Steriliser"
    },
    {
      "OrigoCode": "SAB02267",
      "OrigoOccupation": "Stevedore"
    },
    {
      "OrigoCode": "SAB02269",
      "OrigoOccupation": "Stitcher"
    },
    {
      "OrigoCode": "SAB02270",
      "OrigoOccupation": "Stockbroker"
    },
    {
      "OrigoCode": "SAB02272",
      "OrigoOccupation": "Stockroom Storeman"
    },
    {
      "OrigoCode": "SAB02273",
      "OrigoOccupation": "Stocktaker"
    },
    {
      "OrigoCode": "SAD03290",
      "OrigoOccupation": "Stone Breaker"
    },
    {
      "OrigoCode": "SAD03293",
      "OrigoOccupation": "Stone/Slate Polisher"
    },
    {
      "OrigoCode": "SAB02284",
      "OrigoOccupation": "Stonemason"
    },
    {
      "OrigoCode": "SAB02285",
      "OrigoOccupation": "Store Detective"
    },
    {
      "OrigoCode": "SAB02286",
      "OrigoOccupation": "Storekeeper"
    },
    {
      "OrigoCode": "SAD03304",
      "OrigoOccupation": "Storeman"
    },
    {
      "OrigoCode": "SAD03310",
      "OrigoOccupation": "Structural Engineer"
    },
    {
      "OrigoCode": "SAB02298",
      "OrigoOccupation": "Student"
    },
    {
      "OrigoCode": "SAB02301",
      "OrigoOccupation": "Stunt Man"
    },
    {
      "OrigoCode": "SAB02305",
      "OrigoOccupation": "Submariner"
    },
    {
      "OrigoCode": "SAB02306",
      "OrigoOccupation": "Sugar Beet Cutter/Slicer"
    },
    {
      "OrigoCode": "SAB02307",
      "OrigoOccupation": "Sugar Boiler"
    },
    {
      "OrigoCode": "SAB02310",
      "OrigoOccupation": "Supermarket Cashier"
    },
    {
      "OrigoCode": "SAD03282",
      "OrigoOccupation": "Supermarket Manager"
    },
    {
      "OrigoCode": "SAB02317",
      "OrigoOccupation": "Surgeon"
    },
    {
      "OrigoCode": "SAB02318",
      "OrigoOccupation": "Surgery Nurse"
    },
    {
      "OrigoCode": "SAB02319",
      "OrigoOccupation": "Surgery Receptionist"
    },
    {
      "OrigoCode": "SAB02320",
      "OrigoOccupation": "Surgical Appliance Maker"
    },
    {
      "OrigoCode": "SAC01936",
      "OrigoOccupation": "Surgical Shoe Maker"
    },
    {
      "OrigoCode": "SAB02322",
      "OrigoOccupation": "Surveyor - Oil & Natural Gas Industries (Exploration & Production)"
    },
    {
      "OrigoCode": "SAB02323",
      "OrigoOccupation": "Surveyor - Ship Building, Ship Repair & Marine Engineering"
    },
    {
      "OrigoCode": "SAC01937",
      "OrigoOccupation": "Swimming Instructor"
    },
    {
      "OrigoCode": "SAB02325",
      "OrigoOccupation": "Swimming Pool Attendant"
    },
    {
      "OrigoCode": "SAB02326",
      "OrigoOccupation": "Switchboard Operator"
    },
    {
      "OrigoCode": "SAB02327",
      "OrigoOccupation": "Sword Swallower"
    },
    {
      "OrigoCode": "SAD03311",
      "OrigoOccupation": "Systems Programmer"
    },
    {
      "OrigoCode": "TAD03338",
      "OrigoOccupation": "Tailor"
    },
    {
      "OrigoCode": "TAD03340",
      "OrigoOccupation": "Takeaway Food Shop Assistant"
    },
    {
      "OrigoCode": "TAD03341",
      "OrigoOccupation": "Takeaway Food Shop Manager - no serving"
    },
    {
      "OrigoCode": "TAB02333",
      "OrigoOccupation": "Tamperman"
    },
    {
      "OrigoCode": "TAB02334",
      "OrigoOccupation": "Tank Room Attendant"
    },
    {
      "OrigoCode": "TAD03327",
      "OrigoOccupation": "Tanker Driver"
    },
    {
      "OrigoCode": "TAD03330",
      "OrigoOccupation": "Tanker Filler"
    },
    {
      "OrigoCode": "TAD03332",
      "OrigoOccupation": "Tanner"
    },
    {
      "OrigoCode": "TAB02338",
      "OrigoOccupation": "Tarmac Layer - Construction Industry"
    },
    {
      "OrigoCode": "TAB02339",
      "OrigoOccupation": "Tarmac Layer - Road Maintenance & Construction"
    },
    {
      "OrigoCode": "TAD03339",
      "OrigoOccupation": "Tattoo Artist"
    },
    {
      "OrigoCode": "TAB02340",
      "OrigoOccupation": "Tax Consultant"
    },
    {
      "OrigoCode": "TAB02341",
      "OrigoOccupation": "Tax Inspector"
    },
    {
      "OrigoCode": "TAC01941",
      "OrigoOccupation": "Taxi Business Administrator"
    },
    {
      "OrigoCode": "TAC01943",
      "OrigoOccupation": "Taxi Business Proprietor (no driving)"
    },
    {
      "OrigoCode": "TAB02342",
      "OrigoOccupation": "Taxi Driver"
    },
    {
      "OrigoCode": "TAB02343",
      "OrigoOccupation": "Taxidermist"
    },
    {
      "OrigoCode": "TAD03325",
      "OrigoOccupation": "Teacher"
    },
    {
      "OrigoCode": "TAC01944",
      "OrigoOccupation": "Teaching Assistant"
    },
    {
      "OrigoCode": "TAD03328",
      "OrigoOccupation": "Technician - admin and site visits"
    },
    {
      "OrigoCode": "TAD03331",
      "OrigoOccupation": "Technician - admin only"
    },
    {
      "OrigoCode": "TAD03333",
      "OrigoOccupation": "Technician - heavy manual"
    },
    {
      "OrigoCode": "TAD03335",
      "OrigoOccupation": "Technician - light manual"
    },
    {
      "OrigoCode": "TAB02360",
      "OrigoOccupation": "Technician - other"
    },
    {
      "OrigoCode": "TAC01945",
      "OrigoOccupation": "Telecommunication Technical Officer"
    },
    {
      "OrigoCode": "TAB02365",
      "OrigoOccupation": "Telegraphist"
    },
    {
      "OrigoCode": "TAC01946",
      "OrigoOccupation": "Telephone Customer Advisor"
    },
    {
      "OrigoCode": "TAB02366",
      "OrigoOccupation": "Telephone Exchange Superintendent"
    },
    {
      "OrigoCode": "TAB02367",
      "OrigoOccupation": "Telephone Fitter"
    },
    {
      "OrigoCode": "TAB02368",
      "OrigoOccupation": "Telephone Operator"
    },
    {
      "OrigoCode": "TAB02369",
      "OrigoOccupation": "Telephone Repairer"
    },
    {
      "OrigoCode": "TAB02370",
      "OrigoOccupation": "Telephone Supervisor"
    },
    {
      "OrigoCode": "TAC01947",
      "OrigoOccupation": "Telephone Systems Sales Director"
    },
    {
      "OrigoCode": "TAB02371",
      "OrigoOccupation": "Telephonist"
    },
    {
      "OrigoCode": "TAD03323",
      "OrigoOccupation": "Telesales Caller"
    },
    {
      "OrigoCode": "TAD03324",
      "OrigoOccupation": "Telesales Manager"
    },
    {
      "OrigoCode": "TAC01948",
      "OrigoOccupation": "Television Engineer"
    },
    {
      "OrigoCode": "TAD03336",
      "OrigoOccupation": "Test Engineer"
    },
    {
      "OrigoCode": "TAB02380",
      "OrigoOccupation": "Test Pilots"
    },
    {
      "OrigoCode": "TAD03321",
      "OrigoOccupation": "Tester"
    },
    {
      "OrigoCode": "TAC01949",
      "OrigoOccupation": "Textile Worker"
    },
    {
      "OrigoCode": "TAB02393",
      "OrigoOccupation": "Thatcher"
    },
    {
      "OrigoCode": "TAC01950",
      "OrigoOccupation": "Theatre Sound Engineer"
    },
    {
      "OrigoCode": "TAB02398",
      "OrigoOccupation": "Ticket Inspector"
    },
    {
      "OrigoCode": "TAB02400",
      "OrigoOccupation": "Tiler - 40' up"
    },
    {
      "OrigoCode": "TAB02401",
      "OrigoOccupation": "Tiler - up to 40'"
    },
    {
      "OrigoCode": "TAB02402",
      "OrigoOccupation": "Timber Merchant (no manual work)"
    },
    {
      "OrigoCode": "TAB02405",
      "OrigoOccupation": "Timberman"
    },
    {
      "OrigoCode": "TAC01951",
      "OrigoOccupation": "Tobacconist"
    },
    {
      "OrigoCode": "TAB02412",
      "OrigoOccupation": "Tool Fitter"
    },
    {
      "OrigoCode": "TAB02413",
      "OrigoOccupation": "Tool Maker"
    },
    {
      "OrigoCode": "TAB02414",
      "OrigoOccupation": "Tool Pusher"
    },
    {
      "OrigoCode": "TAB02417",
      "OrigoOccupation": "Tour Guide"
    },
    {
      "OrigoCode": "TAB02418",
      "OrigoOccupation": "Tour Manager"
    },
    {
      "OrigoCode": "TAB02419",
      "OrigoOccupation": "Tower Crane Driver"
    },
    {
      "OrigoCode": "TAB02420",
      "OrigoOccupation": "Town Planner"
    },
    {
      "OrigoCode": "TAB02421",
      "OrigoOccupation": "Toxicologist"
    },
    {
      "OrigoCode": "TAB02426",
      "OrigoOccupation": "Trackman"
    },
    {
      "OrigoCode": "TAD03334",
      "OrigoOccupation": "Trade Union Official (full time)"
    },
    {
      "OrigoCode": "TAB02427",
      "OrigoOccupation": "Traffic Warden"
    },
    {
      "OrigoCode": "TAB02428",
      "OrigoOccupation": "Train Crew Inspector"
    },
    {
      "OrigoCode": "TAB02429",
      "OrigoOccupation": "Train Crew Supervisor"
    },
    {
      "OrigoCode": "TAC01953",
      "OrigoOccupation": "Train Driver"
    },
    {
      "OrigoCode": "TAD03326",
      "OrigoOccupation": "Trainer - education/office based"
    },
    {
      "OrigoCode": "TAD03329",
      "OrigoOccupation": "Tram Driver/Conductor"
    },
    {
      "OrigoCode": "TAB02432",
      "OrigoOccupation": "Translator"
    },
    {
      "OrigoCode": "TAC01954",
      "OrigoOccupation": "Transport Company Operations Manager (Office based)"
    },
    {
      "OrigoCode": "TAC01955",
      "OrigoOccupation": "Transport Manager"
    },
    {
      "OrigoCode": "TAC01956",
      "OrigoOccupation": "Travel Agent (office based)"
    },
    {
      "OrigoCode": "TAB02434",
      "OrigoOccupation": "Travel Courier"
    },
    {
      "OrigoCode": "TAB02435",
      "OrigoOccupation": "Trawlerman"
    },
    {
      "OrigoCode": "TAB02436",
      "OrigoOccupation": "Tree Feller"
    },
    {
      "OrigoCode": "TAB02437",
      "OrigoOccupation": "Tree Surgeon"
    },
    {
      "OrigoCode": "TAB02438",
      "OrigoOccupation": "Trenchman"
    },
    {
      "OrigoCode": "TAB02443",
      "OrigoOccupation": "Tugman"
    },
    {
      "OrigoCode": "TAB02449",
      "OrigoOccupation": "Tunneller - no explosives etc"
    },
    {
      "OrigoCode": "TAB02450",
      "OrigoOccupation": "Tunneller - using explosives etc"
    },
    {
      "OrigoCode": "TAC01957",
      "OrigoOccupation": "Turf Accountant (on course)"
    },
    {
      "OrigoCode": "TAC01958",
      "OrigoOccupation": "Turf Accountant (shop)"
    },
    {
      "OrigoCode": "TAD03337",
      "OrigoOccupation": "Turf Cutter/Layer"
    },
    {
      "OrigoCode": "TAB02451",
      "OrigoOccupation": "Turner - Machining, Shaping etc - Metal Manufacture"
    },
    {
      "OrigoCode": "TAB02452",
      "OrigoOccupation": "Turner - Pottery Industry"
    },
    {
      "OrigoCode": "TAB02453",
      "OrigoOccupation": "Turnstile Operator"
    },
    {
      "OrigoCode": "TAB02454",
      "OrigoOccupation": "Tutor (salaried)"
    },
    {
      "OrigoCode": "TAB02455",
      "OrigoOccupation": "Tutor (self-employed)"
    },
    {
      "OrigoCode": "TAB02457",
      "OrigoOccupation": "Type Caster"
    },
    {
      "OrigoCode": "TAC01959",
      "OrigoOccupation": "Typesetter"
    },
    {
      "OrigoCode": "TAB02459",
      "OrigoOccupation": "Typograph Operator"
    },
    {
      "OrigoCode": "TAB02460",
      "OrigoOccupation": "Typographical Designer"
    },
    {
      "OrigoCode": "TAD03322",
      "OrigoOccupation": "Tyre/Exhaust Fitter"
    },
    {
      "OrigoCode": "UAD03343",
      "OrigoOccupation": "Umpire"
    },
    {
      "OrigoCode": "UAD03344",
      "OrigoOccupation": "Under Secretary"
    },
    {
      "OrigoCode": "UAB02463",
      "OrigoOccupation": "Undertaker"
    },
    {
      "OrigoCode": "UAB02464",
      "OrigoOccupation": "Undertaker's Director's Assistant"
    },
    {
      "OrigoCode": "UAB02465",
      "OrigoOccupation": "Underwriter"
    },
    {
      "OrigoCode": "UAC01960",
      "OrigoOccupation": "Unemployed"
    },
    {
      "OrigoCode": "UAD03342",
      "OrigoOccupation": "University lecturer"
    },
    {
      "OrigoCode": "UAC01961",
      "OrigoOccupation": "Unknown"
    },
    {
      "OrigoCode": "UAB02467",
      "OrigoOccupation": "Upholsterer"
    },
    {
      "OrigoCode": "UAB02469",
      "OrigoOccupation": "Usher/Usherette"
    },
    {
      "OrigoCode": "VAD03348",
      "OrigoOccupation": "Vacuum Plant Operator"
    },
    {
      "OrigoCode": "VAB02470",
      "OrigoOccupation": "Valet/Valeter"
    },
    {
      "OrigoCode": "VAB02471",
      "OrigoOccupation": "Valuer"
    },
    {
      "OrigoCode": "VAD03345",
      "OrigoOccupation": "Valveman"
    },
    {
      "OrigoCode": "VAB02475",
      "OrigoOccupation": "Van driver"
    },
    {
      "OrigoCode": "VAB02476",
      "OrigoOccupation": "Varnisher"
    },
    {
      "OrigoCode": "VAB02477",
      "OrigoOccupation": "Vatman"
    },
    {
      "OrigoCode": "VAB02478",
      "OrigoOccupation": "Vehicle Body Builder"
    },
    {
      "OrigoCode": "VAB02479",
      "OrigoOccupation": "Vehicle Body Fitter"
    },
    {
      "OrigoCode": "VAC01962",
      "OrigoOccupation": "Vending Machine Engineer"
    },
    {
      "OrigoCode": "VAB02480",
      "OrigoOccupation": "Venetian Blind Maker"
    },
    {
      "OrigoCode": "VAB02481",
      "OrigoOccupation": "Ventriloquist"
    },
    {
      "OrigoCode": "VAD03346",
      "OrigoOccupation": "Venture Capitalist"
    },
    {
      "OrigoCode": "VAB02482",
      "OrigoOccupation": "Verger"
    },
    {
      "OrigoCode": "VAB02483",
      "OrigoOccupation": "Veterinarian"
    },
    {
      "OrigoCode": "VAB02485",
      "OrigoOccupation": "Veterinary Assistant"
    },
    {
      "OrigoCode": "VAB02492",
      "OrigoOccupation": "Vicar"
    },
    {
      "OrigoCode": "VAC01963",
      "OrigoOccupation": "Video Conference Engineer"
    },
    {
      "OrigoCode": "VAB02493",
      "OrigoOccupation": "Video Recorder Operator"
    },
    {
      "OrigoCode": "VAD03347",
      "OrigoOccupation": "Vintner"
    },
    {
      "OrigoCode": "VAB02495",
      "OrigoOccupation": "Vision Mixer"
    },
    {
      "OrigoCode": "VAB02496",
      "OrigoOccupation": "Vocational Training Instructor"
    },
    {
      "OrigoCode": "WAD03356",
      "OrigoOccupation": "Wages Clerk"
    },
    {
      "OrigoCode": "WAB02499",
      "OrigoOccupation": "Wages Inspector"
    },
    {
      "OrigoCode": "WAB02503",
      "OrigoOccupation": "Waiter, Waitress"
    },
    {
      "OrigoCode": "WAB02506",
      "OrigoOccupation": "Wallpaper Printer"
    },
    {
      "OrigoCode": "WAB02507",
      "OrigoOccupation": "Warden"
    },
    {
      "OrigoCode": "WAB02508",
      "OrigoOccupation": "Wardrobe Mistress"
    },
    {
      "OrigoCode": "WAB02510",
      "OrigoOccupation": "Warehouse Manager"
    },
    {
      "OrigoCode": "WAB02513",
      "OrigoOccupation": "Warehouseman"
    },
    {
      "OrigoCode": "WAD03352",
      "OrigoOccupation": "Washer"
    },
    {
      "OrigoCode": "WAD03353",
      "OrigoOccupation": "Waste Disposal/Recycling Operative"
    },
    {
      "OrigoCode": "WAB02518",
      "OrigoOccupation": "Wasteman, Salvage Man"
    },
    {
      "OrigoCode": "WAC01964",
      "OrigoOccupation": "Watch & Clock Maker"
    },
    {
      "OrigoCode": "WAC01965",
      "OrigoOccupation": "Watch & Clock Repairer"
    },
    {
      "OrigoCode": "WAB02519",
      "OrigoOccupation": "Watchman (inland waterways)"
    },
    {
      "OrigoCode": "WAB02520",
      "OrigoOccupation": "Watchstander"
    },
    {
      "OrigoCode": "WAB02521",
      "OrigoOccupation": "Water Bailiff"
    },
    {
      "OrigoCode": "WAB02522",
      "OrigoOccupation": "Water Infusion Man"
    },
    {
      "OrigoCode": "WAD03351",
      "OrigoOccupation": "Water Meter Reader/Fitter/Tester"
    },
    {
      "OrigoCode": "WAB02524",
      "OrigoOccupation": "Water Skiing - Prof. Instructor"
    },
    {
      "OrigoCode": "WAD03354",
      "OrigoOccupation": "Water Treatment Plant Operator"
    },
    {
      "OrigoCode": "WAD03355",
      "OrigoOccupation": "Waterworks Manager"
    },
    {
      "OrigoCode": "WAD03357",
      "OrigoOccupation": "Weaver"
    },
    {
      "OrigoCode": "WAD03359",
      "OrigoOccupation": "Website/Webpage Designer"
    },
    {
      "OrigoCode": "WAB02529",
      "OrigoOccupation": "Wedding Photographer"
    },
    {
      "OrigoCode": "WAB02530",
      "OrigoOccupation": "Weighbridge Clerk"
    },
    {
      "OrigoCode": "WAD03349",
      "OrigoOccupation": "Weighbridgeman"
    },
    {
      "OrigoCode": "WAB02538",
      "OrigoOccupation": "Weights & Measures Inspector"
    },
    {
      "OrigoCode": "WAB02541",
      "OrigoOccupation": "Welder - 40' up"
    },
    {
      "OrigoCode": "WAB02542",
      "OrigoOccupation": "Welder - up to 40'"
    },
    {
      "OrigoCode": "WAB02546",
      "OrigoOccupation": "Welfare Officer"
    },
    {
      "OrigoCode": "WAB02547",
      "OrigoOccupation": "Welfare Worker"
    },
    {
      "OrigoCode": "WAB02556",
      "OrigoOccupation": "Wicker Worker"
    },
    {
      "OrigoCode": "WAB02558",
      "OrigoOccupation": "Wig Maker"
    },
    {
      "OrigoCode": "WAB02561",
      "OrigoOccupation": "Winch Operator"
    },
    {
      "OrigoCode": "WAB02562",
      "OrigoOccupation": "Winchman"
    },
    {
      "OrigoCode": "WAB02564",
      "OrigoOccupation": "Window Cleaner (40' up)"
    },
    {
      "OrigoCode": "WAB02565",
      "OrigoOccupation": "Window Cleaner (up to 40')"
    },
    {
      "OrigoCode": "WAB02566",
      "OrigoOccupation": "Window Dresser"
    },
    {
      "OrigoCode": "WAD03358",
      "OrigoOccupation": "Window/Conservatory Fitter"
    },
    {
      "OrigoCode": "WAD03360",
      "OrigoOccupation": "Windscreen Fitter/Repairer"
    },
    {
      "OrigoCode": "WAB02567",
      "OrigoOccupation": "Wire Winder"
    },
    {
      "OrigoCode": "WAB02570",
      "OrigoOccupation": "Wood Carver"
    },
    {
      "OrigoCode": "WAD03350",
      "OrigoOccupation": "Wood Worker"
    },
    {
      "OrigoCode": "WAB02574",
      "OrigoOccupation": "Woodcutter"
    },
    {
      "OrigoCode": "WAB02575",
      "OrigoOccupation": "Woodman"
    },
    {
      "OrigoCode": "WAC01966",
      "OrigoOccupation": "Working Partner"
    },
    {
      "OrigoCode": "WAB02581",
      "OrigoOccupation": "Wrapping Machine Attendant"
    },
    {
      "OrigoCode": "WAB02583",
      "OrigoOccupation": "Writer"
    },
    {
      "OrigoCode": "XAD03362",
      "OrigoOccupation": "X-ray Technician - Radiologist"
    },
    {
      "OrigoCode": "YAD03363",
      "OrigoOccupation": "Yacht & Boat Builder"
    },
    {
      "OrigoCode": "YAD03365",
      "OrigoOccupation": "Yard Assistant"
    },
    {
      "OrigoCode": "YAB02585",
      "OrigoOccupation": "Yard Cleaner"
    },
    {
      "OrigoCode": "YAD03364",
      "OrigoOccupation": "Yarn Worker"
    },
    {
      "OrigoCode": "YAD03366",
      "OrigoOccupation": "Yoga Teacher"
    },
    {
      "OrigoCode": "YAC01967",
      "OrigoOccupation": "Youth Hostel Assistant and Cook"
    },
    {
      "OrigoCode": "YAC01968",
      "OrigoOccupation": "Youth Hostel Manager (some manual work)"
    },
    {
      "OrigoCode": "YAB02586",
      "OrigoOccupation": "Youth Leader (Full time)"
    },
    {
      "OrigoCode": "YAD03367",
      "OrigoOccupation": "Youth Worker (full time)"
    },
    {
      "OrigoCode": "ZAD03370",
      "OrigoOccupation": "Zoo Curator"
    },
    {
      "OrigoCode": "ZAD03385",
      "OrigoOccupation": "Zoo Director"
    },
    {
      "OrigoCode": "ZAD03368",
      "OrigoOccupation": "Zoo Keeper"
    },
    {
      "OrigoCode": "ZAD03369",
      "OrigoOccupation": "Zoo Keeper - large zoos"
    },
    {
      "OrigoCode": "ZAD03371",
      "OrigoOccupation": "Zoo Keeper - small zoos"
    },
    {
      "OrigoCode": "ZAD03372",
      "OrigoOccupation": "Zoological Research Associate"
    },
    {
      "OrigoCode": "ZAD03373",
      "OrigoOccupation": "Zoological Researcher"
    },
    {
      "OrigoCode": "ZAD03374",
      "OrigoOccupation": "Zoologist"
    },
    {
      "OrigoCode": "ZAB02587",
      "OrigoOccupation": "Zoologist (no overseas field work)"
    }
  ];

export function getOrigoOccupation(origoCode: string) {
  return occupations.find(o => o.OrigoCode === origoCode)?.OrigoOccupation;
}
