import { Component, Input } from '@angular/core';
import { User } from '../../../root/services/user.service';

const placeholderUrl = "/assets/img/avatar-placeholder.png";

@Component({
  selector: 'hfc-user-bar',
  templateUrl: './user-bar.component.html',
  styleUrls: ['./user-bar.component.scss']
})
export class UserBarComponent {
  @Input()
  public showRole = true;

  @Input()
  public showEmail = false;

  public get userPhotoUrl() { return placeholderUrl }

  @Input()
  public user: User;
}
