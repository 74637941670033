<div class="error">
  <span class="material-icons error-icon">error</span>
  Please specify
</div>

<div class="container">
  <div *ngFor="let item of items" class="item hfc-checkbox-list-item" [class.readonly]="readonly" [class.checked]="isChecked(item)" (click)="!readonly && onClicked(item)">
    <div class="item-content">
      <ng-container *ngTemplateOutlet="itemTemplate || defaultItemTemplate, context: { $implicit: item }">
      </ng-container>

      <div *ngIf="hintTemplate" class="label">
        <ng-container *ngTemplateOutlet="hintTemplate, context: { $implicit: item }"></ng-container>
      </div>
    </div>

    <i *ngIf="isChecked(item)" class="material-icons">check</i>
  </div>
</div>

<ng-template #defaultItemTemplate let-item>{{map ? map.get(item) : item}}</ng-template>
