import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserBarComponent } from './components/user-bar/user-bar.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { DesktopHeaderComponent } from './components/desktop-header/desktop-header.component';
import { DesktopFooterComponent } from './components/desktop-footer/desktop-footer.component';
import { FooterComponent } from './components/footer/footer.component';
import { LogoutButtonComponent } from './components/logout-button/logout-button.component';
import { HeaderComponent } from './components/header/header.component';
import { UploadComponent } from './components/upload/upload.component';
import { StickyFooterComponent } from './components/sticky-footer/sticky-footer.component';
import { RouterModule } from '@angular/router';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { CheckboxGroupComponent } from './components/checkbox-group/checkbox-group.component';
import { FieldComponent } from './components/field/field.component';
import { AgreeboxComponent } from './components/agreebox/agreebox.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhonePrefixDropdownComponent } from './components/phone-prefix-dropdown/phone-prefix-dropdown.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { PostcodeComponent } from './components/postcode/postcode.component';
import { AddressComponent } from './components/address/address.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { HelpPopupComponent } from './components/help-popup/help-popup.component';
import { ExpenseInputComponent } from './components/expense-input/expense-input.component';
import { ObjectCheckboxListComponent } from './components/object-checkbox-list/object-checkbox-list.component';
import { FieldErrorComponent } from './components/field-error/field-error.component';
import { FactFindUploadComponent } from '../fact-find/components/fact-find-upload/fact-find-upload.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { TitleDropdownComponent } from './components/title-dropdown/title-dropdown.component';
import { EvergladesSharedMorule } from '../../../../../src/app/website/shared/everglades-shared.module';
import { ClientSharedModule } from './client-shared.module';
import { HeaderWithLogoComponent } from './header-with-logo/header-with-logo.component';
import { StepNavigationComponent } from './components/step-navigation/step-navigation.component';
import { HeaderWithSidebarComponent } from './components/header-with-sidebar/header-with-sidebar.component';
import { StepLayoutComponent } from './components/step-layout/step-layout.component';
import { StepLayoutService } from './components/step-layout/step-layout.service';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { ValueTransformerDirective } from './directives/value-transformer.directive';
import { CompletedBannerComponent } from './components/completed-banner/completed-banner.component';
import { OrigoCodeComponent } from './components/origo-code/origo-code.component';
import { CountryDropdownComponent } from './components/country-dropdown/country-dropdown.component';
import { ApplicantSwitchComponent } from './components/applicant-switch/applicant-switch.component';
import { CodeInputComponent } from './components/code-input/code-input.component';
import { RegionDropdownComponent } from './components/region-dropdown/region-dropdown.component';

const exported = [
  UserBarComponent,
  LogoutButtonComponent,
  ProgressBarComponent,
  HeaderComponent,
  DesktopHeaderComponent,
  FooterComponent,
  StickyFooterComponent,
  DesktopFooterComponent,
  UploadComponent,
  FactFindUploadComponent,
  CheckboxComponent,
  CheckboxGroupComponent,
  FieldComponent,
  AgreeboxComponent,
  DropdownComponent,
  PhonePrefixDropdownComponent,
  CalendarComponent,
  PostcodeComponent,
  AddressComponent,
  TooltipComponent,
  HelpPopupComponent,
  ExpenseInputComponent,
  ObjectCheckboxListComponent,
  FieldErrorComponent,
  SignaturePadComponent,
  TitleDropdownComponent,
  RegionDropdownComponent,
  HeaderWithLogoComponent,
  StepNavigationComponent,
  HeaderWithSidebarComponent,
  StepLayoutComponent,
  TermsAndConditionsComponent,
  CompletedBannerComponent,
  OrigoCodeComponent,
  CountryDropdownComponent,
  ApplicantSwitchComponent,
  CodeInputComponent,

  ValueTransformerDirective,
];

@NgModule({
  declarations: [
    ...exported,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,

    ClientSharedModule,
    EvergladesSharedMorule,
  ],
  exports: exported,
  providers: [
    StepLayoutService,
  ]
})
export class SharedModule { }
